import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgModelGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PhoneNumberControlComponent } from '@shared/controls/phone-number-component/phone-number-control.component';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { PhoneDialogData } from '@shared/models/phone-dialog.model';
import { ForeignVisitorPhoneService } from '@shared/services/foreign-visitor-phone.service';
import { DialogCloseMessage } from '@shared/shared.types';

@Component({
  selector: 'app-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneNumberControlComponent,
  ],
})
export class PhoneDialogComponent {
  busy: boolean = false;
  phone: ContactPhoneNumber;
  @ViewChild('phoneNumberGroup') phoneNumberGroup: NgModelGroup;
  constructor(
    public phoneDialog: MatDialogRef<PhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fvPhoneService: ForeignVisitorPhoneService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: PhoneDialogData
  ): MatDialogRef<PhoneDialogComponent> {
    return dialog.open<PhoneDialogComponent>(PhoneDialogComponent, {
      data: item,
      width: '1000px',
    });
  }

  save(phone: ContactPhoneNumber) {
    this.busy = true;
    const update = {
      ...phone,
      ...{
        foreignVisitor: {
          id: this.data.fvId,
        },
      },
    };
    this.fvPhoneService.save(update).subscribe((result: ForeignVisitor) => {
      this.onClose({
        update: result,
        message: `Updated Phone Information for ${result.givenName} ${result.surname} `,
      });
    });
  }
  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.phoneDialog.close(message);
  }
}
