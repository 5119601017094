<div
  matSnackBarLabel
  class="account-approval-contact-snackbar"
  data-cy="account-approval-contact-snackbar"
>
  <app-account-expiration
    [user]="data.user"
    [contacts]="data.contacts"
  ></app-account-expiration>
</div>
<div matSnackBarActions>
  <button
    mat-button
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()"
  >
    Acknowledge
  </button>
</div>
