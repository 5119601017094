<div class="d-flex flex-column h-100 profile-pic-select-container mt-2">
  <div mat-dialog-title class="d-flex flex-row">
    <span>Update {{ fileTypeName }}</span>
  </div>
  <div
    mat-dialog-content
    class="
      d-flex
      flex-column
      justify-content-center
      align-items-center
      flex-grow-1
    "
  >
    <div
      [class.file-over-drop]="fileHoverDrop"
      (fileOver)="fileHoverDrop = $event"
      (onFileDrop)="fileHoverDrop = false"
      [uploader]="uploader"
      ng2FileDrop
      *ngIf="!fileItem"
      class="upload-file col p-5"
    >
      <div class="d-flex flex-column upload-file-wrapper">
        <span class="col"></span>
        <div class="file-upload d-flex flex-column justify-content-center">
          <h2 class="text-center">Drag file here</h2>
          <h6 class="text-center">— or —</h6>
          <div class="d-flex justify-content-around flex-row">
            <button
              mat-stroked-button
              (click)="fileInput.click()"
              color="primary"
              class="mr-1"
            >
              <mat-icon svgIcon="laptop"></mat-icon> Upload from computer
            </button>
          </div>
        </div>
        <span class="col"></span>
      </div>
    </div>
    <input
      #fileInput
      type="file"
      ng2FileSelect
      [attr.accept]="allowedMimeType.join(',')"
      [uploader]="uploader"
      style="display: none"
    />
    <div
      class="d-flex flex-row justify-content-center align-items-center"
      *ngIf="fileItem"
    >
      <mat-icon
        *ngIf="fileItem.file.type as fileType"
        [mimeType]="fileType"
        class="file-icon md-48"
        matTooltip="File Pending Upload"
        aria-hidden="false"
      ></mat-icon>
      <span class="file-name" [matTooltip]="fileItem.file.name">{{
        fileItem.file.name
      }}</span>
    </div>
    <mat-progress-bar
      *ngIf="uploader?.isUploading"
      class="file-upload-progress w-100"
      color="primary"
      [mode]="uploader.progress === 100 ? 'indeterminate' : 'buffer'"
      [value]="uploader.progress"
    >
    </mat-progress-bar>
  </div>
  <div mat-dialog-actions class="flex-row">
    <button mat-button mat-dialog-close>Cancel</button>
    <span class="flex-grow-1"></span>
    <button mat-button *ngIf="fileItem" (click)="clear()">Back</button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="fileItem"
      [disabled]="uploader?.isUploading"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>
