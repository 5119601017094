import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AccountApproverContact } from '@shared/models/account-approver-contact.model';
import { OrganizationService } from '@shared/services/organization.service';
import { catchError, of } from 'rxjs';

export const accountApproversResolver: ResolveFn<AccountApproverContact[]> = (
  route,
  state
) => {
  const orgService: OrganizationService = inject(OrganizationService);
  return orgService.getApprovers().pipe(catchError((err) => of([])));
};
