import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FarLocation } from '@shared/models/farlocation.model';
import { FarLocationService } from '@shared/services/farlocation.service';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { ItineraryControlComponent } from './itinerary-control.component';
import { FarPurposeType } from '@shared/models/far.model';

@Component({
  selector: 'app-itinerary-dialog',
  templateUrl: './itinerary-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ItineraryControlComponent,
  ],
})
export class ItineraryDialogComponent implements AfterViewChecked {
  @ViewChild('itineraryControl') itineraryControl: ItineraryControlComponent;
  busy: boolean = false;
  public location: FarLocation;
  public locationRequired: boolean = true;
  public farOwningOrgId: string = '';
  public farPurposeType: FarPurposeType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public locationData: any,
    private locationService: FarLocationService,
    private dialogRef: MatDialogRef<ItineraryDialogComponent>,
    private changeDetect: ChangeDetectorRef
  ) {
    this.location = locationData.location;
    this.locationRequired = locationData.locationRequired;
    this.farOwningOrgId = locationData.farOwningOrgId;
    this.farPurposeType = locationData.farPurposeType;
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  onSubmit() {
    this.busy = true;
    this.locationService.save(this.location).subscribe(
      (location) => {
        this.dialogRef.close(location);
      },
      () => {
        this.busy = false;
      }
    );
  }
}
