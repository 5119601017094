import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  USER_ADMIN_ACCOUNT_COLUMNS,
  USER_ADMIN_ACCOUNT_TAB,
  UserAdminAccountColumnDefs,
} from '@app/user-preference/config/user-admin-account.config.const';
import { UserPrefSaved } from '@app/user-preference/model/user-pref-saved.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { UserAvatarComponent } from '@shared/components/user-avatar/user-avatar.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { AccountDialogDirective } from '@shared/directives/account-dialog.directive';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { Role } from '@shared/models/role';
import { AccountStatus, User } from '@shared/models/user.model';
import { AlertService } from '@shared/services/alert.service';
import { ApprovalService } from '@shared/services/approval.service';
import { UserService } from '@shared/services/user.service';
import { NgPipesModule } from 'ngx-pipes';
import { Subject, Subscription, take } from 'rxjs';
import { ToggleUserStatusDirective } from '../../toggle-user-status.directive';
import { AccountDialogComponent } from '../account-dialog/account-dialog.component';
import { AccountPwdResendDialogComponent } from '../account-pwd-resend-dialog/account-pwd-resend-dialog.component';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-account-user-table',
  templateUrl: './account-user-table.component.html',
  styleUrls: ['./account-user-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    AuthEventsDialogDirective,
    ToggleUserStatusDirective,
    AccountDialogDirective,
    NgPipesModule,
    UserAvatarComponent,
  ],
  providers: [],
})
export class AccountUserTableComponent implements OnInit, OnDestroy {
  position = 'right';
  Role = Role;
  AccountStatus = AccountStatus;

  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;

  @AppConfigValue('highEnvFeatures')
  highEnvFeatures: boolean = false;

  currentNetwork: NetworkDomain = this.highEnvFeatures
    ? NetworkDomain.J
    : NetworkDomain.U;
  USER_ADMIN_ACCOUNT_COLUMNS: UserAdminAccountColumnDefs =
    USER_ADMIN_ACCOUNT_COLUMNS;

  @Input() users: User[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  private ngUnsubscribe = new Subject<void>();

  currentUser: User | null;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$!: Subscription;

  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    USER_ADMIN_ACCOUNT_TAB
  );

  constructor(
    public dialog: MatDialog,
    private alert: AlertService,
    private userService: UserService,
    private approvalService: ApprovalService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.auth.getUser();
  }

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  resendPassword(user: User) {
    this.alert
      .confirm({
        title: `Resend Temporary Password`,
        message: `Would you like to resend ${user.givenName} ${user.surname} a temporary password?`,
        performAction: () => {
          if (user.id) return this.userService.resendPassword(user.id, user);
        },
        successMsg: `${user.givenName} ${user.surname}'s temporary password has been resent!`,
        confirmText: 'Send',
      })
      .subscribe();
  }

  resetPasswordConfirmation(user: User) {
    this.alert
      .confirm({
        title: `Reset User's Password`,
        message: `${user.givenName} ${user.surname}'s password will automatically be reset.  Do you want to continue?`,
        performAction: () => this.userService.resetPassword(user.id!, false),
        confirmText: 'Reset',
      })
      .subscribe((result) => {
        this.dialog.open(AccountPwdResendDialogComponent, {
          data: { user: user, password: result['value'] },
          width: '600px',
        });
      });
  }

  checkPriv(targetRoles: Role[], currentUserRoles: Role[] = []) {
    if (
      targetRoles?.includes(Role.sv_admin) &&
      !currentUserRoles.includes(Role.sv_admin)
    ) {
      return false;
    } else return true;
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  extendOrgUserAccount(user: User) {
    this.approvalService
      .extendOrgUserAccount(user.id!)
      .pipe(take(1))
      .subscribe(() => this.filterParamsChange.emit(this.filterParams));
  }
}
