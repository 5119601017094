import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserPrefSaved } from '@app/user-preference/model/user-pref-saved.model';
import { UserPreferenceBlob } from '@app/user-preference/model/user-preference.model';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { Group } from '@shared/models/group.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { CrudService } from '@shared/services/crud.service';
import { GroupService } from '@shared/services/groups.service';
import { Repository } from '@shared/services/repository.service';
import { FileUploadModule } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GroupsCollectionComponent } from '../collection/collection.component';

@Component({
  selector: 'app-groups',
  templateUrl: 'groups.component.html',
  styleUrls: ['groups.component.scss'],
  providers: [Repository, { provide: CrudService, useClass: GroupService }],
  standalone: true,
  imports: [
    CommonModule,
    GroupsCollectionComponent,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    FileUploadModule,
  ],
})
export class GroupsComponent implements OnInit, AfterViewChecked {
  loading: boolean = false;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'createdDate',
    direction: 'DESC',
  });
  groups$: Observable<PageableCollection<Group>> = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  queryParamsArray: Array<string> = [];
  hasUserPreference: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  constructor(
    private repo: Repository<Group>,
    private changeDetect: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    const savedValue: UserPrefSaved = this.route.snapshot.data
      ?.userPrefFormGroup?.value as UserPrefSaved;
    const userId: string = this.authService.getUser()?.id as string;
    /** @TODO the foreignVisitorGroup controller is implemented differently than other controllers
     * It passes a userId directly into the queryParams instead of the currentUserCreatedBy = true param
     * used elsewhere.
     *
     * This is already done by default for all users, so the user preferences would allow this to be disabled by default which is the opposite
     * of other areas within the app.
     * **/

    this.filterParams.createdBy = userId;
    this.filterParams.currentUserCreatedBy = savedValue.group?.defaultMineOnly;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.filterParams.direction = 'DESC';
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;

    if (!this.filterParams.currentUserCreatedBy) {
      this.filterParams.createdBy = undefined;
      return this.repo.navigateWithParams(filterParams);
    }

    this.filterParams.createdBy = this.authService.getUser()?.id;
    this.repo.navigateWithParams(filterParams);
  }
}
