import { Pipe, PipeTransform } from '@angular/core';
import { Approval } from '@shared/models/approval';
import { Role } from '@shared/models/role';

@Pipe({
  name: 'highestRole',
  standalone: true,
})
export class HighestRolePipe implements PipeTransform {
  transform(approval: Approval): string {
    if (approval.systemUser?.roles) {
      if (approval.systemUser.roles.length === 0) return 'N/A';

      if (approval.systemUser.roles.includes(Role.sv_admin))
        return Role.sv_admin;
      else if (approval.systemUser.roles.includes(Role.sv_help_desk))
        return Role.sv_help_desk;
      else if (approval.systemUser.roles.includes(Role.sv_org_admin))
        return Role.sv_org_admin;
      return Role.sv_org_user;
    }
    return 'N/A';
  }
}
