import { Pipe, PipeTransform } from '@angular/core';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { ScreeningDisplayMap } from '@shared/models/screening.model';

/**
 * @deprecated
 * To be removed once [LUKECIT-7621](https://jira.csde.caci.com/browse/LUKECIT-7621) is complete.  Use [status-display.pipe](https://gitlab.citadel.caci.com/luke06/frontend/cteam_gui/-/blob/6e9e435153f9313706ec2c58a96fd9fc79b72d61/src/app/shared/helpers/screening-display.pipe.ts) instead which seperates icon, color, classList and lable into spearate pipes.
 */
@Pipe({
  name: 'statusMapper',
  standalone: true,
})
export class StatusMapperPipe implements PipeTransform {
  statusMap = ScreeningDisplayMap;
  transform(
    status?: string,
    parameter?: 'icon' | 'color' | 'status' | 'result',
    result?:
      | 'GREEN'
      | 'MACHINE_GREEN'
      | 'REVIEWED_GREEN'
      | 'YELLOW'
      | 'RED'
      | 'MACHINE_RED'
      | 'REVIEWED_RED_WITH_DEROG'
      | 'REVIEWED_RESTRICTED'
      | 'MACHINE_PURPLE'
      | 'USP'
  ): string | null | undefined {
    /**
     * The status mapper pipe is used to programatically determine the correct status to return
     * and display based on the below passed in parameters for all screening objects.
     * @param status - Takes in the screening status field of the determining record.
     * @param parameter - Takes in the property the user wants to determine and display results
     * @param result - Takes in the screening result field of the determining record
     */
    if (status !== undefined) {
      if (!!result && status === ScreeningStatus.Completed) {
        status = result;
      }

      if (parameter === 'status') {
        return this.statusMap.get(status)?.statusLabel;
      }
      if (parameter === 'icon') {
        return this.statusMap.get(status)?.icon;
      }
      if (parameter === 'color') {
        return this.statusMap.get(status)?.color;
      }
      if (parameter === 'result') {
        if (result) {
          return this.statusMap.get(result)?.statusLabel;
        }
      }
    }
    return null;
  }
}
