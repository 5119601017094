<mat-toolbar
  class="mat-elevation-z4 app-toolbar position-relative"
  color="primary"
>
  <mat-toolbar-row
    *ngIf="showBanner"
    class="classification-banner d-flex flex-column"
  >
    <span class="col text-uppercase text-center">{{ classification }}</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <button
      id="main-nav-button"
      style="margin-left: -5px"
      mat-icon-button
      matTooltip="Click for Navigation Options"
      [matMenuTriggerFor]="navMenu"
      aria-label="navigation"
    >
      <mat-icon style="margin-bottom: 5px">menu</mat-icon>
    </button>
    <!--Navigation Menu-->
    <mat-menu #navMenu="matMenu" [class]="'menu--main'">
      <button
        id="home-nav-menu-button"
        mat-menu-item
        [routerLink]="'/'"
        [routerLinkActive]="'font-weight-bold active'"
        [routerLinkActiveOptions]="homeLinkOptions"
      >
        <mat-icon>home</mat-icon> <span> Home </span>
      </button>
      <button
        id="fvs-nav-menu-button"
        mat-menu-item
        [routerLink]="'/fvs'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="FVViewRoles"
      >
        <mat-icon>perm_identity</mat-icon>
        <span> Foreign Nationals (FN) </span>
      </button>
      <button
        id="fars-nav-menu-button"
        mat-menu-item
        [routerLink]="'/fars'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="FarViewRoles"
      >
        <mat-icon>language</mat-icon>
        <span> Foreign Access Requests (FAR) </span>
      </button>
      <button
        id="screening-nav-menu-button"
        mat-menu-item
        [routerLink]="'/screening'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="ScreeningRoles"
      >
        <mat-icon>fingerprint</mat-icon> <span> Screening Review </span>
      </button>
      <button
        id="location-nav-menu-button"
        mat-menu-item
        [routerLink]="'/locations'"
        [routerLinkActive]="'font-weight-bold-active'"
        *appIfRoles="LocViewRoles"
      >
        <mat-icon svgIcon="office-building-marker"></mat-icon>
        <span> Locations </span>
      </button>
      <button
        id="group-nav-menu-button"
        mat-menu-item
        [routerLink]="'/groups'"
        [routerLinkActive]="'font-weight-bold-active'"
        *appIfRoles="GroupViewRoles"
      >
        <mat-icon svgIcon="group"></mat-icon>
        <span> Group Management </span>
      </button>
      <ng-container *appIfRoles="AdminViewRoles">
        <div
          class="text-white bg-primary admin-panel py-2"
          [stopPropagation]="['click']"
        >
          <mat-icon svgIcon="security" class="mx-3"></mat-icon
          ><span>Admin</span>
        </div>
      </ng-container>
      <button
        id="metrics-nav-menu-button"
        mat-menu-item
        [routerLink]="'/metrics'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="MetricsViewRoles"
      >
        <mat-icon svgIcon="chart-box"></mat-icon> <span> Metrics </span>
      </button>
      <button
        id="monitoring-nav-menu-button"
        mat-menu-item
        [routerLink]="'/monitoring'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="MonitoringViewRoles"
      >
        <mat-icon svgIcon="monitor-dashboard"></mat-icon>
        <span>System Monitoring</span>
      </button>
      <button
        id="usage-nav-menu-button"
        mat-menu-item
        [routerLink]="'/usage'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="SystemUsageViewRoles"
      >
        <mat-icon svgIcon="monitor-account"></mat-icon>
        <span>System Usage</span>
      </button>
      <button
        id="org-nav-menu-button"
        mat-menu-item
        [routerLink]="'/organizations'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="AdminViewRoles"
      >
        <mat-icon svgIcon="home-city"></mat-icon> <span> Organizations </span>
      </button>
      <button
        mat-menu-item
        id="system-alerts-menu-button"
        [routerLink]="['systemalerts']"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="SystemAlertsEditRoles"
      >
        <mat-icon svgIcon="bullhorn-variant-outline"></mat-icon>
        <span> System Alerts </span>
      </button>
      <ng-container *ngIf="!disableUserAdmin">
        <button
          id="admin-nav-menu-button"
          mat-menu-item
          [routerLink]="'/admin'"
          [routerLinkActive]="'font-weight-bold active'"
          *appIfRoles="AdminViewRoles"
        >
          <mat-icon>settings</mat-icon> <span> User Administration </span>
        </button>
      </ng-container>
      <ng-container
        *appIfConfig="{ key: 'accountApprovalsEnabled', value: true }"
      >
        <ng-container *ngIf="lowEnvFeatures">
          <button
            mat-menu-item
            [routerLink]="'/approvals'"
            [routerLinkActive]="'font-weight-bold active'"
            *appIfRoles="UserApproverRoles"
          >
            <mat-icon svgIcon="account-reactivate-outline"></mat-icon>
            <span>Approvals</span>
          </button>
        </ng-container>
      </ng-container>
      <button
        mat-menu-item
        [routerLink]="'/feedback'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="AdminOnlyRoles"
      >
        <mat-icon>feedback</mat-icon>
        <span>Feedback Review</span>
      </button>
    </mat-menu>

    <!--Logo-->
    <a
      [routerLink]="'/'"
      class="d-flex text-white ml-3"
      aria-label="Citadel Logo"
    >
      <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
    </a>
    <div class="flex-fill"></div>
    <!--Async Notifications-->
    <button
      *ngIf="asyncJobs?.totalElements"
      id="notification-toolbar-button"
      class="mr-2"
      mat-icon-button
      matTooltip="Bulk Upload Notifications"
      [matMenuTriggerFor]="notificationMenu"
      aria-label="help"
    >
      <mat-icon
        svgIcon="bell-outline"
        [matBadge]="asyncJobs?.totalElements"
        matBadgeColor="accent"
        matBadgeSize="small"
        matBadgeOverlap="true"
      ></mat-icon>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notification-menu">
      <mat-toolbar
        class="d-flex justify-content-between"
        style="height: 40px; background: white"
      >
        <h6>Notifications</h6>
        <div>
          <button
            *ngIf="false"
            mat-icon-button
            matTooltip="Refresh"
            (click)="refresh(); $event.stopPropagation()"
          >
            <mat-icon svgIcon="refresh"></mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Mark All Viewable as Read"
            (click)="markAllAsRead(); $event.stopPropagation()"
          >
            <mat-icon svgIcon="read"></mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <mat-progress-bar
        color="accent"
        [mode]="loading ? 'indeterminate' : 'determinate'"
      ></mat-progress-bar>
      <mat-list class="p-0" style="height: 365px">
        <ng-container *ngFor="let item of asyncJobs?.content">
          <mat-list-item class="pb-2">
            <mat-icon
              *ngIf="item.type === 'Foreign Visitor Bulk Upload'"
              matTooltip="Foreign Visitor Bulk Upload"
              class="text-primary mr-2"
              style="font-size: 35px"
              matListItemAvatar
              >perm_identity</mat-icon
            >
            <mat-icon
              *ngIf="item.type === 'Location Bulk Resolve'"
              matTooltip="Address Normalization - Attempting to Add Geocoordinates to existing locations"
              class="text-primary"
              style="width: 35px; height: 35px; margin-right: 0.75rem"
              matListItemAvatar
              svgIcon="location-enter"
            ></mat-icon>
            <mat-icon
              *ngIf="item.type === 'Location Bulk Upload'"
              matTooltip="Location Bulk Upload"
              class="text-primary mr-2"
              style="font-size: 35px"
              matListItemAvatar
              svgIcon="office-building-marker"
            ></mat-icon>
            <span matListItemTitle *ngIf="item.type === 'Location Bulk Resolve'"
              >Address Normalization
            </span>
            <span
              *ngIf="item.type !== 'Location Bulk Resolve'"
              matListItemTitle
              [matTooltip]="item.fileName | default"
              >{{ item.fileName | shorten: 20:"..." | default }}</span
            >
            <div matListItemLine>
              <span *ngIf="item.createdDate">{{
                item.createdDate | date: "MMM d, y, HH:mm:ss" | default
              }}</span>
            </div>
            <div
              matListItemMeta
              class="d-flex align-items-center h-100"
              [class]="item.status === 'Completed' ? 'ml-0 mr-2' : ''"
            >
              <button
                mat-icon-button
                [disableRipple]="true"
                class="in-progress"
                *ngIf="item.status === 'In Progress'"
                matTooltip="In Progress"
                (click)="$event.stopPropagation()"
              >
                <mat-icon svgIcon="autorenew" class="text-info"></mat-icon>
              </button>
              <div *ngIf="item.status === 'Completed'">
                <button
                  mat-icon-button
                  [appAsyncDetailsDialog]="item"
                  (asyncUpdated)="refresh($event)"
                  (click)="$event.stopPropagation()"
                  [matTooltip]="
                    item.fvGroupId ||
                    item.type === 'Location Bulk Upload' ||
                    item.type === 'Location Bulk Resolve'
                      ? 'Completed'
                      : 'Completed - Results not available, group no longer exists.'
                  "
                >
                  <mat-icon
                    class="text-primary"
                    svgIcon="progress-check"
                  ></mat-icon>
                </button>
                <button
                  *ngIf="item.fvGroupId || item.type === 'Location Bulk Upload'"
                  mat-icon-button
                  (click)="navigateToGroup(item)"
                  matTooltip="Go to Results"
                >
                  <mat-icon svgIcon="open-in-new"></mat-icon>
                </button>
              </div>
              <button
                *ngIf="item.status === 'Error'"
                [appAsyncDetailsDialog]="item"
                (asyncUpdated)="refresh($event)"
                matTooltip="Error"
                mat-icon-button
                (click)="$event.stopPropagation()"
              >
                <mat-icon
                  class="text-error"
                  svgIcon="alert-circle-outline"
                ></mat-icon>
              </button>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
      <app-paginator
        class="sticky"
        [filterParams]="defaultFilterParams"
        [pageIndex]="asyncJobs?.number"
        [pageSize]="asyncJobs?.size"
        [totalElements]="asyncJobs?.totalElements"
        [hidePageSize]="true"
        [showFirstLastButtons]="false"
        (filterParamsChange)="onFilterParamChange($event)"
        (click)="$event.stopPropagation()"
      ></app-paginator>
    </mat-menu>
    <!-- Help -->
    <button
      id="help-toolbar-button"
      class="mr-2"
      mat-icon-button
      matTooltip="Help"
      [matMenuTriggerFor]="helpMenu"
      aria-label="help"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <mat-menu #helpMenu="matMenu">
      <button
        id="help-button"
        mat-menu-item
        [appResourceFileDownload]="ResourceFileType.HELP_FILE"
        matTooltip="Help"
        aria-label="Help"
      >
        <mat-icon>help_center</mat-icon> Help
      </button>
      <button
        id="update-help-button"
        *appIfRoles="Role.sv_admin"
        mat-menu-item
        [appResourceFileUpload]="ResourceFileType.HELP_FILE"
        matTooltip="Upload New version of Help Document"
        aria-label="Update Help"
      >
        <mat-icon svgIcon="cloud-upload"></mat-icon> Update Help
      </button>
      <button id="about-button" mat-menu-item appAboutDialog aria-label="About">
        <mat-icon svgIcon="information-outline"></mat-icon> About
      </button>
      <a
        id="contact-support-button"
        mat-menu-item
        appContactSupport
        aria-label="Contact Support"
      >
        <mat-icon svgIcon="face-agent"></mat-icon> Contact Support
      </a>
      <button
        id="release-notes-button"
        mat-menu-item
        [routerLink]="['/release-notes']"
      >
        <mat-icon svgIcon="note-text"></mat-icon> Release Notes
      </button>
      <button
        id="user-feedback-button"
        mat-menu-item
        appUserFeedback
        aria-label="User Feedback"
      >
        <mat-icon svgIcon="clipboard-text-outline"></mat-icon> User Feedback
      </button>
    </mat-menu>
    <!-- User Profile Menu-->
    <button
      id="user-profile-toolbar-button"
      class="ml-2"
      mat-icon-button
      matBadge
      [matBadgeIconHidden]="!userService.filteredRoles?.length"
      matBadgeColor="accent"
      matBadgeOverlap="true"
      matBadgePosition="below after"
      matBadgeIcon="account-switch-outline"
      [matMenuTriggerFor]="userProfileMenu"
      aria-label="profile"
      aria-hidden="false"
    >
      <div class="user-profile-icon">
        <app-user-avatar [size]="40" [user]="systemUser"></app-user-avatar>
      </div>
    </button>
    <mat-menu #userProfileMenu="matMenu">
      <!-- Help -->
      <button
        id="user-profile-nav-button"
        mat-menu-item
        [routerLink]="['/profile']"
      >
        <app-user-avatar class="pl-1" [user]="systemUser"></app-user-avatar>
        <span class="user-name"
          >{{ systemUser.givenName }} {{ systemUser.surname }}</span
        >
      </button>
      <mat-divider></mat-divider>
      <button
        *appSwitchRoles
        mat-menu-item
        id="acct-switch-roles-button"
        aria-label="Switch Roles"
      >
        <mat-icon
          aria-hidden="false"
          [matBadge]="
            userService.filteredRoles?.length
              ? userService.filteredRoles.length
              : undefined
          "
          matBadgeColor="accent"
          [matBadgeHidden]="userService.filteredRoles?.length ? false : true"
          svgIcon="account-switch-outline"
        ></mat-icon>
        Switch Roles
      </button>
      <button
        id="change-password-button"
        *ngIf="auth.canChangePassword"
        mat-menu-item
        appChangePassword
        aria-label="Change Password"
      >
        <mat-icon>lock</mat-icon> Change Password
      </button>
      <button
        id="user-preferences-button"
        mat-menu-item
        [routerLink]="['/user-preferences']"
        aria-label="User Preferences"
      >
        <mat-icon svgIcon="account-cog-outline"></mat-icon>
        User Preferences
      </button>
      <button
        id="logout-button"
        mat-menu-item
        (click)="logout()"
        aria-label="logout"
      >
        <mat-icon>input</mat-icon> Logout
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="isRouting" mode="indeterminate"></mat-progress-bar>
