import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { RolesSelectControlComponent } from '@shared/controls/roles-select-control/roles-select-control.component';
import { SelectFvComponent } from '@shared/controls/select-fv/select-fv.component';
import { SelectGroupComponent } from '@shared/controls/select-group/select-group.component';
import { UserSelectComponent } from '@shared/controls/user-select/user-select.component';
import { AppConfigDirective } from '@shared/directives/app-config.directive';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { FilterConfigType } from '@shared/enums/filter-config-type.enum';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FilterParams } from '@shared/models/filter-params.model';
import { Roles } from '@shared/models/role';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { resolveProp } from '@shared/shared.functions';
import { isEmpty, isString } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OrgLocationSelectComponent } from '../org-location-select/org-location-select.component';
import { DateTimeComponent } from '@shared/controls/date-time/date-time.component';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    AuthEventsDialogDirective,
    CountrySelectComponent,
    DefaultPipe,
    OrgLocationSelectComponent,
    OrganizationSelectComponent,
    RolesSelectControlComponent,
    SelectFvComponent,
    UserSelectComponent,
    SelectGroupComponent,

    RolesDirective,
    AppConfigDirective,
    DateTimeComponent,
  ],
})
export class AdvancedSearchComponent implements OnInit {
  // Just saving filter ref objs, in case needed from special filters (FN, Org, etc)
  filterRefObjs: { [key: string]: any } = {};

  FilterConfigType = FilterConfigType;

  data: FilterParams;
  filterFields: FilterConfig[];

  resolveProp = resolveProp;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: AdvancedSearchDialogConfig,
    public dialogRef: MatDialogRef<AdvancedSearchComponent>
  ) {}

  ngOnInit(): void {
    this.data = this.inputData.filterParams
      ? this.inputData.filterParams.clone()
      : new FilterParams();
    this.filterFields = this.inputData.filterFields;
    this.filterFields?.forEach((config) => {
      if (config.type === FilterConfigType.TOGGLE) {
        this.data[config.name] = coerceBooleanProperty(this.data[config.name]);
      }
    });
  }

  clear() {
    this.filterFields?.forEach((config) => {
      this.clearField(config, this.data);
      delete this.filterRefObjs[config.name];
    });
  }

  clearField(config: FilterConfig, params: FilterParams) {
    if (config.type === FilterConfigType.DATE_RANGE) {
      delete params[config.name + 'Start'];
      delete params[config.name + 'End'];
    }

    delete params[config.name];
  }

  submit() {
    this.trimSpace();
    this.dialogRef.close(this.data);
  }

  trimSpace() {
    this.filterFields?.forEach((config) => {
      if (isString(this.data[config.name])) {
        this.data[config.name] = this.data[config.name].trim();
        if (isEmpty(this.data[config.name])) this.clearField(config, this.data);
      }
    });
  }

  static openDialog(
    dialog: MatDialog,
    filterParams: FilterParams,
    filterFields: FilterConfig[]
  ): MatDialogRef<AdvancedSearchComponent, FilterParams> {
    return dialog.open<
      AdvancedSearchComponent,
      AdvancedSearchDialogConfig,
      FilterParams
    >(AdvancedSearchComponent, {
      data: { filterParams, filterFields },
      minWidth: 650,
      width: '30%',
      disableClose: true, //must click button on dialog to close
    });
  }

  protected readonly Roles = Roles;
}

interface AdvancedSearchDialogConfig {
  filterParams: FilterParams;
  filterFields: FilterConfig[];
}
