import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AuthSummary } from '@shared/models/auth-event.model';
import { catchError, EMPTY, from, of } from 'rxjs';

export const authSummaryResolver: ResolveFn<AuthSummary | null> = (
  route,
  state
) => {
  const auth: AuthService = inject(AuthService);
  return from(auth.getAuthSummary()).pipe(
    catchError((err) => {
      /**
       * Make this non-blocking if an error is encountered.
       */
      return of(null);
    })
  );
};
