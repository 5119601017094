import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { AdminOnlyRoles } from '@app/shared/models/role-permissions';
import { USER_FEEDBACK_TAB } from '@app/user-preference/config/user-feedback.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';

export const USER_FEEDBACK_ROUTES: Routes = [
  {
    path: 'feedback',
    title: 'Feedback Review',
    loadComponent: () =>
      import('./user-feedback.component').then((c) => c.UserFeedbackComponent),
    canActivate: [AuthGuard],
    data: {
      roles: AdminOnlyRoles,
      viewDefKey: USER_FEEDBACK_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];
