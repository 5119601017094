import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data.model';
import { CrudChangeEvent } from '@shared/models/crud-change-event';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { MilitaryDatePipe } from '@shared/pipes/military-date.pipe';
import { AlertService } from '@shared/services/alert.service';
import { FvService } from '@shared/services/fv.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentTypeLabel } from '@app/foreign-visitors/fv/document-id/document-id.component';
import { DocumentId } from '@shared/models/document-id.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { DocumentIdDialogComponent } from './document-id-dialog/document-id-dialog.component';

export interface DialogData<T> {
  mode: 'create' | 'update' | 'delete';
  model: T;
}
@Component({
  selector: 'app-fvview-document-id',
  templateUrl: './fvview-document-id.component.html',
  styleUrls: ['./fvview-document-id.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    DefaultPipe,
    MilitaryDatePipe,
    MatTooltipModule,
  ],
})
//implements DoCheck
export class FvviewDocumentIdComponent implements DoCheck {
  @Input() showActions: boolean = true;
  @Input() parentId: string;
  @Input() set documentIds(data: DocumentId[]) {
    this._data = data;
  }
  get documentIds() {
    return this._data;
  }

  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() type: 'DIPLOMAT' | 'DACA' = 'DIPLOMAT';
  @Input() hasDocument: boolean = false;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;
  typeLabel = DocumentTypeLabel;
  @Output() documentChange = new EventEmitter();
  refresh$: Observable<CrudChangeEvent<ForeignVisitor>> =
    this.fvService.changeEvent$.pipe(
      startWith({} as CrudChangeEvent<ForeignVisitor>)
    );
  private _data: DocumentId[];
  private _iterableDiffer: IterableDiffer<DocumentId>;

  documentIds$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<ForeignVisitor>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removeDocumentIdRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addDocumentIdRecord(refresh.model as DocumentId);
      if (refresh.type === CrudChangeType.update)
        this.updateDocumentIdRecord(refresh.model as DocumentId);
      this.documentChange.emit(this.documentIds);
      return this.documentIds;
    })
  );

  constructor(
    private fvService: FvService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private iterableDiffers: IterableDiffers
  ) {
    this._iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._iterableDiffer.diff(this._data);
    if (changes) {
      this.documentIds = this._data;
    }
  }

  createDocumentId() {
    this.openDialog({
      documentType: this.type,
      foreignVisitor: { id: this.parentId },
    });
  }

  addDocumentIdRecord(documentIdRecord: DocumentId) {
    if (this.documentIds) {
      this.documentIds = [...this.documentIds, documentIdRecord];
    }
  }

  updateDocumentId(documentId: DocumentId) {
    this.openDialog({
      ...documentId,
      foreignVisitor: { id: this.parentId },
    });
  }

  updateDocumentIdRecord(documentIdRecord: DocumentId) {
    if (this.documentIds) {
      const index = this.documentIds.findIndex((documentId) => {
        return documentId.id === documentIdRecord.id;
      });
      this.documentIds[index] = documentIdRecord;
      this.documentIds = [...this.documentIds];
    }
  }

  removeDocumentId(documentId: DocumentId) {
    let confirmData: ConfirmDialogData = {
      title:
        `Remove` + this.typeLabel[this.type] + ` Id #${documentId.documentId}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () =>
        this.fvService.deleteFvDocId(this.parentId, documentId.documentId!),
    };
    this.alertService.confirmDelete(confirmData);
  }

  removeDocumentIdRecord(id: string) {
    if (this.documentIds) {
      this.documentIds = this.documentIds.filter((x) => x.id !== id);
    }
  }

  openDialog(documentId: DocumentId) {
    this.dialog.open(DocumentIdDialogComponent, {
      data: documentId,
      width: '1200px',
    });
  }
}
