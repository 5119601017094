<ul *ngIf="contacts && contacts.length > 0">
  <li *ngFor="let contact of contacts">
    {{ contact.approverGivenName }} {{ contact.approverSurname }} (<a
      [attr.href]="
        'mailto:' +
        contact.approverEmail +
        '?subject=' +
        emailSubject +
        '&body=' +
        emailBody
      "
    >
      {{ contact.approverEmail }}</a
    >)
  </li>
</ul>
