import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { Roles } from '@shared/models/role';
import { accountApproversResolver } from './resolver/account-approvers.resolver';
import { authSummaryResolver } from './resolver/auth-summary.resolver';
import { userResolver } from './resolver/user.resolver';

export const USER_PROFILE_ROUTES: Routes = [
  {
    path: 'profile',
    title: 'User Profile',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./profile/profile-toolbar/profile-toolbar.component').then(
        (c) => c.ProfileToolbarComponent
      ),
    data: {
      roles: Roles,
      pageIcon: 'account-cog-outline',
    },
    resolve: {
      user: userResolver,
      authSummary: authSummaryResolver,
      approvers: accountApproversResolver,
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./profile/profile.component').then((c) => c.ProfileComponent),
      },
    ],
  },
];
