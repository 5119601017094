import { Role } from '@shared/models/role';

/** Can View FV Screens */
export const FVViewRoles: Role[] = [
  Role.sv_org_user,
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_analyst,
  Role.sv_vetter,
];

/** Can Add FVs */
export const FVEditRoles: Role[] = [Role.sv_admin, Role.sv_org_user];

/** Can Rescreen FVs */
export const FVRescreenRoles: Role[] = [
  Role.sv_admin,
  Role.sv_org_user,
  Role.sv_vetter,
];

/** Can View FAR Screens */
export const FarViewRoles: Role[] = [
  Role.sv_org_user,
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_analyst,
  Role.sv_vetter,
];

/** Can Add FARS */
export const FarEditRoles: Role[] = [Role.sv_admin, Role.sv_org_user];

/** Can View Org Location Screens */
export const LocViewRoles: Role[] = [
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_org_user,
  Role.sv_org_admin,
];

export const LocViewGeoCodeRoles: Role[] = [Role.sv_admin, Role.sv_help_desk];

/** Can View Organization Screens */
export const OrgViewRoles: Role[] = [
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_org_admin,
];

export const MetricsViewRoles: Role[] = [
  Role.sv_org_admin,
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_org_user,
];
/** Can View Screening Screens */
export const ScreeningRoles: Role[] = [Role.sv_vetter];

/** Can View Admin Screens */
export const AdminViewRoles: Role[] = [
  Role.sv_help_desk,
  Role.sv_org_admin,
  Role.sv_admin,
];

/** Can Add Users */
export const UserEditRoles: Role[] = [
  Role.sv_help_desk,
  Role.sv_org_admin,
  Role.sv_admin,
];

/**Can View Group Management Screens */
export const GroupViewRoles: Role[] = [
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_org_user,
];

/** Can See Monitoring Views */
export const MonitoringViewRoles: Role[] = [Role.sv_help_desk, Role.sv_admin];

/** Can See System Usage Views */
export const SystemUsageViewRoles: Role[] = [Role.sv_help_desk, Role.sv_admin];

/** Can Add System Alerts */
export const SystemAlertsEditRoles: Role[] = [Role.sv_help_desk, Role.sv_admin];

/** Can Add Users across Orgs */
export const UserCrossOrgEditRoles: Role[] = [Role.sv_help_desk, Role.sv_admin];

/** Can Approve Org Admin Roles */
export const UserApproverRoles: Role[] = [Role.sv_help_desk, Role.sv_admin];

/** Can See User Feedback Views */
export const UserFeedbackRoles: Role[] = [
  Role.sv_admin,
  Role.sv_help_desk,
  Role.sv_org_admin,
];

/** Who can assign specific roles */
export const RoleAssignableBy: { [key in Role]: Role[] } = {
  [Role.sv_admin]: [Role.sv_admin],
  [Role.sv_analyst]: [Role.sv_admin, Role.sv_help_desk],
  [Role.sv_help_desk]: [Role.sv_admin, Role.sv_help_desk],
  [Role.sv_org_admin]: [Role.sv_admin, Role.sv_help_desk],
  [Role.sv_org_user]: [Role.sv_admin, Role.sv_help_desk, Role.sv_org_admin],
  [Role.sv_vetter]: [Role.sv_admin],
};

export const AdminOnlyRoles: Role[] = [Role.sv_admin];
