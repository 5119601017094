import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RolesSelectControlComponent } from '@shared/controls/roles-select-control/roles-select-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { Role, RoleLabels, Roles } from '@shared/models/role';
import { User } from '@shared/models/user.model';
import { UserService } from '@shared/services/user.service';
import { without, xor } from 'lodash';

@Component({
  selector: 'app-switch-roles-dialog',
  templateUrl: './switch-roles-dialog.component.html',
  styleUrls: ['./switch-roles-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RolesSelectControlComponent,
    MatChipsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
  ],
})
export class SwitchRolesDialogComponent {
  RoleLabels = RoleLabels;

  allRoles: Role[] = [];
  Roles: Role[] = Roles;
  loading: boolean = false;

  prevSelected: Role[];

  selectedRoles: Role[];

  constructor(
    public dialogRef: MatDialogRef<SwitchRolesDialogComponent>,
    public userService: UserService
  ) {
    userService.getCurrent(true).subscribe((user: User) => {
      if (user.roles) {
        this.allRoles = user.roles;
        this.selectedRoles = userService.filteredRoles?.length
          ? userService.filteredRoles
          : this.allRoles;
        this.prevSelected = [...this.selectedRoles];
        this.loading = false;
      }
    });
    this.loading = true;
  }

  onRemove(role: Role) {
    this.selectedRoles = without(this.selectedRoles, role);
  }

  get isValid(): boolean {
    return (
      !!this.selectedRoles?.length &&
      !!xor(this.prevSelected, this.selectedRoles)?.length
    );
  }

  reset() {
    this.userService.filteredRoles = [];
    this.dialogRef.close(this.userService.filteredRoles);
  }

  submit() {
    if (
      !this.selectedRoles.length ||
      this.selectedRoles.length === this.allRoles.length
    ) {
      return this.reset();
    }

    this.userService.filteredRoles = this.selectedRoles;
    this.dialogRef.close(this.userService.filteredRoles);
  }

  static openDialog(
    dialog: MatDialog
  ): MatDialogRef<SwitchRolesDialogComponent> {
    return dialog.open<SwitchRolesDialogComponent>(SwitchRolesDialogComponent, {
      width: '600px',
    });
  }
}
