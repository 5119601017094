import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckbox,
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FarPurposeType } from '@shared/models/far.model';

@Component({
  selector: 'app-location-unknown',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatIconModule,
  ],
  templateUrl: './location-unknown.component.html',
  styleUrl: './location-unknown.component.scss',
})
export class LocationUnknownComponent implements OnChanges {
  @Input() formGroup: FormGroup<any>;
  @Input() formControlName: string | number | null = null;
  @Input() farPurposeType?: FarPurposeType;
  @Input() locationUnknown?: boolean;
  @Output() locationUnknownChangedEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);
  @Input() label = 'Location is Unknown';
  @Input()
  tooltip = `Select the "${this.label}" checkbox if you are unable to supply a valid location.`;
  @ViewChild('locationUnknownCheckbox') locationUnknownCheckbox: MatCheckbox;
  get isVisible(): boolean {
    if (!this.farPurposeType) return false;
    return ![
      FarPurposeType.SYSTEM_ACCESS,
      FarPurposeType.VIRTUAL_MEETING,
    ].includes(this.farPurposeType);
  }

  locationUnknownChanged(change: MatCheckboxChange) {
    this.locationUnknownChangedEmitter.emit(change.checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.farPurposeType && !changes.farPurposeType.isFirstChange()) {
      this.locationUnknown = false;
    }
  }
}
