import { Pipe, PipeTransform } from '@angular/core';
import { BaseModel } from '@shared/models/base.model';
import { Organization } from '@shared/models/organization.model';

@Pipe({
  name: 'auditOrg',
  standalone: true,
})
export class AuditOrgPipe implements PipeTransform {
  transform(value: BaseModel): Organization | null {
    if (!value.auditMetadata) return null;
    if (value.auditMetadata.owningOrganizationName) {
      return {
        id: value.ownerOrganizationId,
        name: value.auditMetadata.owningOrganizationName,
        shortName: value.auditMetadata.owningOrganizationShortName,
      };
    }
    return null;
  }
}
