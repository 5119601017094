<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    id="contact-select"
    [(ngModel)]="value"
    (ngModelChange)="emitChangeEvent()"
    [required]="required"
    [disabled]="disabled"
    (selectionChange)="emitChangeEvent()"
    [name]="label"
    (openedChange)="monitorScroll($event)"
  >
    <mat-select-trigger
      ><strong>{{
        value?.groupName ? value.groupName : (value | fullname)
      }}</strong>
      | {{ value?.email }}</mat-select-trigger
    >
    <mat-option class="search-option">
      <ngx-mat-select-search
        [searching]="searching"
        noEntriesFoundLabel="No Contacts Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
        [disableScrollToActiveOnOptionsChanged]="true"
        [clearSearchInput]="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngIf="orgId"
      [appContactEdit]="{
        type: type,
        organization: { id: orgId }
      }"
      (appContactEdited)="newContactCreated($event)"
    >
      <mat-icon svgIcon="plus-circle-outline"></mat-icon> New
      {{ label }}</mat-option
    >
    <mat-divider *ngIf="(filteredValues | async)?.length!"></mat-divider>
    <mat-option
      class="flex-fill"
      *ngFor="let contact of filteredValues | async"
      [value]="contact"
      ><strong>{{
        contact?.groupName ? contact.groupName : (contact | fullname)
      }}</strong>
      | {{ contact.email }}</mat-option
    >
  </mat-select>
</mat-form-field>
