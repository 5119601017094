import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollectionComponent } from '@app/foreign-visitors/dashboard/collection/collection.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { hasValues } from '@shared/helpers/has-values';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Passport } from '@shared/models/passport.model';
import { CrudService } from '@shared/services/crud.service';
import { FvService } from '@shared/services/fv.service';
import { Repository } from '@shared/services/repository.service';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-visitor-baseline',
  templateUrl: './visitor-baseline.component.html',
  styleUrls: ['./visitor-baseline.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: FvService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VisitorBaselineComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VisitorBaselineComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    CountrySelectComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    CollectionComponent,
    MatTooltipModule,
  ],
})
export class VisitorBaselineComponent
  extends BaseControlComponent<ForeignVisitor>
  implements Validator, OnInit, OnDestroy
{
  private _passport: Passport = {};
  get passport(): Passport {
    return this._passport;
  }

  @Input()
  set passport(value: Passport) {
    this._passport = value || {};
  }

  @Output() passportChange: EventEmitter<Passport> = new EventEmitter();
  @ViewChild('baselineForm')
  baselineForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  value: ForeignVisitor = {};

  fvs: ForeignVisitor[];
  filterParamObj: FilterParams = new FilterParams();
  today = new Date();

  onValidationChange: any = () => {};

  /**
   * Subject used to initialize a stream of fvs.
   * This subject will remain empty until the first ngModelChange Occurs.
   * The public observabel will be exposed and a 500ms debounce time is added
   * so that each change is emitted only after 500ms from the last emitted value
   * of the Subject
   */
  private changesSub = new Subject<any>();
  public changesSub$ = this.changesSub.asObservable().pipe(debounceTime(500));
  unsubscribe = new Subject<void>();

  /**
   * Observable for PageableCollection of Foreign Visitors. If the filter has values,
   * then it will emit the value for the fvs given the current filter Params.
   * Otherwise it will emit a null value.
   */
  fvs$ = this.changesSub$.pipe(
    switchMap((fvFilter) => {
      if (hasValues(fvFilter)) return this.repo.collection$;
      return of(null);
    })
  );

  constructor(private repo: Repository<ForeignVisitor>) {
    super();
  }

  ngOnInit(): void {
    this.changesSub$.pipe(takeUntil(this.unsubscribe)).subscribe((filters) => {
      this.repo.onFilterParamChange(filters);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  refreshFilterParams() {
    this.filterParamObj = new FilterParams();
    let fvFilterFields = ['givenName', 'surname', 'dateOfBirth'];
    let passportFilterFields = ['number', 'issuingCountryCode'];
    fvFilterFields.forEach((filterField) => {
      this.addToFilterParams(
        this.value[filterField as keyof typeof this.value],
        filterField
      );
    });
    passportFilterFields.forEach((filterField) => {
      this.addToFilterParams(
        this._passport[filterField as keyof typeof this.passport],
        filterField
      );
    });
  }

  addBaseline() {
    this.emitChangeEvent();
  }

  addToFilterParams(event: Passport | ForeignVisitor, filterName: string) {
    /*adding filter field and value to object to update
    baselinefilterparam observable in repo service */
    this.filterParamObj[filterName] = event;

    this.changesSub.next(this.filterParamObj);
    this.onChange(this.value);
  }

  setPassport(event: Passport, filterName: string) {
    const passport = hasValues(this.passport) ? this.passport : null;
    if (passport) {
      this.passportChange.emit(passport);
      this.addToFilterParams(event, filterName);
      this.onChange(this.value);
    }
  }

  writeValue(_value: ForeignVisitor) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.baselineForm?.valid ? null : { baseline: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
}

const passportRefreshKeys = {
  number: 'number',
  issuingCountryCode: 'issuingCountryCode',
};
