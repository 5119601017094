import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { environment } from '@environments/environment';
import { Roles } from '@shared/models/role';
import { ADMIN_ROUTES } from './admin/admin-routing.module';
import { USER_APPROVAL_ROUTES } from './admin/approvals/approvals-routing.module';
import { COGNITO_AUTH_ROUTES } from './auth/cognito-auth/cognito-auth-routing.module';
import { OAUTH_ROUTES } from './auth/oauth/oauth-routing.module';
import { FAR_ROUTES } from './foreign-access-requests/foreign-access-request-routing.module';
import { FV_ROUTES } from './foreign-visitors/foreign-visitors-routing.module';
import { GROUP_ROUTES } from './groups/group-routing.module';
import { LOCATIONS_ROUTES } from './locations/location-routing.module';
import { METRICS_ROUTES } from './metrics-dashboard/metrics-dashboard-routing.module';
import { MONITORING_ROUTES } from './monitoring/monitoring-routing.module';
import { ORGANIZATION_ROUTES } from './organizations/organization-routing.module';
import { SCREENING_ROUTES } from './screening/screening-routing.module';
import { SYSTEM_ALERTS_ROUTES } from './system-alerts/system-alerts-routing.module';
import { USER_FEEDBACK_ROUTES } from './user-feedback/user-feedback-routing.module';
import { userPreferenceFormResolver } from './user-preference/resolvers/user-preference-form.resolver';
import { USER_PREFERENCE_ROUTES } from './user-preference/user-preference-routing.module';
import { USER_PROFILE_ROUTES } from './user-profile/user-profile-routing.module';
import { SYSTEM_USUAGE_ROUTES } from '@app/system-usage/system-usage-routing.module';

function getAuthRoutes(): Routes {
  if (environment.oauth) {
    return OAUTH_ROUTES;
  }
  return COGNITO_AUTH_ROUTES;
}

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./home/homepage.component').then((c) => c.HomePageComponent),
    canActivate: [AuthGuard],
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    runGuardsAndResolvers: 'always',
    title: 'Home',
    data: {
      roles: Roles,
    },
  },
  ...getAuthRoutes(),
  {
    path: 'simple',
    loadComponent: () =>
      import('./home/landing-page/landing-page.component').then(
        (c) => c.LandingPageComponent
      ),
    canActivate: [AuthGuard],
    data: {
      roles: Roles,
    },
  },
  {
    path: 'release-notes',
    loadComponent: () =>
      import('./release-notes/release-notes.component').then(
        (c) => c.ReleaseNotesComponent
      ),
    canActivate: [AuthGuard],
    data: {
      roles: Roles,
    },
  },
  {
    path: 'accept',
    title: 'Notice Acceptance',
    canActivate: [],
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
    loadComponent: () =>
      import('./home/splash-page/splash-page.component').then(
        (c) => c.SplashPageComponent
      ),
  },
  ...ADMIN_ROUTES,
  ...FV_ROUTES,
  ...FAR_ROUTES,
  ...GROUP_ROUTES,
  ...LOCATIONS_ROUTES,
  ...METRICS_ROUTES,
  ...MONITORING_ROUTES,
  ...SYSTEM_USUAGE_ROUTES,
  ...ORGANIZATION_ROUTES,
  ...SCREENING_ROUTES,
  ...SYSTEM_ALERTS_ROUTES,
  ...USER_FEEDBACK_ROUTES,
  ...USER_APPROVAL_ROUTES,
  ...USER_PREFERENCE_ROUTES,
  ...USER_PROFILE_ROUTES,
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];
