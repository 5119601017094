import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FnCardComponent } from '@app/foreign-nationals/fn-card/fn-card.component';
import { AttachmentsComponent } from '@shared/components/attachments/attachments.component';
import { CommentListComponent } from '@shared/components/comments/comment-list.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { DEFAULT_TITLE } from '@shared/helpers/app-title.strategy';
import { CrudChangeEvent } from '@shared/models/crud-change-event';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvMetrics } from '@shared/models/fv-metrics.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FarService } from '@shared/services/far.service';
import { FvService } from '@shared/services/fv.service';
import { MetricsObservable } from '@shared/services/metrics-observable.service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

import { MatButtonModule } from '@angular/material/button';
import { AppConfigValue } from '@shared/constants/shared.const';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { FvViewAdditionalInfoComponent } from './fv-view-additional-info/fv-view-additional-info.component';
import { FvActivityComponent } from './fvactivity/fvactivity.component';
import { FvInfoComponent } from './fvinfo/fvinfo.component';
import { FvScreeningEventsComponent } from './fvscreening-events/fvscreening-events.component';
import { FvviewContactComponent } from './fvview-contact/fvview-contact.component';
import { FvviewDocumentIdComponent } from './fvview-document-id/fvview-document-id.component';
import { FvViewEducationComponent } from './fvview-education/fvview-education.component';
import { FvviewEmploymentComponent } from './fvview-employment/fvview-employment.component';
import { FvviewIdentifyingVehicleComponent } from './fvview-identifying-vehicle/fvview-identifying-vehicle.component';
import { FvviewNationalIdComponent } from './fvview-national-id/fvview-national-id.component';
import { FvviewPassportsComponent } from './fvview-passports/fvview-passports.component';
import { FvviewSocialMediaComponent } from './fvview-social-media/fvview-social-media.component';
import { FvviewVisaComponent } from './fvview-visa/fvview-visa.component';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * FvViewComponent is a container component responsible for resolving a
 * Foreign Visitor as an observable, then passing the resolved FV to any
 * children of this view.
 */

@Component({
  templateUrl: 'fvview.component.html',
  styleUrls: ['fvview.component.scss'],
  standalone: true,
  selector: 'app-fvview',
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatBadgeModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    FvScreeningEventsComponent,
    FvViewAdditionalInfoComponent,
    FvviewIdentifyingVehicleComponent,
    FvInfoComponent,
    FvviewSocialMediaComponent,
    FvviewEmploymentComponent,
    FvActivityComponent,
    FvViewEducationComponent,
    FvviewContactComponent,
    FvviewDocumentIdComponent,
    FvviewNationalIdComponent,
    FvviewVisaComponent,
    FvviewPassportsComponent,
    FnCardComponent,
    AttachmentsComponent,
    CommentListComponent,
    PaginatorComponent,
    MatTooltipModule,
  ],
})
export class FvViewComponent implements OnInit, OnDestroy {
  @ViewChild('additionalInformation')
  additionalInformation: FvViewAdditionalInfoComponent;
  @ViewChild('fvActivity')
  fvActivity: FvActivityComponent;
  @AppConfigValue('additionalDocumentsScreeningEligibility')
  additionalDocumentsScreeningEligibility: boolean = false;
  @AppConfigValue('commentsAttachmentsDisabled')
  commentsAttachmentsDisabled: boolean = true;
  metrics: FvMetrics = {};
  linkedFVs: PageableCollection<ForeignVisitor>;
  attachmentCount: number = 0;
  fullnamePipe = new FullnamePipe();
  showLinked = false;
  hasDocument: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  filterParams = new FilterParams({
    sort: { active: 'createdDate', direction: 'asc' },
    pageSize: 4,
  });

  fvId: string = '';
  linkedById: string | null = '';
  modelChanges$ = this.fvService.modelChanges$.pipe(
    startWith({} as CrudChangeEvent<ForeignVisitor>)
  );
  fv$: Observable<ForeignVisitor | null>;

  farModelChanges$ = this.farService.changeEvent$.pipe(startWith({}));
  farFilterParams = new FilterParams({
    sort: { active: 'createdDate', direction: 'asc' },
  });

  fars$ = this.farModelChanges$.pipe(
    switchMap((_) => {
      Object.assign(this.farFilterParams, { fvId: this.fvId });
      if (this.fvActivity && this.fvActivity.upcomingFarMode)
        Object.assign(this.farFilterParams, { isActiveFar: true });
      return this.farService.find(this.farFilterParams);
    })
  );

  constructor(
    private fvService: FvService,
    private route: ActivatedRoute,
    private router: Router,
    private farService: FarService,
    private _MetricsObservable: MetricsObservable,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.fv$ = combineLatest([this.route.params, this.modelChanges$]).pipe(
      switchMap(([params, updatedModel]) => {
        this.fvId = params.id;
        this.linkedById = params.linkedById ? params.linkedById : null;
        const isFvUpdate: boolean =
          updatedModel.type == 'update' && updatedModel.modelId === this.fvId;
        const isFvDelete: boolean =
          updatedModel.type == 'delete' && updatedModel.modelId === this.fvId;
        let fv: Observable<ForeignVisitor | null> = this.fvService.get(
          this.fvId
        );
        if (isFvUpdate) {
          fv = of(updatedModel.model as ForeignVisitor);
          if (updatedModel.component) fv = this.fvService.get(this.fvId);
        }
        if (!!this.linkedById) {
          fv = this.fvService.getLinked(this.linkedById, this.fvId);
          this.showLinked = true;
        }
        if (isFvDelete) {
          fv = of(null);
        }
        if (!isFvDelete) this.updateMetrics();
        return fv;
      })
    );
    this.fv$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fv) => {
      const fullName = this.fullnamePipe.transform(fv!);
      const titleSuffix = !this.linkedById
        ? `FN - ${DEFAULT_TITLE}`
        : `Linked FN - ${DEFAULT_TITLE}`;

      this.hasDocument = this.hasDocumentData(fv!);
      if (!!fullName?.length) {
        this.title.setTitle(`${fullName} - ${titleSuffix}`);
      } else {
        this.title.setTitle(titleSuffix);
      }
    });

    this._MetricsObservable.MetricsObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      this.updateMetrics();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateAttachmentCount(count: number) {
    this.attachmentCount = count;
  }

  updateMetrics() {
    this.fvService
      .findLinked(this.linkedById ?? this.fvId, this.filterParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.linkedFVs = data;
      });
  }

  hasDocumentData(fv: ForeignVisitor) {
    if (fv?.passports && fv.passports[0]) return true;
    if (fv?.visas && fv.visas[0]) return true;
    if (fv?.diplomaticIds && fv?.diplomaticIds[0]) return true;
    if (fv?.dacaIds && fv.dacaIds[0]) return true;
    if (fv?.nationalIds && fv.nationalIds[0]) return true;
    return false;
  }

  async showLinkedToggled(close = false) {
    this.showLinked = close ? false : !this.showLinked;

    if (!this.showLinked && this.linkedById) {
      await this.router.navigate(['/fv', this.linkedById]);
    }
  }
  protected readonly parseInt = parseInt;
}
