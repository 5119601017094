import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
// sets up the interface for the Alias
interface Alias {
  name: string;
}
@Component({
  selector: 'app-biographic',
  templateUrl: './biographic.component.html',
  styleUrls: ['biographic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BiographicComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BiographicComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,

    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    CountrySelectComponent,
  ],
})
export class BiographicComponent
  extends BaseControlComponent<ForeignVisitor>
  implements Validator
{
  @Input() activeFV?: ForeignVisitor;
  @ViewChild('bioForm')
  bioForm: NgForm;

  appearance: MatFormFieldAppearance = 'outline';
  gender: any = ['male', 'female'];
  today = new Date();
  value: ForeignVisitor = {};

  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  addFVBio() {
    this.emitChangeEvent();
  }

  writeValue(_value: ForeignVisitor) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.bioForm?.valid ? null : { fv: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
