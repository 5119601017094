import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FAR } from '@shared/models/far.model';
import { Sponsor } from '@shared/models/sponsor.model';
import { SponsorDialogComponent } from './sponsor-dialog.component';

@Directive({
  selector: '[appSponsorEdit]',
  standalone: true,
})
export class SponsorEditDirective {
  @Input() appSponsorEdit: FAR;
  @Output() appSponsorDialogOpen = new EventEmitter<
    MatDialogRef<SponsorDialogComponent, Sponsor>
  >();

  @Output() sponsorEdited = new EventEmitter<Sponsor>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const far = this.appSponsorEdit;
    const dialog = SponsorDialogComponent.openDialog(this.dialog, {
      farId: far.id,
      sponsor: far.sponsor as Sponsor,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.sponsorEdited.emit(result);
      }
    });

    this.appSponsorDialogOpen.emit(dialog);
  }
}
