import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Organization } from '@app/shared/models/organization.model';
import { environment } from '@environments/environment';
import { AccountApproverContact } from '@shared/models/account-approver-contact.model';
import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '@shared/services/crud.service';
import { map, Observable, of } from 'rxjs';
import { AppConfigService } from './app-config.services';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/organizations`,
  hasIdPathUpdate: true,
  entity: 'Organization',
})
export class OrganizationService extends CrudService<Organization> {
  private readonly configService: AppConfigService = inject(AppConfigService);

  constructor(http: HttpClient) {
    super(http);
  }

  getApprovers(): Observable<AccountApproverContact[]> {
    if (this.configService.get('highEnvFeatures')) return of([]);

    return this.http
      .get<AccountApproverContact[]>(`${this.apiUrl}/approvers`)
      .pipe(
        map((contacts: AccountApproverContact[]) => {
          if (!contacts) {
            return [];
          }
          return contacts.filter(
            (contact: AccountApproverContact) =>
              contact.approverEmail && contact.organizationName
          );
        })
      );
  }
}
