<ng-container *ngIf="value">
  <form ngForm #approvalForm="ngForm">
    <mat-form-field class="w-100" *ngIf="isGroupExtension">
      <mat-label>Select Group</mat-label>
      <mat-select
        multiple
        required
        name="groupType"
        [(ngModel)]="value.groupType"
        (ngModelChange)="emitChangeEvent()"
      >
        <mat-option [value]="Role.sv_admin">Admin</mat-option>
        <mat-option [value]="Role.sv_help_desk">Helpdesk</mat-option>
      </mat-select>
    </mat-form-field>
    <contact-select
      [disabled]="disabled"
      [required]="true"
      [(contactId)]="value.contactId!"
      (contactIdChange)="emitChangeEvent()"
      name="approver"
      [orgId]="orgId"
    ></contact-select>
    <mat-form-field class="w-100">
      <mat-label>Approval Comment</mat-label>
      <textarea
        name="content"
        [disabled]="disabled"
        required
        matInput
        [(ngModel)]="value.content"
        rows="10"
        (ngModelChange)="emitChangeEvent()"
      ></textarea>
    </mat-form-field>
  </form>
</ng-container>
