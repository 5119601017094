<div
  *ngIf="isVisible"
  class="location-unknown-container"
  data-cy="location-unknown-container"
>
  <ng-container>
    <div data-cy="location-unknown-checkbox-container">
      <mat-checkbox
        data-cy="location-unknown-checkbox"
        #checkboxLocationUnknown
        name="locationUnknown"
        [checked]="locationUnknown"
        (change)="locationUnknownChanged($event)"
      >
        {{ label }}
      </mat-checkbox>
    </div>
  </ng-container>
  <mat-icon
    data-cy="location-unknown-tooltip"
    color="primary"
    [matTooltip]="tooltip"
    matTooltipClass="location-unknown-tooltip"
    >info</mat-icon
  >
</div>
