import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { Role } from '@shared/models/role';
import { FAR_TAB } from '../config/far.config.const';
import { FV_TAB } from '../config/fv.config.const';
import { GROUP_TAB } from '../config/group.config.const';
import { HOME_TAB } from '../config/home.config.const';
import { LOCATIONS_TAB } from '../config/location.config.const';
import { METRICS_TAB } from '../config/metrics.config.const';
import { ORGANIZATION_TAB } from '../config/organization.config.const';
import { SCREENING_TAB } from '../config/screening.config.const';
import { SYSTEM_ALERT_TAB } from '../config/system-alerts.config.const';
import { SYSTEM_MONITORING_TAB } from '../config/system-monitoring.config.const';
import { USER_ADMIN_ACCOUNT_TAB } from '../config/user-admin-account.config.const';
import { USER_ADMIN_APPROVAL_TAB } from '../config/user-admin-approval.config.const';
import { USER_FEEDBACK_TAB } from '../config/user-feedback.config.const';
import { UserPrefViewDefinitions } from '../model/user-pref-view-definitions.model';

export const userPrefViewDefResolver: ResolveFn<
  UserPrefViewDefinitions | boolean
> = async (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const roles: Role[] | undefined = authService.getUser()?.roles;
  const viewDefs: UserPrefViewDefinitions = {
    home: HOME_TAB,
    fv: FV_TAB,
    far: FAR_TAB,
    screening: SCREENING_TAB,
    group: GROUP_TAB,
    location: LOCATIONS_TAB,
    metrics: METRICS_TAB,
    systemMonitoring: SYSTEM_MONITORING_TAB,
    organization: ORGANIZATION_TAB,
    systemAlert: SYSTEM_ALERT_TAB,
    userAdministration: USER_ADMIN_ACCOUNT_TAB,
    userApproval: USER_ADMIN_APPROVAL_TAB,
    userFeedback: USER_FEEDBACK_TAB,
  };

  if (!roles) {
    await router.navigate(['error', '403']);
    return false;
  }

  return viewDefs;
};
