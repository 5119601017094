import { CmtClassification } from './cmt-classification';

export enum CmtStatus {
  INITIALIZED = 0,
  RESPONSE = 1,
  ERROR = 2,
  CLOSE = 3,
}

export interface CmtResponse {
  status: CmtStatus;
  errorMessage?: string;
  classificationXml?: string;
  classificationJson?: CmtClassification;
  favoritesXml?: string;
  darkMode?: boolean;
}
