import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { SystemAlertsEditRoles } from '@shared/models/role-permissions';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';

export const SYSTEM_ALERTS_ROUTES: Routes = [
  {
    path: 'systemalerts',
    title: 'System Alerts',
    component: SystemAlertsComponent,
    canActivate: [AuthGuard],
    data: { roles: SystemAlertsEditRoles },
  },
];
