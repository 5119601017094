import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreeningAdvancedSearch } from '@app/screening/collection/screening-advanced-search';
import { ActiveSearchDisplayComponent } from '@shared/components/advanced-search/active-search-display.component';
import { ColumnChooserComponent } from '@shared/components/column-chooser/column-chooser.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { ScreeningStatus } from '@shared/enums/screening-status.enum';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { Role } from '@shared/models/role';
import { ScreeningStatusLabels } from '@shared/models/screening.model';
import { SortSelect } from '@shared/models/sort-select.model';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { ScreeningService } from '@shared/services/screening.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreeningDrawerObservable } from '../../screening-drawer-observable.service';

@Component({
  selector: 'app-screening-toolbar',
  templateUrl: './screening-toolbar.component.html',
  styleUrls: ['./screening-toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ActiveSearchDisplayComponent,
    ColumnChooserComponent,
    FilterComponent,
    MatTooltipModule,
  ],
})
export class ScreeningToolbarComponent implements OnDestroy {
  @Input() loading: boolean = false;
  @Input() filterPlaceholder: string = 'Given Name, Surname';
  @Input() sortFields: SortSelect[];
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();
  @Input() filterParams: FilterParams = new FilterParams({});
  screenable: boolean = true;
  private ngUnsubscribe = new Subject<void>();
  automatedScreeningEnabled = this.config.get('automatedScreeningEnabled');

  statusFilter: string = '';
  actionButton: ActionButton = {
    label: 'Import',
    bulkUpload: 'Screening',
    menuIcon: 'input',
    roles: [Role.sv_vetter],
    color: 'primary',
  };

  fvAdvFiltersList: {
    key: string;
    label: string;
    valLabel?: (val: string) => string;
  }[] = [
    { key: 'givenName', label: 'Given Name' },
    { key: 'surname', label: 'Surname' },
    { key: 'emailAddress', label: 'Email Address' },
    { key: 'screeningCompletedDate', label: 'Screening Completed Date' },
    { key: 'passportNumber', label: 'Passport Number' },
    { key: 'passportIssuingCountryCode', label: 'Passport Issuing Country' },
    { key: 'citizenshipCountry', label: 'Citizenship Country' },
    { key: 'residenceCountry', label: 'Residence Country' },
    { key: 'birthCountry', label: 'Birth Country' },
    { key: 'dateOfBirth', label: 'Date of Birth' },
    {
      key: 'status',
      label: 'Screening Status',
      valLabel: (val) => ScreeningStatusLabels[val as ScreeningStatus],
    },
    { key: 'result', label: 'Screening Result' },
    { key: 'offenseCode', label: 'Screening Offense Code' },
  ];

  constructor(
    private alert: AlertService,
    private screeningService: ScreeningService,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable,
    private config: AppConfigService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  openHistoryDrawer() {
    this._ScreeningDrawerObservable.send(true);
  }

  isFilterActive() {
    for (let filter of this.fvAdvFiltersList) {
      if (this.filterParams[filter.key]) return true;
    }
    if (this.filterParams['vip']) return true;
    return false;
  }

  clearAllFilters() {
    this.fvAdvFiltersList.forEach((filter) => {
      this.filterParams[filter.key] = null;
    });
    this.filterParams['vip'] = null;
    this.onFilterParamChange(this.filterParams);
  }

  export(isVip: boolean) {
    this.alert.showLoadingOverlay();
    this.screeningService
      .exportScreenings(isVip)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.handleExport(res, isVip);
      });
  }
  exportVip() {
    this.export(true);
  }

  exportAll() {
    this.export(false);
  }

  handleExport(response: HttpResponse<Blob>, isVip: boolean) {
    let message: string;
    this.alert.hideLoadingOverlay();

    if (response['status'] === 204)
      this.alert.infoAlert('No Foreign Nationals Ready for Export');
    else {
      this.processFileForBrowser(response['body'] as Blob, isVip);
    }
  }

  processFileForBrowser(responseData: Blob, isVip: boolean) {
    let fileType: string;
    fileType = responseData.type == 'application/zip' ? '.zip' : '.xlsx';
    const data = window.URL.createObjectURL(responseData);

    let link = document.createElement('a');
    let fileTitle = `${isVip ? 'EXPEDITED-' : ''}SCREENING-'`;

    link.href = data;
    link.download =
      fileTitle +
      (new Date().getUTCMonth() + 1) +
      new Date().getUTCDate() +
      '_' +
      new Date().getUTCHours() +
      new Date().getUTCMinutes() +
      fileType;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    this._ScreeningDrawerObservable.send('refresh');
    this.filterParamsChange.emit(this.filterParams);
  }

  protected readonly ScreeningAdvancedSearch = ScreeningAdvancedSearch;
}
