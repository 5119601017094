<ng-container *ngIf="value">
  <form #bioForm="ngForm">
    <!-- not required -->
    <mat-form-field [appearance]="appearance">
      <mat-label>Given Name</mat-label>
      <input
        required
        matInput
        name="givenName"
        maxlength="256"
        id="givenName"
        placeholder="Enter Given Name"
        aria-label="given name"
        [(ngModel)]="value.givenName"
      />
      <mat-error>Please enter a valid given name.</mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Surname</mat-label>
      <input
        required
        matInput
        name="surname"
        maxlength="256"
        id="surname"
        placeholder="Enter Surname"
        aria-label="Surname"
        [(ngModel)]="value.surname"
      />
      <!-- ERROR -->
      <mat-error>Please enter a valid surname.</mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Sex</mat-label>
      <mat-select
        name="gender"
        id="gender"
        aria-label="Please select an option"
        color="primary"
        [(ngModel)]="value.gender"
      >
        <mat-option selected disabled>Select a Sex</mat-option>
        <mat-option *ngFor="let option of gender" [value]="option">
          {{ option | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Date Of Birth</mat-label>
      <input
        matInput
        [matDatepicker]="dobPicker"
        name="dateOfBirth"
        id="dateOfBirth"
        [max]="today"
        [min]="minDate"
        placeholder="MM/DD/YYYY"
        aria-label="date of birth"
        [(ngModel)]="value.dateOfBirth"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="dobPicker"
        matTooltip="Select a date"
      ></mat-datepicker-toggle>
      <mat-datepicker #dobPicker></mat-datepicker>
      <mat-error>Date of Birth is invalid</mat-error>
    </mat-form-field>
    <app-country-select
      #birthCountryCtl="ngModel"
      [placeholder]="'Country of Birth'"
      [label]="'Country of Birth'"
      name="birthCountry"
      id="birthCountry"
      [(ngModel)]="value.birthCountry"
      [includeOrgs]="false"
    >
    </app-country-select>
    <app-country-select
      #citizenshipCountry="ngModel"
      name="citizenshipCountry"
      id="citizenshipCountry"
      [label]="'Country of Citizenship'"
      [placeholder]="'Country of Citizenship'"
      errorMsg="Country of Citizenship is required"
      [excludeUS]="true"
      [(ngModel)]="value.citizenshipCountry"
      [includeOrgs]="false"
    >
    </app-country-select>
    <app-country-select
      #residenceCountry="ngModel"
      name="residenceCountry"
      id="residenceCountry"
      [label]="'Country of Residence'"
      [placeholder]="'Country of Residence'"
      errorMsg="Country of Residence is required"
      [(ngModel)]="value.residenceCountry"
      [includeOrgs]="false"
    >
    </app-country-select>
  </form>
</ng-container>
