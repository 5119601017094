import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FilterConfigType } from '@shared/enums/filter-config-type.enum';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FilterParams } from '@shared/models/filter-params.model';
import { isArray, isEmpty, isNil, isString, result } from 'lodash';

/** Takes a property path or map function */
export type PropResolver = string | ((opt: any) => string);
export function resolveProp(
  opt: any,
  resolve?: PropResolver,
  defaultVal = opt
) {
  if (!resolve || !opt) return defaultVal;
  if (isString(resolve)) return result(opt, resolve);
  return resolve(opt);
}

export function resolveOpt(
  options: any[],
  value: any,
  valResolve?: PropResolver
) {
  return options.find((opt) => resolveProp(opt, valResolve) === value);
}

export function clearField(config: FilterConfig, params: FilterParams) {
  if (config.type === FilterConfigType.DATE_RANGE) {
    delete params[config.name + 'Start'];
    delete params[config.name + 'End'];
  }

  delete params[config.name];
}

export function isFilterActive(config: FilterConfig, params: FilterParams) {
  if (config.type === FilterConfigType.DATE_RANGE) {
    return (
      !isNil(params[config.name + 'Start']) ||
      !isNil(params[config.name + 'End'])
    );
  }

  if (config.type === FilterConfigType.TOGGLE) {
    return coerceBooleanProperty(params[config.name]);
  }

  if (isArray(params[config.name]) || isString(params[config.name])) {
    return !isEmpty(params[config.name]);
  }

  return !isNil(params[config.name]);
}

export function isArrayVal(config: FilterConfig) {
  return !!(<any>config).multiple;
}

export function getArrayKeys(configs: FilterConfig[]) {
  return (configs || [])
    .filter((config) => isArrayVal(config))
    .map((config) => config.name);
}
