import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AccountApproverContact } from '@shared/models/account-approver-contact.model';

@Component({
  selector: 'app-account-approvers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './account-approvers.component.html',
  styleUrl: './account-approvers.component.scss',
})
export class AccountApproversComponent {
  @Input() emailSubject: string = '';
  @Input() emailBody: any;
  @Input() contacts: AccountApproverContact[] = [];
}
