import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { LocationUnknownComponent } from '@app/locations/location-unknown/location-unknown.component';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { OrganizationSelectComponent } from '@shared/controls/organization-select/organization-select.component';
import { BulkUploadDirective } from '@shared/directives/bulk-upload.directive';
import { MimeTypeIconDirective } from '@shared/directives/mime-type-icon.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import {
  BulkSetting,
  BulkTemplate,
  BulkUploadData,
  BulkUploadSettings,
} from '@shared/models/bulk-upload-type';
import { ClassificationModel } from '@shared/models/classification.model';
import { FarPurposeType } from '@shared/models/far.model';
import { Organization } from '@shared/models/organization.model';
import { Role } from '@shared/models/role';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { AsyncBulkUploadObservable } from '@shared/services/async-bulk-upload.observable';
import { TemplateService } from '@shared/services/template.service';
import { UserService } from '@shared/services/user.service';
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  FileUploadModule,
  ParsedResponseHeaders,
} from 'ng2-file-upload';
import { SearchLocationsDialogComponent } from '../search-locations-dialog/search-locations-dialog.component';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
  standalone: true,
  imports: [
    RolesDirective,
    CommonModule,
    FileUploadModule,
    ClassificationBannerComponent,
    OrganizationSelectComponent,
    SearchLocationsDialogComponent,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MimeTypeIconDirective,
    ClassifyDirective,
    ClassifyShowDirective,
    LocationUnknownComponent,
  ],
})
export class BulkUploadComponent {
  @ViewChild('bulkUploadDirective') bulkUploadDirective: BulkUploadDirective;
  private readonly deltaRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  Role = Role;

  inputDisplayCtrl = new UntypedFormControl();

  @ViewChild('fileInput') fileInput: ElementRef;

  settings: BulkSetting;
  farId: any[] = [];
  uploader: FileUploader;
  showErrors: boolean = false;
  countrySearch: boolean = false;
  errMsg: any = {};
  errorsToDisplay: any = [];

  classificationModel: ClassificationModel;
  owningOrg: Organization;
  _defaultOptions: FileUploaderOptions;
  farScreeningEligibility: boolean = false;
  busy: boolean = false;

  locationUnknown = false;
  defaultFarPurposeType: FarPurposeType = FarPurposeType.IN_PERSON_MEETING;
  locationUnknownLabel = 'Location is Unknown';
  locationUnknownTooltip = `Select the "${this.locationUnknownLabel}" checkbox if you are
  unable to supply a valid location.  The 'Event Title' column will be required for FARs with unknown location data. This will apply to all rows.`;

  constructor(
    public dialogRef: MatDialogRef<BulkUploadComponent>,
    public locationsDialogRef: MatDialogRef<SearchLocationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkUploadData,
    public resultsDialog: MatDialog,
    private auth: AuthService,
    private userService: UserService,
    private alert: AlertService,
    private template: TemplateService,
    private config: AppConfigService,
    private poll: AsyncBulkUploadObservable,
    private router: Router
  ) {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.settings = BulkUploadSettings[this.data.type];
    this.farId = this.data.farId;
    if (this.settings) {
      this._defaultOptions = {
        itemAlias: 'file',
        method: 'POST',
        url: this.settings.path,
      };
      this.uploader = new FileUploader(this._defaultOptions);

      this.owningOrg = this.auth.getOrganization() as Organization;

      this.uploader.onAfterAddingFile = this.onAddFile.bind(this);
      this.uploader.onErrorItem = this.onError.bind(this);
      this.uploader.onSuccessItem = this.onSuccess.bind(this);
    }
  }

  onAddFile(_item: FileItem) {
    if (this.uploader.queue.length > 1) {
      this.uploader.queue.splice(0, 1);
    }
    this.inputDisplayCtrl.reset();
    this.showErrors = false;
    this.errorsToDisplay = [];
  }

  clearFiles() {
    this.uploader.clearQueue();
    this.fileInput.nativeElement.value = '';
  }

  hasOrg() {
    return this.owningOrg != null;
  }

  generateFnTooltip() {
    if (!this.farScreeningEligibility)
      return "Foreign Nationals - Fields Required for Screening: Given Name, Surname, Date of Birth, Passport Number and Passport Country. For Single Name FNs, enter the single name into the Surname field and 'FNU' into the Given Name field";
    else {
      return "Foreign Nationals - Fields Required for Screening: Given Name, Surname, Date of Birth, Passport Number, Passport Country, and an Active FAR. For Single Name FNs, enter the single name into the Surname field and 'FNU' into the Given Name field";
    }
  }

  generateFarTooltip() {
    if (!this.farScreeningEligibility)
      return "Foreign Access Request - 'Purpose Type' will be defaulted to 'In Person Meeting'.  All location fields and 'Date of Visit From' are required. 'Event Title' will be generated from 'Location Name' and submitting user's organization if not specified. FAR data is not required, and FN data will be processed as normal. If FAR data is present, any missing FAR data will cause whole file to be rejected.";
    else
      return "Foreign Access Request - 'Purpose Type' will be defaulted to 'In Person Meeting'.  All location fields and 'Date of Visit From' are required. 'Event Title' will be generated from 'Location Name' and submitting user's organization if not specified. FAR data is now required for screening. Any FN data without FAR data will be processed as normal, however, the FN will be marked as ineligible. Any missing FAR data will cause whole file to be rejected.";
  }

  onError(
    _item: FileItem,
    response: string,
    status: number,
    _headers: ParsedResponseHeaders
  ) {
    this.clearFiles();
    this.busy = false;
    if (status === 0) {
      // infer that browser detected a change in the file.
      this.inputDisplayCtrl.setErrors({ error: 'File Changed' });
      this.errMsg.message = 'File Changed. Select a new file.';
      return;
    }

    this.errMsg = JSON.parse(response);
    this.inputDisplayCtrl.setErrors({ error: response }, { emitEvent: true });
    if (this.errMsg.validationErrors?.length > 0) {
      this.errorsToDisplay = this.errMsg.validationErrors;
    } else if (this.errMsg.duplicateMessages?.length > 0) {
      this.errorsToDisplay = this.errMsg.duplicateMessages;
    }
  }

  /**
   * Presents a snackbar to the user when the API returns on successful upload
   * then navigates to ineligible FVs.
   *
   * @param _item File being uploaded
   */
  onSuccess(_item: FileItem) {
    this.alert.infoAlert(
      'Bulk Upload Submitted - Job In Progress. View the Notification Menu at the top right application toolbar for further details.',
      {
        duration: 15000,
      }
    );
    this.poll.startPolling(true);
    this.dialogRef.close(true);
  }

  async submit() {
    if (!this.uploader.queue?.length) return;
    const newOptions = { ...this._defaultOptions };
    const additionalParameter: FileUploaderOptions['additionalParameter'] = {};

    if (
      this.classificationModel?.classification?.length &&
      this.classificationModel?.classificationXML?.length
    ) {
      additionalParameter.classification =
        this.classificationModel?.classification;
      additionalParameter.classificationXml =
        this.classificationModel?.classificationXML;
    }

    let skipOwningOrg = false;
    if (this.farId && this.farId.length > 0) {
      if (this.farId[0].id) {
        let idArray = [];
        for (let far of this.farId) {
          idArray.push(far.id);
        }
        additionalParameter.farIds = idArray;
      } else additionalParameter.farIds = this.farId;
      skipOwningOrg = true;
    }

    if (this.auth.isAdmin && this.owningOrg && !skipOwningOrg) {
      additionalParameter.owningOrgId = this.owningOrg.id;
    }

    if (this.locationUnknown) {
      additionalParameter.locationUnknown = this.locationUnknown;
    }

    this.showErrors = false;
    this.errorsToDisplay = [];
    const token = await this.auth.getToken();
    this.errMsg = {};

    if (this.userService.filteredRoles?.length) {
      newOptions.headers = [
        { name: 'Role', value: this.userService.filteredRoles.join(',') },
      ];
    }

    newOptions.additionalParameter = additionalParameter;
    newOptions.authToken = token;
    this.uploader.setOptions(newOptions);

    this.uploader.queue[0].upload();
  }

  static openDialog(
    dialog: MatDialog,
    data: BulkUploadData
  ): MatDialogRef<BulkUploadComponent> {
    return dialog.open<BulkUploadComponent, BulkUploadData>(
      BulkUploadComponent,
      {
        data,
        minWidth: '800px',
      }
    );
  }

  hideCountrySearch() {
    this.countrySearch = !this.countrySearch;
  }

  download(tpl: BulkTemplate) {
    this.template.download(tpl.type, tpl.file).subscribe();
  }

  classificationModelChangeEmitted(cmtModel: ClassificationModel) {
    this.classificationModel = cmtModel;
    this.deltaRef.detectChanges();
  }

  locationUnknownChanged(locationUnknown: boolean) {
    this.locationUnknown = locationUnknown;
  }
}
