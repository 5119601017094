import { CommonModule, formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MinDateDirective } from '@shared/directives/min-date-time.directive';
import { Moment, isMoment } from 'moment';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DateTimeComponent, multi: true },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MinDateDirective,
  ],
})
export class DateTimeComponent extends BaseControlComponent<Date> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label = 'start';
  @Input() minDate: Date | null = null;
  @Input() timeStep: number = 5 * 60;

  year: number;
  month: number;
  date: number;
  fulldate: Date;

  hours: number = 0;
  minutes: number = 0;
  time: string = '00:00';

  now = Date.now();

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  writeValue(_value: Date): void {
    if (!_value) {
      this.value = _value;
      this.year = 0;
      this.month = 0;
      this.date = 0;
      this.hours = 0;
      this.minutes = 0;
      this.fulldate = _value;
      this.time = '00:00';
      return;
    }
    this.value = new Date(_value);
    this.year = this.value.getFullYear();
    this.month = this.value.getMonth();
    this.date = this.value.getDate();
    this.hours = this.value.getHours();
    this.minutes = this.value.getMinutes();

    this.fulldate = new Date(this.year, this.month, this.date);
    this.time = formatDate(this.value, 'HH:mm', this.locale);
  }

  setTime(time: string) {
    this.hours = +time.split(':')[0];
    this.minutes = +time.split(':')[1];
    this.value = this.getDateTime();
    this.emitChangeEvent();
  }

  setDate(event: Moment) {
    if (!isMoment(event)) return;
    let date = event.toDate();
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.date = date.getDate();
    this.value = this.getDateTime();
    this.emitChangeEvent();
  }

  getDateTime() {
    return new Date(this.year, this.month, this.date, this.hours, this.minutes);
  }

  hasError(form: NgForm, control: string, error: string) {
    if (!form) return;
    return form.form.controls[control]?.hasError(error);
  }
}
