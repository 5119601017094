import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ContactType } from '@shared/enums/contact-type.enum';
import { BaseModel } from '@shared/models/base.model';
import { Role } from '@shared/models/role';
import { Approval } from './approval';
import { Organization } from './organization.model';
import { v4 as uuidV4 } from 'uuid';

export interface User extends BaseModel {
  id?: string; //number | string;
  email?: string; // (60)
  username?: string; // (64)
  phone?: string; // (15)
  mobilePhone?: string; // (15)
  givenName?: string; // (25)
  surname?: string; // (25)
  rankScore?: number;
  accountStatus?: string; // (40)
  lastLogin?: Date;
  avatarImageLink?: string; //(500) link to a file in S3 bucket (50)
  accountLocked?: boolean;
  multifactorAuthenticationType?: string; // (10)
  organization?: Organization;
  roles?: Role[];
  token?: string;
  tempPassword?: string;
  accountApproval?: Approval;

  //Account Expiration
  expirationDate?: string;
  isExpiring?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserAccountStatus {
  public accountStatus = [
    { value: 'pre-registered', text: 'Pre-Registered' },
    { value: 'waiting approval', text: 'Waiting Approval' },
    { value: 'active', text: 'Active' },
    { value: 'disabled', text: 'Disabled' },
  ];

  get status() {
    return this.accountStatus;
  }
}

export enum AccountStatus {
  PreRegistered = 'pre-registered',
  Active = 'active',
  Inactive = 'inactive',
}

export const AccountStatuses = Object.values(AccountStatus);

export const AccountStatusLabels: {
  [key in AccountStatus]: string;
} = {
  [AccountStatus.PreRegistered]: 'Pre-Registered',
  [AccountStatus.Active]: 'Active',
  [AccountStatus.Inactive]: 'Inactive',
};

export const AccountActionLabel: {
  [key in AccountStatus]?: {
    action: string;
    present: string;
    past: string;
    color: ThemePalette;
  };
} = {
  [AccountStatus.Active]: {
    action: 'activate',
    present: 'activating',
    past: 'activated ',
    color: 'primary',
  },
  [AccountStatus.Inactive]: {
    action: 'deactivate',
    present: 'deactivating',
    past: 'deactivated ',
    color: 'warn',
  },
};

export const MOCK_USER: User = {
  id: uuidV4(),
  expirationDate: new Date(
    new Date().getFullYear() + 1,
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
    0
  ).toDateString(),
  isExpiring: false,
  organization: {
    id: uuidV4(),
    name: 'Mock Org',
    contacts: [
      {
        email: 'TEST@citadel.caci.com',
        type: ContactType.ORG_ADMIN_APPROVER,
      },
    ],
  },
  roles: [
    Role.sv_admin,
    Role.sv_help_desk,
    Role.sv_org_admin,
    Role.sv_org_user,
  ],
};
