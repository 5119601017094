import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { Country } from '@shared/models/country.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { CountryService } from '@shared/services/country.service';
import { take } from 'rxjs/operators';
import { FilterComponent } from '../filter/filter.component';
import { MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'app-search-locations-dialog',
  templateUrl: './search-locations-dialog.component.html',
  styleUrls: ['./search-locations-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    FlagIconDirective,
    AuthEventsDialogDirective,
    MatSortModule,
  ],
})
export class SearchLocationsDialogComponent implements OnInit {
  @Input() includeOrgs: boolean = false;
  countries: Country[];
  filterParams: FilterParams = new FilterParams();
  countriesHeaders: string[] = ['flag', 'name', 'iso-3'];
  loading: boolean = false;
  constructor(private countryService: CountryService) {}

  ngOnInit(): void {
    this.onFilterParamChange(this.filterParams);
  }

  onFilterParamChange(params: FilterParams) {
    this.includeOrgs
      ? (params.includeNonCountryOrgs = true)
      : (params.includeNonCountryOrgs = false);
    this.loading = true;
    this.countryService
      .find(params)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.countries = data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }
}
