import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { AccountApproverContact } from '@shared/models/account-approver-contact.model';
import { User } from '@shared/models/user.model';
import { AccountExpirationComponent } from '../account-expiration/account-expiration.component';

@Component({
  selector: 'app-account-approval-contact-snackbar',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    AccountExpirationComponent,
  ],
  templateUrl: './account-expiration-snackbar.component.html',
  styleUrl: './account-expiration-snackbar.component.scss',
})
export class AccountExpirationSnackbarComponent {
  snackBarRef: MatSnackBarRef<AccountExpirationSnackbarComponent> =
    inject(MatSnackBarRef);
  data: { user: User; contacts: AccountApproverContact[] } =
    inject(MAT_SNACK_BAR_DATA);
}
