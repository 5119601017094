import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { DashboardComponent } from '@app/monitoring/dashboard/dashboard.component';
import { MonitoringViewRoles } from '@shared/models/role-permissions';

export const MONITORING_ROUTES: Routes = [
  {
    path: 'monitoring',
    title: 'Monitoring',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: MonitoringViewRoles },
  },
];
