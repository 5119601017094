import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Address } from '@shared/models/address.model';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data.model';
import { CrudChangeEvent } from '@shared/models/crud-change-event';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { IDialogData } from '@shared/models/dialog-data.model';
import { EmploymentRecord, FvWork } from '@shared/models/fv-work.model';
import { AlertService } from '@shared/services/alert.service';
import { EmploymentService } from '@shared/services/employment.service';
import { NgPipesModule } from 'ngx-pipes';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { EmploymentFormComponent } from './employment-form/employment-form.component';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'fvview-employment',
  templateUrl: './fvview-employment.component.html',
  styleUrls: ['./fvview-employment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    DefaultPipe,
    NgPipesModule,
    AddressPipe,
    MatTooltipModule,
  ],
})
export class FvviewEmploymentComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;

  @Input() employment?: FvWork[] = [];
  @Output() employmentChange = new EventEmitter<FvWork[]>();
  refresh$: Observable<CrudChangeEvent<EmploymentRecord>> =
    this.employmentService.changeEvent$.pipe(
      startWith({} as CrudChangeEvent<EmploymentRecord>)
    );

  /**
   * Template uses this observable to react to CRUD Changes.
   * If there are no crud changes then input employment property
   * is emitted.
   */

  employment$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<FvWork>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removeEmploymentRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addEmploymentRecord(refresh.model as FvWork);
      if (refresh.type === CrudChangeType.update)
        this.updateEmploymentRecord(refresh.model as FvWork);
      this.employmentChange.emit(this.employment);
      return this.employment;
    })
  );

  @Input() fvId: string = '';

  constructor(
    public dialog: MatDialog,
    private alert: AlertService,
    private employmentService: EmploymentService
  ) {}

  onAdd() {
    this.openDialog({
      model: { foreignVisitor: { id: this.fvId } },
      mode: 'create',
    });
  }
  onEdit(employment: FvWork) {
    this.openDialog({
      model: this.getEmploymentRecord(employment),
      mode: 'update',
    });
  }
  onDelete(employment: EmploymentRecord) {
    const employerName = employment.employerName;
    let confirmData: ConfirmDialogData = {
      title: `Delete ${employerName}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () => this.employmentService.delete(employment.id),
    };
    this.alert.confirmDelete(confirmData).pipe(take(1)).subscribe();
  }

  // helper to add the foreign visitor assiciation to the employment object
  getEmploymentRecord(employment: FvWork) {
    return {
      ...employment,
      foreignVisitor: { id: this.fvId },
      entity: 'Foreign National',
    };
  }

  openDialog(employmentRecord: IDialogData<EmploymentRecord>) {
    this.dialog.open(EmploymentFormComponent, {
      data: employmentRecord,
      disableClose: true,
    });
  }

  addEmploymentRecord(employmentRecord: FvWork) {
    if (this.employment) {
      this.employment = [...this.employment, employmentRecord];
    }
  }

  updateEmploymentRecord(employmentRecord: FvWork) {
    if (this.employment) {
      const index = this.employment.findIndex((employment) => {
        return employment.id === employmentRecord.id;
      });
      this.employment[index] = employmentRecord;
      this.employment = [...this.employment];
    }
  }
  removeEmploymentRecord(id: string) {
    if (this.employment) {
      this.employment = this.employment.filter((x) => x.id !== id);
    }
  }

  isAddress(location?: Address) {
    if (!location) return false;
    if (
      !location.line1 &&
      !location.line2 &&
      !location.city &&
      !location.stateProvince &&
      !location.countryCode &&
      !location.postalCode
    )
      return false;
    return true;
  }
}
