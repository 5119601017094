import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApprovalControlComponent } from '@shared/controls/approval/approval-control.component';
import { AddApprovalDialogData } from '@shared/models/add-approval-dialog-data.model';
import { Approval } from '@shared/models/approval';

@Component({
  selector: 'app-add-approval',
  template: `<p
      mat-dialog-title
      class="pt-3 d-flex flex-row align-items-center"
    >
      <span *ngIf="extendApproval && !viewOnly && !isGroupExtension"
        >Extend Approval</span
      >
      <span *ngIf="isGroupExtension">Extend Approval by Group</span>
      <ng-container *ngIf="!extendApproval && viewOnly">
        <span class="flex-grow-1">Approved ({{ approval.approvalDate }})</span>
        <span *ngIf="approval.endedDate" class="text-danger"
          >Inactive ({{ approval.endedDate }})</span
        >
      </ng-container>
      <span *ngIf="!extendApproval && !viewOnly && !isGroupExtension"
        >Approval Required</span
      >
    </p>
    <mat-dialog-content class="py-3">
      <form [class.form-inputs-read-only]="viewOnly">
        <app-approval-control
          [disabled]="viewOnly"
          #approvalCtrl
          [(ngModel)]="approval"
          name="approval"
          [orgId]="approval?.organizationId || ''"
          [isGroupExtension]="isGroupExtension"
        ></app-approval-control>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [matDialogClose]="null">
        {{ !viewOnly ? 'Cancel' : 'Close' }}
      </button>
      <button
        *ngIf="!viewOnly"
        mat-flat-button
        [disabled]="!approvalCtrl?.valid"
        color="primary"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </mat-dialog-actions> `,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    ApprovalControlComponent,
  ],
})
export class AddApprovalComponent {
  approval: Approval;
  viewOnly: boolean;
  extendApproval: boolean;
  isGroupExtension: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddApprovalDialogData,
    private dialogRef: MatDialogRef<AddApprovalComponent>
  ) {
    if (data.user) {
      this.approval = {
        systemUserId: data.user.id!,
        organizationId: data.user.organization?.id!,
      };
    } else if (data.approval) {
      this.approval = data.approval;
    }

    if (data.orgId) {
      this.approval = {
        ...this.approval,
        organizationId: data.orgId,
      };
    }

    this.viewOnly = !!this.approval?.id;
    this.extendApproval = !!data.extendApproval;
    this.isGroupExtension = data.isGroupExtension || false;
  }

  onSubmit() {
    return this.dialogRef.close(this.approval);
  }

  static openDialog(dialog: MatDialog, data: AddApprovalDialogData) {
    return dialog.open<AddApprovalComponent, AddApprovalDialogData>(
      AddApprovalComponent,
      {
        width: '800px',
        data,
      }
    );
  }
}
