import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ContactPhoneNumber,
  phoneIcons,
} from '@shared/models/contact-phone-number.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
import { ForeignVisitorPhoneService } from '@shared/services/foreign-visitor-phone.service';
import { NgPipesModule } from 'ngx-pipes';
import { PhoneDialogDirective } from '../phone-dialog/phone-dialog.directive';

@Component({
  selector: 'app-fv-phone-display',
  templateUrl: './fv-phone-display.component.html',
  styleUrls: ['./fv-phone-display.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DefaultPipe,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    NgPipesModule,
    PhoneNumberPipe,
    PhoneDialogDirective,
  ],
})
export class FvPhoneDisplayComponent {
  @Input() phones: ContactPhoneNumber[];
  @Input() fvId: string;
  @Input() editable?: boolean = false;
  phoneIcons = phoneIcons;

  constructor(private phoneService: ForeignVisitorPhoneService) {}

  lockPrimary(phone: ContactPhoneNumber): boolean {
    return this.phoneService.lockPrimary(this.phones, phone);
  }
}
