import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { ORGANIZATION_TAB } from '@app/user-preference/config/organization.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { AdminViewRoles } from '@shared/models/role-permissions';
import { OrganizationsComponent } from './organizations/organizations.component';

export const ORGANIZATION_ROUTES: Routes = [
  {
    path: 'organizations',
    title: 'Organizations',
    component: OrganizationsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: AdminViewRoles,
      viewDefKey: ORGANIZATION_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];
