import { Directive, HostListener, Input } from '@angular/core';
import {
  ResourceFileConfig,
  ResourceFileType,
} from '@shared/components/resource-file/resource-file.enum';
import { ResourceFileService } from '@shared/components/resource-file/resource-file.service';
import { AlertService } from '@shared/services/alert.service';

@Directive({
  selector: '[appResourceFileDownload]',
  standalone: true,
})
export class ResourceFileDownloadDirective {
  @Input() appResourceFileDownload: ResourceFileType;

  constructor(
    private resourceFileService: ResourceFileService,
    private alert: AlertService
  ) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appResourceFileDownload) return;
    const config = ResourceFileConfig[this.appResourceFileDownload];
    this.resourceFileService
      .requestDownload(this.appResourceFileDownload)
      .subscribe((result) => {
        if (!result.url) {
          return this.alert.errorAlert(
            `${config.name} is currently unavailable`
          );
        }

        window.open(result.url);
      });
  }
}
