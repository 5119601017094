import { inject, Injectable, NgZone } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { CMTSettings } from '@environments/ienvironment';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { BehaviorSubject } from 'rxjs';
import { CmtResponse, CmtStatus } from './interface/cmt-response';

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {
  private settings: CMTSettings;
  private readonly alertService: AlertService = inject(AlertService);

  constructor(
    private ngZone: NgZone,
    private auth: AuthService,
    private config: AppConfigService
  ) {
    this.settings = config.get('cmt') as CMTSettings;
  }

  get enabled(): boolean {
    return !!this.settings;
  }

  cmtClassification$: BehaviorSubject<CmtResponse | undefined> =
    new BehaviorSubject<CmtResponse | undefined>(undefined);

  launchCMTEmbed(classificationXml?: string): void {
    const cmt: CMTSettings = this.config.get('cmt') as CMTSettings;
    this.ngZone.runOutsideAngular(() => {
      let cmtWin: Window | undefined = undefined;

      window.addEventListener('message', async (event: MessageEvent) => {
        const data: CmtResponse = event.data as CmtResponse;

        switch (data?.status) {
          case CmtStatus.INITIALIZED:
            cmtWin?.postMessage(
              {
                buildId: this.settings.cmtBuildId,
                portionMark: false,
                classifiedBy: await this.auth.getClaim(
                  this.settings.cmtClassifiedByAuthClaim
                ),
                classificationXml: classificationXml,
              },
              cmt.cmtIntegrationUrl
            );
            break;
          case CmtStatus.RESPONSE:
            // eslint-disable-next-line no-case-declarations
            const cmtResponse: CmtResponse = data;
            this.cmtClassification$.next(cmtResponse);
            break;
          case CmtStatus.ERROR:
            this.alertService.errorAlert('The CMT Tool encountered an error');
            console.warn('CMT Error: ' + data.errorMessage);
            break;
          default:
            cmtWin?.close();
            cmtWin = undefined;
            break;
        }
      });
      cmtWin = window.open(
        `${cmt.cmtIntegrationUrl}/embed/`,
        '_tab',
        'width=870,height=850'
      ) as Window;
      cmtWin.focus();
    });
  }
}
