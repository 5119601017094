<mat-card appearance="outlined" class="itinerary d-flex flex-column">
  <mat-card-header
    class="d-flex justify-content-between pb-2"
    [class]="
      far.foreignAccessRequestLocations?.length === 1
        ? 'pt-3 align-items-center'
        : ''
    "
  >
    <mat-card-title class="d-flex align-items-center"
      >Itinerary
      <span *ngIf="far.foreignAccessRequestLocations?.length !== 1"
        >({{ far.foreignAccessRequestLocations?.length || 0 }})</span
      >
      <mat-icon
        class="ml-2"
        [class]="far.isActiveFar ? 'text-success' : 'text-danger'"
        [svgIcon]="far.isActiveFar ? 'calendar-check' : 'calendar-remove'"
        [matTooltip]="far.isActiveFar ? 'FAR is Active' : 'FAR is Inactive'"
        aria-hidden="false"
      ></mat-icon>
    </mat-card-title>
    <!--Turned off as part of LUKECIT-8284-->
    <!--<span [matTooltip]="generateTooltip()">
      <button
        mat-button
        *ngIf="far.isEditable && far.foreignAccessRequestLocations?.length"
        color="primary"
        id="AddFarItinerary"
        [disabled]="!far.purposeType"
        (click)="onCreate()"
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon svgIcon="plus-circle-outline"></mat-icon>
          <span>Add</span>
        </div>
      </button>
    </span>-->
    <span
      [matTooltip]="generateEditTooltip()"
      *ngIf="far.foreignAccessRequestLocations?.length === 1"
    >
      <button
        *ngIf="far.isEditable"
        [disabled]="!far.purposeType"
        color="primary"
        mat-button
        (click)="onUpdate(far.foreignAccessRequestLocations![0])"
        id="far-itinerary-{{ far.foreignAccessRequestLocations![0].id }}-edit"
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon svgIcon="square-edit-outline"></mat-icon>
          <span>Edit</span>
        </div>
      </button>
    </span>
  </mat-card-header>
  <mat-card-content class="flex-fill overflow-auto">
    <mat-icon
      *ngIf="far.isCanceled"
      svgIcon="cancel"
      class="canceled mr-1 d-flex"
      matTooltip="FAR Canceled"
    ></mat-icon>
    <div
      class="itinerary__events"
      *ngIf="
        far.foreignAccessRequestLocations &&
        far.foreignAccessRequestLocations?.length
      "
    >
      <app-timeline
        *ngIf="far.foreignAccessRequestLocations?.length !== 1"
        [data]="far.foreignAccessRequestLocations"
        icon="map-marker"
      >
        <ng-template timeline-content-tmp let-item="item">
          <div class="d-flex">
            <mat-expansion-panel
              class="flex-fill itinerary__item"
              #pannel="matExpansionPanel"
              id="far-itinerary-{{ item.id }}"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="itinerary__start-date">
                  {{ item.startDate | date }}
                </mat-panel-title>
                <mat-panel-description style="line-break: anywhere">
                  <span [matTooltip]="item?.organizationLocation?.name">
                    {{ item.organizationLocation?.name | shorten: 40:"..." }}
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="itinerary__content">
                <div class="itinerary__header d-flex justify-content-between">
                  <div class="itinerary__label">
                    <h6 class="font-weight-bold text-primary">Facility Name</h6>
                    <p style="line-break: anywhere">
                      {{ item.organizationLocation?.name }}
                    </p>
                  </div>
                  <div class="itinerary__label">
                    <h6 class="font-weight-bold text-primary">
                      Facility Address
                    </h6>
                    <p class="mb-0">
                      {{
                        item.organizationLocation?.address
                          | address: "street"
                          | shorten: 50:"..."
                      }}
                    </p>
                    <p>
                      {{
                        item.organizationLocation?.address
                          | address: "cityStateCountryPostalCode"
                          | shorten: 50:"..."
                      }}
                    </p>
                  </div>
                  <div class="itinerary__label">
                    <h6 class="font-weight-bold text-primary">Organization</h6>
                    <p>
                      {{ item.organizationLocation?.organization?.name }}
                    </p>
                  </div>
                </div>
                <div class="itinerary__body" *ngIf="item.description">
                  <h6 class="font-weight-bold text-primary">
                    Visit Details Description
                  </h6>
                  <p>
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </mat-expansion-panel>
            <span [matTooltip]="generateEditTooltip()">
              <button
                *ngIf="far.isEditable"
                [disabled]="!far.purposeType"
                mat-icon-button
                (click)="onUpdate(item)"
                id="far-itinerary-{{ item.id }}-edit"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>
            </span>
            <ng-container *restrictDelete>
              <button
                mat-icon-button
                color="danger"
                *ngIf="far.isDeletable"
                matTooltip="Remove Itinerary Item"
                (click)="onDelete(item.id)"
                id="far-itinerary-{{ item.id }}-remove"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </ng-container>
          </div>
        </ng-template>
      </app-timeline>
      <div *ngIf="far.foreignAccessRequestLocations?.length === 1">
        <mat-divider></mat-divider>
        <div class="row">
          <div class="col-6 pr-0">
            <mat-list class="pb-0">
              <mat-list-item
                class="activity__item list-item-divider"
                id="far-start-date"
              >
                <mat-icon
                  class="text-primary"
                  matListItemAvatar
                  svgIcon="calendar-start"
                ></mat-icon>

                <div matListItemTitle>
                  <strong> Start Date </strong>
                </div>
                <div matListItemLine>
                  <strong style="font-size: 16px">
                    {{
                      far.foreignAccessRequestLocations[0].startDate
                        | date
                        | default
                    }}
                  </strong>
                </div>
              </mat-list-item>
              <mat-list-item
                class="activity__item list-item-divider"
                id="single-location-name"
              >
                <mat-icon
                  class="text-primary"
                  matListItemAvatar
                  svgIcon="office-building-marker"
                ></mat-icon>

                <div matListItemTitle>
                  <strong> Location Name </strong>
                </div>
                <div
                  matListItemLine
                  [matTooltip]="
                    far.foreignAccessRequestLocations[0].organizationLocation
                      ?.name || ''
                  "
                >
                  <strong style="font-size: 16px; line-break: anywhere">
                    {{
                      far.foreignAccessRequestLocations[0].organizationLocation
                        ?.name | default
                    }}
                  </strong>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="col-6 pl-0">
            <mat-list class="pb-0">
              <mat-list-item
                class="activity__item list-item-divider"
                id="far-end-date"
              >
                <mat-icon
                  class="text-primary"
                  matListItemAvatar
                  svgIcon="calendar-end"
                ></mat-icon>

                <div matListItemTitle>
                  <strong> End Date </strong>
                </div>
                <div matListItemLine>
                  <strong style="font-size: 16px">
                    {{
                      far.foreignAccessRequestLocations[0].endDate
                        | date
                        | default
                    }}
                  </strong>
                </div>
              </mat-list-item>
              <mat-list-item
                class="activity__item list-item-divider"
                id="far-organization"
              >
                <mat-icon
                  class="text-primary"
                  matListItemAvatar
                  svgIcon="home-city"
                ></mat-icon>

                <div matListItemTitle>
                  <strong> Organization </strong>
                </div>
                <div
                  matListItemLine
                  [matTooltip]="
                    far.foreignAccessRequestLocations[0].organizationLocation
                      ?.organization?.name | default
                  "
                >
                  <strong style="font-size: 16px">
                    {{
                      far.foreignAccessRequestLocations[0].organizationLocation
                        ?.organization?.shortName | default
                    }}
                  </strong>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <mat-list class="pt-0">
          <mat-list-item
            *ngFor="let loc of far.foreignAccessRequestLocations"
            class="activity__item list-item-divider"
            id="single-far-address"
          >
            <mat-icon
              class="text-primary"
              matListItemAvatar
              [svgIcon]="
                loc.locationUnknown ? 'map-marker-question' : 'map-marker'
              "
              [matTooltip]="
                loc.locationUnknown
                  ? 'Location is Unknown'
                  : 'Location is known'
              "
              [ngClass]="{
                'text-warning': loc.locationUnknown,
                'mt-3': isAddress(loc.organizationLocation?.address)
              }"
            ></mat-icon>

            <div matListItemTitle>
              <strong> Address </strong>
            </div>
            <ng-container *ngIf="isAddress(loc.organizationLocation?.address)">
              <div matListItemLine style="margin-top: -25px">
                {{
                  loc.organizationLocation?.address!
                    | address: "street"
                    | default
                }}
              </div>
              <div matListItemLine>
                {{
                  loc.organizationLocation?.address!
                    | address: "cityStateCountryPostalCode"
                    | default
                }}
              </div>
            </ng-container>
            <div
              matListItemLine
              *ngIf="!isAddress(loc.organizationLocation?.address)"
            >
              N/A
            </div>
          </mat-list-item>
        </mat-list>
        <ng-container *ngIf="far.foreignAccessRequestLocations[0].description">
          <dl class="mt-2">
            <dt>Visit Details Description</dt>
            <dd>{{ far.foreignAccessRequestLocations[0].description }}</dd>
          </dl>
        </ng-container>
      </div>
    </div>
    <app-empty-state
      *ngIf="
        far.foreignAccessRequestLocations &&
        !far.foreignAccessRequestLocations?.length
      "
      [message]="'No Itinerary Items Available'"
    >
      <span [matTooltip]="generateTooltip()">
        <button
          mat-flat-button
          color="accent"
          [disabled]="!far.purposeType"
          *ngIf="far.isEditable"
          (click)="onCreate()"
          id="add-itinerary-item"
        >
          <span>Add Itinerary Item</span>
        </button>
      </span>
    </app-empty-state>
  </mat-card-content>
</mat-card>
