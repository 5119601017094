import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { LoginComponent } from '@app/auth/cognito-auth/login/login/login.component';
import { Role } from '@shared/models/role';
import { AlertService } from '@shared/services/alert.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  subscription: Subscription;
  systemUser$ = this.auth.user$;
  authenticated$ = this.auth.authenticated$;

  constructor(
    private router: Router,
    public platform: Platform,
    public alertService: AlertService,
    public auth: AuthService
  ) {}

  /**
   * set canActivate based upon isAuthenticated and user roles
   * @param route {ActivatedRouteSnapshot}
   * @param state {RouterStateSmapshot}
   * @returns {Observable<boolean>} True if user has correct role permissions and authentication status
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([this.authenticated$, this.systemUser$]).pipe(
      map(([isAuthenticated, user]) => {
        if (!isAuthenticated) {
          this.auth.login(state.url);
          return false;
        }
        let acceptedItem: string | null = localStorage.getItem('userAccepted');
        let userAccepted = acceptedItem ? JSON.parse(acceptedItem) : null;
        if (!userAccepted) {
          this.auth.navigateToSplash(state.url);
          return false;
        }

        const allowedRoles = this.getRoutePermissions(route);
        const userRoles = user?.roles || [];
        const canActivate = this.checkPermissions(userRoles, allowedRoles);
        return canActivate;
      })
    );
  }

  //deactivate Login component if using mobile device
  public canDeactivate(
    component: LoginComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    //check if platform is mobile device
    if (this.platform.ANDROID || this.platform.IOS) {
      this.alertService.error(
        'Citadel cannot be accessed utilizing a Mobile Device'
      );
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    } else {
      return true;
    }
  }

  /**
   * This prevents a component from loading entirely (i.e. js file is not served)
   * @returns {Promise<boolean>} True if user has the correct role to load the component
   */
  public canLoad(): Promise<boolean> | boolean {
    return this.checkPermissions(null, null);
  }

  /**
   * get a list of allowed roles for this route
   * @param route
   * @returns {Role[]} a array list of roles
   */
  public getRoutePermissions(route: ActivatedRouteSnapshot): Role[] | null {
    return route.data && route.data.roles ? (route.data.roles as Role[]) : null;
  }

  /**
   * Check if a user has permission to access access based upon their roles
   * @param {string[]} allowedUserRoles - These are the roles that have permission to access the route
   * @param {string[]} userRoles - The roles the current user has assigned
   * @returns {Promise<boolean>} True if user authenticated otherwise false
   */
  private checkPermissions(
    userRoles: string[] | null,
    allowedUserRoles: Role[] | null
  ): boolean {
    if (userRoles && allowedUserRoles) {
      if (this.areUserRolesAllowed(userRoles, allowedUserRoles)) {
        return true;
      }
      // Authenticated but no access
      // TODO: Add access denied component
      this.router.navigateByUrl('/error/403');
      return false;
    } else {
      return false;
    }
  }

  /**
   * check to see if the user's roles are allowed for the route
   * @param userRoles {string[]} a string array list of user roles
   * @param allowedUserRoles {Role[]} a Role array of allowed roles
   * @returns {boolean} True if route role = user role
   */
  public areUserRolesAllowed(
    userRoles: string[],
    allowedUserRoles: Role[]
  ): boolean {
    if (!allowedUserRoles?.length) return true;
    for (const role of userRoles) {
      for (const allowedRole of allowedUserRoles) {
        if (role.toLowerCase() === allowedRole.toLowerCase()) {
          // Role is found, so return true
          return true;
        }
      }
    }
    // Role is not found, so return false
    return false;
  }
}
