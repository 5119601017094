import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { OrgSortFieldLabels } from '@app/organizations/constants/org.const';
import {
  ORGANIZATION_COLUMNS,
  ORGANIZATION_TAB,
  OrganizationColumnDefs,
} from '@app/user-preference/config/organization.config.const';
import { UserPrefSaved } from '@app/user-preference/model/user-pref-saved.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { ContactEditDirective } from '@shared/directives/contact-edit.directive';
import { OrganizationEditDirective } from '@shared/directives/organization-edit.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { NetworkDomain } from '@shared/models/network-domain';
import { Organization } from '@shared/models/organization.model';
import { Role } from '@shared/models/role';
import { FVViewRoles } from '@shared/models/role-permissions';
import { User } from '@shared/models/user.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { OrganizationService } from '@shared/services/organization.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContactTableComponent } from './contact-table/contact-table.component';

@Component({
  selector: 'app-organization-collection-table',
  templateUrl: './organization-collection-table.component.html',
  styleUrls: ['./organization-collection-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '5px 0' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DefaultPipe,
    MatCheckboxModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatButtonModule,
    ContactTableComponent,
    OrganizationEditDirective,
    ContactEditDirective,
  ],
})
export class OrganizationCollectionTableComponent implements OnInit, OnDestroy {
  @Input() organization: Organization[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  organizationEditEnabled?: boolean = false;
  user = inject(AuthService);
  private currentUser: User = this.user.getUser() as User;
  canViewFN: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  expandedOrg: Organization | null;
  canShowContactActions: boolean = false;
  private readonly configService: AppConfigService = inject(AppConfigService);
  currentNetWork = this.configService.get('highEnvFeatures')
    ? NetworkDomain.J
    : NetworkDomain.U;
  savedPrefs: UserPrefSaved;
  userPrefFormGroup$: Subscription;
  ORGANIZATION_COLUMNS: OrganizationColumnDefs = ORGANIZATION_COLUMNS;
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    ORGANIZATION_TAB
  );

  constructor(
    public dialog: MatDialog,
    public orgService: OrganizationService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FVViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFN = true;
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  toggleRow(org: Organization) {
    org.contacts && org.contacts.length > 0
      ? (this.expandedOrg = this.expandedOrg === org ? null : org)
      : null;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdited(result: Organization) {
    const organization = this.organization.find((o) => o.id === result.id);

    if (organization) {
      Object.assign(organization, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  applyParentOrgFilter(parentOrgId: string) {
    this.filterParams = new FilterParams({
      parentOrganizationId: parentOrgId,
    });
    this.filterParamsChange.emit(this.filterParams);
  }

  get showContactActions(): boolean {
    return this.user
      .getRoles()
      .some((role: Role) =>
        ORGANIZATION_COLUMNS.actions.requiredRoles?.includes(role)
      );
  }

  navigateToFNs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};
      if (queryField) {
        filter[queryField] = queryParam;
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }

  deleteOrganization(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Organization`,
        message: `Are you sure you want to delete this Organization?`,
        performAction: () => this.orgService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
    this.expandedOrg = null;
  }

  protected readonly OrgSortFieldLabels = OrgSortFieldLabels;
}
