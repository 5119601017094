import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  map,
  of,
  tap,
} from 'rxjs';

import { Crud } from '@shared/models/crud.decoration';
import { CrudService } from '../../shared/services/crud.service';
import { UserPrefSaved } from '../model/user-pref-saved.model';
import {
  DisplayUserPreference,
  LoadedDisplay,
  UserPreference,
} from '../model/user-preference.model';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/userPreferences`,
  entity: 'UserPreference',
})
export class UserPreferenceService extends CrudService<UserPreference> {
  hasIdPathUpdate: boolean = true;

  userPreferenceBlob: UserPreference;
  updateCheckboxes = new Subject<LoadedDisplay>();
  updated = new BehaviorSubject(false);

  userPreferences$: BehaviorSubject<UserPreference | null> =
    new BehaviorSubject<UserPreference | null>(null);

  userPreferenceSubject$ = new BehaviorSubject<{
    value: boolean;
    view: string;
  }>({ value: false, view: 'card' });
  updateDisplay = new Subject<DisplayUserPreference>();
  //private _ColumnChooserEventObservable: any;
  constructor(
    http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    super(http);
  }

  /**
   *
   * @param refetch Use refetch = false to use the previously fetch value if it is available.
   * If there is not an existing value,
   * @returns UserPreference | null
   *
   */
  getUserPrefs(refetch = false): Observable<UserPreference | null> {
    const userId: string = this.authService.getUser()?.id as string;
    if (!userId) return EMPTY; //@TODO Not sure what what condition would require this??

    if (this.userPreferences$.value && !refetch) {
      return of(this.userPreferences$.value as UserPreference);
    }

    return this.http
      .get<UserPreference>(`${this.apiUrl}/findByCurrentUserId/` + userId)
      .pipe(
        map((result: UserPreference) => {
          // set the blob from the string if there is a result
          if (result) {
            return {
              ...result,
              userPreferenceBlob: result.userPreferenceBlob
                ? (JSON.parse(
                    result.userPreferenceBlob as unknown as string
                  ) as UserPrefSaved)
                : undefined,
            };
          }
          // return the empty result if that is what is retrieved
          return result;
        }),
        map((result: UserPreference) => {
          this.userPreferences$.next(result);
          return result;
        }),
        catchError((err: Error) => {
          throw `Error retrieving user prefereces: ${err.message}`;
        })
      );
  }

  updatePreference(pref: UserPrefSaved): Observable<UserPreference> {
    const userId: string = this.authService.getUser()?.id as string;
    const stringifiedBlobUp: UserPreference = {
      userId,
      userPreferenceBlob: JSON.stringify(pref) as string,
    };
    return this.http
      .put<UserPreference>(`${this.apiUrl}/${userId}`, stringifiedBlobUp)
      .pipe(
        map((userPref: UserPreference) => {
          userPref.userPreferenceBlob = JSON.parse(
            userPref.userPreferenceBlob as string
          );
          this.userPreferences$.next(userPref);
          return userPref;
        }),
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: m.id,
          })
        )
      );
  }

  postPreference(pref: UserPrefSaved): Observable<UserPreference> {
    const stringifiedUserPreference: UserPreference = {
      userId: this.authService.getUser()!.id!,
      userPreferenceBlob: JSON.stringify(pref) as string,
    };
    return super.create(stringifiedUserPreference).pipe(
      map((result: UserPreference) => {
        result.userPreferenceBlob = JSON.parse(
          result.userPreferenceBlob as string
        );
        this.userPreferences$.next(result);
        return result;
      })
    );
  }

  setSavedView(userPreference: UserPreference): void {
    this.userPreferenceBlob = userPreference;
  }

  sendUpdatedCheckboxes(data: LoadedDisplay) {
    this.updateCheckboxes.next(data);
  }

  sendDisplayUpdates(displayUser: DisplayUserPreference) {
    this.updateDisplay.next(displayUser);
  }

  isUpdated(data: boolean) {
    this.updated.next(data);
  }

  getDefaultPage(): string {
    const userPref: UserPreference = this.userPreferences$
      .value as UserPreference;
    const userPrefBlob: UserPrefSaved =
      userPref?.userPreferenceBlob as UserPrefSaved;
    let targetUrl = '/';
    let queryParams: Params = {};

    if (userPrefBlob?.defaultRoutePath) {
      targetUrl = userPrefBlob.defaultRoutePath;
    }
    return targetUrl;
  }

  async navigateToDefaultPage() {
    let queryParams: Params = {};

    await this.router.navigate([this.getDefaultPage()], { queryParams });
  }
}
