import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ContactEditDirective } from '@shared/directives/contact-edit.directive';
import { ContactType } from '@shared/enums/contact-type.enum';
import { Contact } from '@shared/models/contact.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { ContactService } from '@shared/services/contact.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseMatSelectComponent } from '@shared/controls/base-mat-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ContactEditDirective,
    FullnamePipe,
    NgxMatSelectSearchModule,
  ],
})
export class ContactSelectComponent
  extends BaseMatSelectComponent<Contact>
  implements OnInit
{
  @Input() orgId: string | undefined = '';
  @Input() filterLabel: string = 'Search';
  @Input() label: string = 'Approver';
  @Input() type = ContactType.ORG_ADMIN_APPROVER;
  @Output() contactIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set contactId(_contactId: string) {
    this.loadContactId(_contactId);
  }

  get fvId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams;

  constructor(
    private contactService: ContactService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.baseFilterParams = new FilterParams({
      sortBy: 'fullName',
      direction: 'ASC',
      type: this.type,
      organizationId: this.orgId,
    });
    super.ngOnInit();
  }

  loadContactId(contactId: string) {
    if ((!contactId && !this.value) || contactId === this.value?.id) return;

    if (!contactId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: contactId };
    this.contactService
      .get(contactId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.contactIdChange.emit(this.fvId);
  }

  searchItems(params: FilterParams): Observable<PageableCollection<Contact>> {
    return this.contactService.find(params);
  }

  newContactCreated(contact: Contact) {
    this.value = contact;
    this.search();
    this.ngModel.control.reset(this.value);
    this.emitChangeEvent();
  }
}
