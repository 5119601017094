import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AsyncService } from '@shared/services/async-bulk-upload.service';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs';
import { AsyncDetailsDialogComponent } from './async-details-dialog.component';

@Directive({
  selector: '[appAsyncDetailsDialog]',
  standalone: true,
})
export class AsyncDetailsDialogDirective {
  @Input() appAsyncDetailsDialog: any;
  @Output() asyncUpdated = new EventEmitter<any>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AsyncDetailsDialogComponent.openDialog(
      this.dialog,
      this.appAsyncDetailsDialog
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.asyncUpdated.emit(result);
    });
  }
}
