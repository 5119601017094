import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseModel } from '@shared/models/base.model';
import { Role } from '@shared/models/role';
import { ScreeningDateFormat } from '@shared/models/screening.model';
import { AuditOrgPipe } from '@shared/pipes/audit-org.pipe';
import { AuditUserPipe } from '@shared/pipes/audit-user.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';

@Component({
  selector: 'app-resource-user-details',
  templateUrl: './resource-user-details.component.html',
  styleUrls: ['resource-user-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    AuditUserPipe,
    AuditOrgPipe,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    FullnamePipe,
  ],
})
export class ResourceUserDetailsComponent {
  @Input() hideOwnerOrg = false;
  @Input() data: BaseModel;
  @Input() fvVersion: boolean = false;
  Role = Role;
  ScreeningDateFormat = ScreeningDateFormat;

  constructor() {}
}
