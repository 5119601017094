import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Approval } from '@shared/models/approval';
import { Role } from '@shared/models/role';
import { BaseControlComponent } from '../base-control.component';
import { ContactSelectComponent } from '../contact-select/contact-select.component';

@Component({
  selector: 'app-approval-control',
  templateUrl: './approval-control.component.html',
  styleUrls: ['./approval-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ApprovalControlComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ContactSelectComponent,
  ],
})
export class ApprovalControlComponent extends BaseControlComponent<
  Partial<Approval>
> {
  @ViewChild('approvalForm') approvalForm: NgForm;

  @Input() orgId = '';
  @Input() isGroupExtension: boolean = false;
  Role = Role;
  constructor() {
    super();
  }
  override writeValue(_value: Partial<Approval>): void {
    this.value = _value ?? {};
  }

  get valid(): boolean | null {
    return this.approvalForm?.valid ?? true;
  }
}
