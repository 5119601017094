import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  FloatLabelType,
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { Country } from '@shared/models/country.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { CountryService } from '@shared/services/country.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { BaseControlComponent } from '../base-control.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CountryPipe,
    DefaultPipe,
    FlagIconDirective,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    HighlightDirective,
    MatTooltipModule,
  ],
})
export class CountrySelectComponent
  extends BaseControlComponent<string | null>
  implements OnInit, OnDestroy
{
  @Input() placeholder = 'Country';
  @Input() includeOrgs: boolean = false;
  @Input() id: string;
  @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;

  @Input() multiple: boolean = false;
  @Input() excludeUS: boolean = false;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'Country';
  @Input() filterLabel: string = 'Search';
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  filteredCountries = new ReplaySubject<Country[]>(1);

  /** control for the search input value */
  searchCtrl: FormControl = new FormControl();

  public searching = false;

  private destroy$: Subject<void> = new Subject<void>();

  private _searchSub$ = Subscription.EMPTY;

  constructor(
    public injector: Injector,
    private countryService: CountryService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(startWith(''), takeUntil(this.destroy$))
      .subscribe((search) => this.search(search));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(searchVal?: string) {
    const params: FilterParams = new FilterParams({
      sort: {
        active: 'name',
        direction: 'asc',
      },
    });

    this.includeOrgs
      ? (params.includeNonCountryOrgs = true)
      : (params.includeNonCountryOrgs = false);
    if (this.excludeUS) params.us = false;

    const search = searchVal?.trim();
    if (search?.length) params.search = search;

    this.searching = true;
    this._searchSub$.unsubscribe();
    this._searchSub$ = this.countryService
      .find(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filtered) => {
        this.searching = false;
        this.filteredCountries.next(filtered);
      });
  }

  trackBy(idx: number, country: Country): string {
    return country?.id;
  }

  clearValue(evt?: MouseEvent) {
    evt?.stopPropagation();

    this.value = null;
    this.emitChangeEvent();
  }
}
