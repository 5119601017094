import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { USER_ADMIN_APPROVAL_TAB } from '@app/user-preference/config/user-admin-approval.config.const';
import { userPreferenceFormResolver } from '@app/user-preference/resolvers/user-preference-form.resolver';
import { UserApproverRoles } from '@shared/models/role-permissions';
import { ApprovalsComponent } from './approvals.component';

export const USER_APPROVAL_ROUTES: Routes = [
  {
    path: 'approvals',
    title: 'Approvals',
    component: ApprovalsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: UserApproverRoles,
      viewDefKey: USER_ADMIN_APPROVAL_TAB.viewDefKey,
    },
    resolve: {
      userPrefFormGroup: userPreferenceFormResolver,
    },
  },
];
