<div matDialogTitle>Add {{ fv.givenName }} {{ fv.surname }} to a new FAR</div>
<mat-dialog-content data-cy="far-from-fv-dialog">
  <form #form="ngForm" class="mt-3">
    <div class="d-flex flex-row">
      <div class="col-6 d-flex flex-column">
        <h5>Basic Information</h5>
        <app-quick-far-control
          #detailsControl
          [(ngModel)]="far"
          name="quickFarDetails"
          ngDefaultControl
        ></app-quick-far-control>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="col-6 d-flex flex-column mb-2">
        <h5>Visit Details</h5>
        <app-itinerary-control
          #itineraryControl
          [owningFarId]="userOrg.id || ''"
          [(ngModel)]="location"
          [farPurposeType]="far.purposeType"
          name="foreignAccessRequestLocations"
          (locationUnknownChangedEmitter)="locationUnknownChanged($event)"
          ngDefaultControl
        >
        </app-itinerary-control>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" data-cy="quick-far-actions">
  <button matDialogClose mat-button data-cy="cancel-action">Cancel</button>
  <button
    data-cy="submit-action"
    mat-flat-button
    (click)="onSubmit()"
    color="primary"
    [disabled]="!hasRequirements()"
  >
    Submit
  </button>
</mat-dialog-actions>
