import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '@app/navigation/service/navigation.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { CustomNGXLoggerService } from 'ngx-logger';
import { filter } from 'rxjs/operators';

export function auditAppInitializerFactory(
  auditService: AuditService
): () => Promise<void> {
  return () => auditService.init();
}

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private readonly navService: NavigationService = inject(NavigationService);
  constructor(
    private customLogger: CustomNGXLoggerService,
    private config: AppConfigService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  async init() {
    const logger = this.customLogger.getNewInstance({
      partialConfig: { context: 'AuditService' },
    });

    let prevEvent: NavigationEnd;
    this.navService
      .getRouterChanges()
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((navEvent: NavigationEnd) => {
        if (!this.config.get('auditUserActivity', false)) return;
        let route = this.route.firstChild;
        let routeTitle = route?.routeConfig?.title;
        while (route?.firstChild) {
          route = route.firstChild;
          routeTitle = route?.routeConfig?.title ?? routeTitle;
        }

        logger.info(`User navigated to ${navEvent.url}`, {
          from: prevEvent,
          to: navEvent,
          routeTitle,
        });
        prevEvent = navEvent;
      });
  }
}
