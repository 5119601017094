import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'acct-pwd-resend-dialog',
  templateUrl: './account-pwd-resend-dialog.component.html',
  styleUrls: ['./account-pwd-resend-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    CdkCopyToClipboard,
    MatTooltipModule,
  ],
})
export class AccountPwdResendDialogComponent implements OnInit {
  user: User;
  newPwd: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountPwdResendDialogComponent>
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;
    this.newPwd = this.data.password;
  }
}
