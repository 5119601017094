import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchComponent } from '@shared/components/advanced-search/advanced-search.component';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { FilterParams } from '@shared/models/filter-params.model';

@Directive({
  selector: '[appAdvancedSearch]',
  exportAs: 'advancedSearch',
  standalone: true,
})
export class AdvancedSearchDirective {
  @Input() appAdvancedSearch: FilterConfig[] = [];
  @Input() filterParams: FilterParams;
  @Output() appSearchUpdated = new EventEmitter<FilterParams>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AdvancedSearchComponent.openDialog(
      this.dialog,
      this.filterParams,
      this.appAdvancedSearch
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appSearchUpdated.emit(result);
    });
  }
}
