import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { FarFvsRollupComponent } from '@shared/components/far-card/far-fvs-rollup/far-fvs-rollup.component';
import { FarMetadataComponent } from '@shared/components/far-card/far-metadata/far-metadata.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FAR } from '@shared/models/far.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { Role } from '@shared/models/role';
import { ScreeningDateFormat } from '@shared/models/screening.model';
import { Sponsor } from '@shared/models/sponsor.model';

import { FormGroup } from '@angular/forms';
import {
  FAR_COLUMNS,
  FAR_TAB,
  FARColumnDefs,
} from '@app/user-preference/config/far.config.const';
import { UserPrefSaved } from '@app/user-preference/model/user-pref-saved.model';
import { UserPreferenceFormService } from '@app/user-preference/service/user-preference-form.service';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { Subject, Subscription } from 'rxjs';
import { ItineraryTableComponent } from './itinerary-table/itinerary-table.component';

@Component({
  selector: 'app-far-table',
  templateUrl: './far-table.component.html',
  styleUrls: ['./far-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '5px 0' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatSortModule,
    FarFvsRollupComponent,
    AuthEventsDialogDirective,
    AuthEventsDialogComponent,
    FarMetadataComponent,
    ItineraryTableComponent,
    DefaultPipe,
    NgPipesModule,
  ],
})
export class FarTableComponent implements OnInit, OnDestroy {
  @Input() fars: FAR[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  Role = Role;
  private ngUnsubscribe = new Subject<void>();
  expandedFar: FAR | null;

  ScreeningDateFormat = ScreeningDateFormat;

  FAR_COLUMNS: FARColumnDefs = FAR_COLUMNS;

  /**
   * NOTE: 6-16-2022 - Removed Name and Address sort fields.
  bc of the nesting, the sort seems to be buggy, adding multiple items for the
  table depending on what data is referenced with the sort.
   *  */
  dataColumns: string[] = inject(UserPreferenceFormService).generateDataColumns(
    FAR_TAB
  );
  savedPrefs: UserPrefSaved;
  private userPrefFormGroup$!: Subscription;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.savedPrefs = this.route.snapshot.data.userPrefFormGroup?.value;
    const formGroup: FormGroup = this.route.snapshot.data
      .userPrefFormGroup as FormGroup;
    this.userPrefFormGroup$ = formGroup.valueChanges.subscribe(
      (savedPrefs: UserPrefSaved) => {
        this.savedPrefs = savedPrefs;
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userPrefFormGroup$?.unsubscribe();
  }

  editRow(id: string) {
    this.router.navigateByUrl(`far/${id}/edit`);
  }
  viewRow(id: string) {
    this.router.navigateByUrl(`far/${id}`);
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  checkMultiNoDate(farLocations: any[]) {
    if (farLocations.length > 1) return false;
    else if (!farLocations[0].startDate && !farLocations[0].endDate)
      return true;
    return false;
  }

  getMarginClass(item: any): string {
    if (!this.isCityState(item)) {
      return 'mb-0';
    }
    return '';
  }

  isCityState(item: any): boolean {
    if (item.organizationLocation && item.organizationLocation.address) {
      if (
        !item.organizationLocation.address.city &&
        !item.organizationLocation.address.stateProvince
      ) {
        return false;
      }
    }
    return true;
  }

  showSponsor(sponsor: Sponsor): string {
    let result: string = '';
    let hasName: boolean | undefined =
      sponsor.surname || sponsor.givenName ? true : false;
    let hasBothNames: boolean | undefined =
      sponsor.surname && sponsor.givenName ? true : false;
    if (hasName) {
      result = sponsor.surname ? result + sponsor.surname : '';
      result = hasBothNames ? result + ', ' : '';
      result = sponsor.givenName ? result + sponsor.givenName : '';
    }
    if (!hasName) {
      result += 'N/A';
    }
    return result;
  }

  toggleRow(far: FAR) {
    far.locationCount! > 0
      ? (this.expandedFar = this.expandedFar === far ? null : far)
      : null;
    this.cd.detectChanges();
  }
}
