import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { UNFILTERED_ROLES } from '@shared/constants/UNFILTERED_ROLES.const';
import { AuthEvent } from '@shared/models/auth-event.model';
import { CrudChangeType } from '@shared/models/crud-change-type.enum';
import { Crud } from '@shared/models/crud.decoration';
import { Role } from '@shared/models/role';
import { UserMetrics } from '@shared/models/user-metrics.model';
import { User } from '@shared/models/user.model';
import { CrudService } from '@shared/services/crud.service';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AppConfigService } from './app-config.services';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/systemUsers`,
  hasIdPathUpdate: true,
  entity: 'User Account',
})
export class UserService extends CrudService<User> {
  @SessionStorage()
  filteredRoles: Role[];

  private readonly appConfigService: AppConfigService =
    inject(AppConfigService);
  private readonly alertService: AlertService = inject(AlertService);

  constructor(http: HttpClient) {
    super(http);
  }

  manageUser(id: string, status: string): Observable<User> {
    let params: HttpParams = new HttpParams();
    params = params.set('status', status);

    return this.http
      .put<User>(`${this.apiUrl}/${id}/manage`, null, {
        params,
        context: this.context,
      })
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: CrudChangeType.update,
            model: m,
            modelId: m.id,
          })
        )
      );
  }

  public getCurrent(unfilteredRoles = false): Observable<User> {
    const context = new HttpContext().set(UNFILTERED_ROLES, unfilteredRoles);
    return this.http
      .get<User>(`${environment.apiUrl}/systemUsers/current`, {
        context,
      })
      .pipe(
        concatMap((user: User) => {
          if (
            !this.appConfigService.get('highEnvFeatures') &&
            user.isExpiring
          ) {
            return this.alertService.alertOnIsExpiring(user);
          }
          return of(user);
        })
      );
  }

  public getAuthEvents(): Observable<AuthEvent[]> {
    return this.http.get<AuthEvent[]>(
      `${environment.apiUrl}/systemUsers/auth_events`
    );
  }

  public getUsersAuthEvents(id: string): Observable<AuthEvent[]> {
    return this.http.get<AuthEvent[]>(
      `${environment.apiUrl}/systemUsers/${id}/auth_events`
    );
  }

  public getUserMetrics(): Observable<UserMetrics> {
    return this.http.get<UserMetrics>(
      `${environment.apiUrl}/systemUsers/metrics`
    );
  }

  public updateProfile(model: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/current`, model).pipe(
      tap((m) =>
        this.changeEvent$.emit({
          type: CrudChangeType.update,
          model: m,
          modelId: model.id,
        })
      )
    );
  }

  public resendPassword(id: string, user: User): Observable<User> {
    return this.http.put<User>(
      `${environment.apiUrl}/systemUsers/${id}/reset_user`,
      user
    );
  }

  public resetPassword(id: string, permanent: boolean): Observable<any> {
    return this.http.put<string>(
      `${environment.apiUrl}/systemUsers/${id}/reset_password?permanent=${permanent}`,
      null
    );
  }
}
