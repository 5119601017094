import { inject } from '@angular/core';
import { RedirectCommand, ResolveFn, Router, UrlTree } from '@angular/router';
import { User } from '@shared/models/user.model';
import { UserService } from '@shared/services/user.service';
import { catchError, of } from 'rxjs';

export const userResolver: ResolveFn<User> = (route, state) => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  return userService.getCurrent().pipe(
    catchError((err) => {
      /**
       * If the current user cannot be retrieved the user should be forced to login
       * cause something went fubar
       */
      const urlTree: UrlTree = router.parseUrl('/login');
      return of(new RedirectCommand(urlTree));
    })
  );
};
