import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '@app/auth/auth.service';
import { FAR, FarPurposeType } from '@shared/models/far.model';
import { FarLocation } from '@shared/models/farlocation.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Organization } from '@shared/models/organization.model';
import { FarService } from '@shared/services/far.service';
import { MetricsObservable } from '@shared/services/metrics-observable.service';
import { tap } from 'rxjs/operators';
import { ItineraryControlComponent } from '../itinerary-control/itinerary-control.component';
import { QuickFarControlComponent } from './quick-far-control/quick-far-control.component';

@Component({
  selector: 'app-far-from-fv-dialog',
  templateUrl: './far-from-fv-dialog.component.html',
  styleUrls: ['./far-from-fv-dialog.component.scss'],
  standalone: true,
  imports: [
    QuickFarControlComponent,
    ItineraryControlComponent,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class FarFromFvDialogComponent implements OnInit {
  userOrg: Organization = {};
  far: FAR = {
    foreignAccessRequestLocations: [],
  };
  location: FarLocation = {
    startDate: '',
    endDate: '',
  };
  loading: boolean = false;

  @ViewChild('itineraryControl')
  itineraryControl: ItineraryControlComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fv: Partial<ForeignVisitor>,
    private dialogRef: MatDialogRef<FarFromFvDialogComponent>,
    private farService: FarService,
    private _MetricsObservable: MetricsObservable,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.userOrg = this.auth.getOrganization() || {};
    this.far.foreignVisitors = [this.fv];
    this.far.foreignAccessRequestLocations?.push(this.location);
  }
  onSubmit() {
    if (!this.fv.id) this.far.foreignVisitors = [];
    this.farService
      .save(this.far)
      .pipe(
        tap((_) => {
          this.loading = true;
        })
      )
      .subscribe((far) => {
        this._MetricsObservable.send('update');
        this.dialogRef.close(far);
      });
  }

  hasRequirements() {
    if (
      this.far.eventTitle &&
      this.far.purposeType &&
      this.location.startDate &&
      this.itineraryControl.valid &&
      this.isLocationValid()
    )
      return true;
    return false;
  }

  isLocationValid() {
    if (this.far.hasLocationUnknown) return true;
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      if (!this.location.organizationLocation) return false;
    }

    return true;
  }

  locationUnknownChanged(locationUnknown: boolean) {
    this.far.hasLocationUnknown = locationUnknown;
  }
}
