import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AsyncService } from '@shared/services/async-bulk-upload.service';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs';

@Component({
  selector: 'app-async-details-dialog',
  templateUrl: './async-details-dialog.component.html',
  styleUrls: ['./async-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    NgPipesModule,
    DefaultPipe,
    DatePipe,
  ],
})
export class AsyncDetailsDialogComponent {
  value: any = {};
  errorTableColumns = ['row', 'messages'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private asyncService: AsyncService,
    public asyncDialog: MatDialogRef<AsyncDetailsDialogComponent>
  ) {}

  markAsRead() {
    this.asyncService
      .markAsRead([this.data.id])
      .pipe(take(1))
      .subscribe((res) => {
        this.asyncDialog.close(true);
      });
  }

  getHeaderColor(status: string) {
    if (status === 'Error') return 'bg-danger text-white';
    if (status === 'Complete') return 'bg-primary text-white';
  }

  static openDialog(
    dialog: MatDialog,
    item: any
  ): MatDialogRef<AsyncDetailsDialogComponent> {
    return dialog.open<AsyncDetailsDialogComponent>(
      AsyncDetailsDialogComponent,
      {
        data: item,
        width: '800px',
      }
    );
  }
}
