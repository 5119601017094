import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountUserTableComponent } from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';
import { AccountsAdvancedSearch } from '@app/admin/accounts/accounts-collection/accounts-advanced-search';
import { ActionButton } from '@shared/models/actionbutton.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { UserEditRoles } from '@shared/models/role-permissions';

import { CommonModule } from '@angular/common';
import { UserPrefSaved } from '@app/user-preference/model/user-pref-saved.model';
import { NoResultFoundComponent } from '@shared/components/no-result-found/no-result-found.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { SearchToolbarComponent } from '@shared/components/search-toolbar/search-toolbar.component';
import { AppConfigValue } from '@shared/constants/shared.const';
import { User } from '@shared/models/user.model';
const newUserAction: ActionButton = {
  label: 'New User Account',
  menuIcon: 'note_add',
  userDialog: true,
  roles: UserEditRoles,
};

const allActions: ActionButton[] = [newUserAction];

@Component({
  selector: 'app-accounts-collection',
  templateUrl: 'accounts-collection.component.html',
  styleUrls: ['accounts-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AccountUserTableComponent,
    NoResultFoundComponent,
    PaginatorComponent,
    SearchToolbarComponent,
  ],
})
export class AccountsCollectionsComponent {
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  userPreference: UserPrefSaved | undefined;
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<User>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[];
  showingInactive: boolean = false;
  hasUserPreference: boolean = false;

  constructor() {
    this.actionButtons = this.limitedUserAdmin ? [] : allActions;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly AccountsAdvancedSearch = AccountsAdvancedSearch;
}
