import { inject, Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly router: Router = inject(Router);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  getRouterChanges(): Observable<NavigationStart | NavigationEnd> {
    return this.router.events.pipe(
      filter(
        (routerEvent) =>
          routerEvent instanceof NavigationStart ||
          routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel
      ),
      tap((routerEvent: NavigationStart | NavigationEnd | NavigationCancel) => {
        this.isLoading$.next(routerEvent instanceof NavigationStart);
        return routerEvent;
      }),
      filter(
        (routerEvent) =>
          routerEvent instanceof NavigationStart ||
          routerEvent instanceof NavigationEnd
      )
    );
  }
}
