<h1 mat-dialog-title *ngIf="location" class="d-flex align-items-center mt-1">
  <ng-container *ngIf="!id || location?.isEditable; else readOnly"
    >{{ id ? "Edit" : "Create" }} Location</ng-container
  >
  <ng-template #readOnly>View Location (Read-only) </ng-template>
  <div style="flex: 1"></div>
  <mat-icon
    *ngIf="location.networkDomain"
    [svgIcon]="'alpha-' + location.networkDomain?.toLowerCase() + '-box'"
    matTooltip="Network"
    aria-hidden="false"
  ></mat-icon>
  <app-resource-user-details
    class="pl-2"
    *ngIf="location?.id"
    [data]="location"
    [hideOwnerOrg]="true"
    #resourceUserDetails
  ></app-resource-user-details>
</h1>
<mat-dialog-content>
  <app-classification-banner
    class="d-block mb-2"
    [classificationModel]="location"
  ></app-classification-banner>
  <app-location-form
    class="location-form"
    #locationForm
    [(ngModel)]="location"
    (emitChangeEvent)="onLocationChange($event)"
  ></app-location-form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    color="primary"
    mat-dialog-close
    id="cancel-location-dialog"
  >
    {{ !id || location?.isEditable ? "Cancel" : "Close" }}
  </button>
  <span class="col"></span>
  <ng-container *ngIf="!id || location?.isEditable">
    <!-- CLASSIFY BUTTON -->
    <button
      mat-flat-button
      type="button"
      id="location-dialog-classify-btn"
      appClassify
      *appClassifyShow
      [(classificationModel)]="location"
      color="accent"
    >
      Classify
    </button>
  </ng-container>
  <button
    appDebounceClick
    [disabled]="
      !locationForm.hasValidValues() || !hasRequiredData() || disabledSubmit
    "
    *ngIf="!id || location?.isEditable"
    mat-raised-button
    color="primary"
    (debounceClick)="onSubmit()"
    id="submit-location"
  >
    <div *ngIf="!disabledSubmit">Submit</div>
    <mat-spinner
      color="primary"
      *ngIf="disabledSubmit"
      [diameter]="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
