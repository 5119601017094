export enum ScreeningResult {
  Green = 'GREEN',
  Yellow = 'YELLOW',
  Red = 'RED',
  MachineRed = 'MACHINE_RED',
  MachineGreen = 'MACHINE_GREEN',
  ReviewedGreen = 'REVIEWED_GREEN',
  ReviewedRed = 'REVIEWED_RED_WITH_DEROG',
  ReviewedRestricted = 'REVIEWED_RESTRICTED',
  Purple = 'USP',
  MachinePurple = 'MACHINE_PURPLE',
}
