export enum CountryAssignment {
  OFFICIALLY_ASSIGNED = 'OFFICIALLY_ASSIGNED',
  USER_ASSIGNED = 'USER_ASSIGNED',
  EXCEPTIONALLY_RESERVED = 'EXCEPTIONALLY_RESERVED',
  TRANSITIONALLY_RESERVED = 'TRANSITIONALLY_RESERVED',
  INDETERMINATELY_RESERVED = 'INDETERMINATELY_RESERVED',
  NOT_USED = 'NOT_USED',
}

export interface Country {
  id: string;
  name: string;
  numericCode: string;
  alpha2Code: string;
  alpha3Code: string;
  nationality?: string;
  usTerritory: boolean;
  assignmentState: CountryAssignment;
}

export const MOCK_COUNTRIES: Country[] = [
  {
    id: 'mock_country',
    name: 'Westeros',
    numericCode: '-999',
    alpha2Code: 'WS',
    alpha3Code: 'WST',
    usTerritory: false,
    assignmentState: CountryAssignment.NOT_USED,
  },
];

export class CountryModel {
  name: string;
  code: string;
  threeDigitCode: string;
  provinceKey: string;
  postalCode: string;
  zone: string;
  zones: string[];

  //Country Name, Country 2 Digit Code, Country Three Digit Code
  constructor(name: string, code: string, threeDigitCode: string) {
    this.name = name;
    this.code = code;
    this.threeDigitCode = threeDigitCode;
    this.provinceKey = 'PROVINCE';
    this.postalCode = 'Postal Code / ZIP Code';
    this.zone = 'Province';
    this.zones = [];
  }
}
