import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertService } from '@shared/services/alert.service';
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  FileUploadModule,
} from 'ng2-file-upload';
import {
  ResourceFileConfig,
  ResourceFileType,
} from '@shared/components/resource-file/resource-file.enum';
import { MimeTypeIconDirective } from '@shared/directives/mime-type-icon.directive';
import { ResourceFileService } from '@shared/components/resource-file/resource-file.service';
import {
  S3_HEADER_SSE,
  S3_HEADER_SSE_ID,
} from '@shared/services/attachment.service';

@Component({
  selector: 'app-resource-file-upload',
  templateUrl: './resource-file-upload.component.html',
  styleUrls: ['./resource-file-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    FileUploadModule,
    MatTooltipModule,
    MimeTypeIconDirective,
  ],
})
export class ResourceFileUploadComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  allowedMimeType: string[];

  uploader: FileUploader;

  uploading = false;

  fileItem: FileItem | null;

  fileHoverDrop = false;

  fileType: ResourceFileType;

  fileTypeName: string;

  _defaultOptions: FileUploaderOptions;

  constructor(
    public dialogRef: MatDialogRef<ResourceFileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceFileUploadData,
    private alert: AlertService,
    private resourceFileService: ResourceFileService
  ) {
    this.fileType = data.type;
    const config = ResourceFileConfig[this.fileType];
    this.fileTypeName = config.name;
    this.allowedMimeType = config.mimeType;

    this._defaultOptions = {
      itemAlias: 'file',
      method: 'PUT',
      allowedMimeType: this.allowedMimeType,
      disableMultipart: true,
      url: '',
    };
    this.uploader = new FileUploader(this._defaultOptions);

    this.uploader.onAfterAddingFile = (fileItem) => this.onAddFile(fileItem);
    this.uploader.onSuccessItem = (_item: FileItem, _response: string) => {
      this.alert.successAlert(
        `${this.fileTypeName} updated (Pending Virus Scan)`
      );
      this.dialogRef.close();
    };
    this.uploader.onErrorItem = (_item: FileItem, _response: string) => {
      this.uploading = false;
      this.alert.errorAlert(`Error updating ${this.fileTypeName}`);
    };
  }

  onAddFile(fileItem: FileItem) {
    if (this.uploading) return;
    this.fileItem = fileItem;
  }

  clear() {
    this.fileItem = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  submit() {
    if (!this.fileItem) return;

    this.uploading = true;

    this.resourceFileService
      .requestUpload(this.fileType)
      .subscribe((result) => {
        if (!this.fileItem) return;
        this.fileItem.url = result.url;
        this.fileItem.headers = [
          { name: S3_HEADER_SSE, value: result.sseEncryption },
          { name: S3_HEADER_SSE_ID, value: result.sseEncryptionId },
        ];

        this.uploader.uploadItem(this.fileItem);
      });
  }

  static openDialog(
    dialog: MatDialog,
    data: ResourceFileUploadData
  ): MatDialogRef<ResourceFileUploadComponent> {
    return dialog.open<ResourceFileUploadComponent, ResourceFileUploadData>(
      ResourceFileUploadComponent,
      {
        data,
        width: '600px',
      }
    );
  }
}

export interface ResourceFileUploadData {
  type: ResourceFileType;
}
