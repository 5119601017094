import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import {
  AsyncJob,
  AsyncMessage,
  MessageType,
} from '@shared/models/async-job.model';
import { Crud } from '@shared/models/crud.decoration';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { Role } from '@shared/models/role';
import { CrudService } from '@shared/services/crud.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/async`,
  hasIdPathUpdate: true,
  entity: 'Async',
})
export class AsyncService extends CrudService<AsyncJob> {
  private readonly authService: AuthService = inject(AuthService);
  constructor(http: HttpClient) {
    super(http);
  }

  markAsRead(ids: string[]) {
    return this.http.put<void>(this.apiUrl, {
      ids: ids,
      read: true,
    });
  }
  override find(
    filterParams?: FilterParams | undefined
  ): Observable<PageableCollection<AsyncJob>> {
    if (this.authService.getRoles().includes(Role.sv_admin)) {
      return super.find(filterParams);
    }

    // If the user lacks the admin role, filter out the address resolution messages.
    const filteredMessages: MessageType[] = [
      'Address cannot be resolved',
      'Number of unresolved addresses',
    ];

    return super.find(filterParams).pipe(
      map((page: PageableCollection<AsyncJob>) => {
        return {
          ...page,
          content: page.content.map((item: AsyncJob) => {
            return {
              ...item,
              messages: item.messages?.filter((message: AsyncMessage) => {
                return !filteredMessages.includes(message.type);
              }),
            };
          }),
        };
      })
    );
  }
}
