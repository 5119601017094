import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatFormFieldModule,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { AuthService } from '@app/auth/auth.service';
import { UserFeedbackService } from '@app/shared/services/user-feedback.service';
import { take } from 'rxjs';

import { UserFeedback } from '@app/shared/models/user-feedback.model';
import { AlertService } from '@shared/services/alert.service';
import { User } from '@shared/models/user.model';
@Component({
  selector: 'app-user-feedback',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatRadioGroup,
    MatRadioButton,
    MatHint,
    MatDialogModule,
    MatFormFieldModule,
    MatInput,
    MatLabel,
    MatIcon,
    MatDialogTitle,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './user-feedback.component.html',
  styleUrl: './user-feedback.component.scss',
})
export class UserFeedbackComponent {
  user: User;
  busy: boolean = false;
  feedbackType: string;
  feedbackSubject: string;
  feedback: string;
  feedbackData: UserFeedback = {};

  onSubmit(form: NgForm) {
    this.busy = true;
    this.feedbackData.feedbackType = form.value.feedbackType;
    this.feedbackData.subjectTitle = form.value.feedbackSubject;
    this.feedbackData.feedback = form.value.feedback;

    this.feedbackService
      .save(this.feedbackData)
      .pipe(take(1))
      .subscribe(
        (result: UserFeedback) => {
          this.onClose(result);
          this.alert.success(
            'Feedback successfully submitted. Thank you for your feedback submission.'
          );
        },
        (err) => {
          this.alert.errorAlert(
            'Unable to submit feedback. Please try again at another time.'
          );
          this.busy = false;
        }
      );
  }

  onClose(userFeedback: UserFeedback) {
    this.dialogRef.close(userFeedback);
  }

  protected readonly value = signal('');

  onInput($event: Event) {
    this.value.set(($event.target as HTMLInputElement).value);
  }

  constructor(
    public auth: AuthService,
    public dialogRef: MatDialogRef<UserFeedbackComponent>,
    private alert: AlertService,
    private feedbackService: UserFeedbackService
  ) {}

  static openDialog(
    dialog: MatDialog,
    userFeedback: any
  ): MatDialogRef<UserFeedbackComponent> {
    return dialog.open(UserFeedbackComponent, {
      width: '600px',
    });
  }
}
