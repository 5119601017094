import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResourceFileType } from '@shared/components/resource-file/resource-file.enum';

export interface ResourceResp {
  url: string;
  sseEncryption?: string;
  sseEncryptionId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceFileService {
  constructor(private http: HttpClient) {}

  requestUpload(type: ResourceFileType): Observable<ResourceResp> {
    return this.http.post<ResourceResp>(
      `${environment.apiUrl}/resources/${type}`,
      null
    );
  }

  requestDownload(type: ResourceFileType): Observable<ResourceResp> {
    return this.http.get<ResourceResp>(
      `${environment.apiUrl}/resources/${type}`
    );
  }
}
