import { CommonModule } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgModelGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PhoneNumberControlComponent } from '@shared/controls/phone-number-component/phone-number-control.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ForeignVisitorPhoneService } from '@shared/services/foreign-visitor-phone.service';
import { cloneDeep } from 'lodash';
import { AuthEventsDialogComponent } from '../auth-events-dialog/auth-events-dialog.component';
import { DialogCloseMessage } from '@shared/shared.types';
import { PhoneDialogData } from '@shared/models/phone-dialog.model';
import { PhoneDialogComponent } from './phone-dialog.component';

@Directive({
  selector: '[appPhoneDialog]',
  standalone: true,
})
export class PhoneDialogDirective {
  @Input() appPhoneDialog?: ContactPhoneNumber;
  @Input() fvId: string;
  @Input() lockPrimary: boolean;
  @Output() fvPhoneUpdated = new EventEmitter<ContactPhoneNumber>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appPhoneDialog) this.appPhoneDialog = {};
    const dialogData: PhoneDialogData = {
      phone: cloneDeep(this.appPhoneDialog),
      fvId: this.fvId,
      lockPrimary: this.lockPrimary,
    };
    const dialog = PhoneDialogComponent.openDialog(this.dialog, dialogData);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvPhoneUpdated.emit(result);
    });
  }
}
