import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ActivatedRoute,
  IsActiveMatchOptions,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { ChangePasswordDirective } from '@app/auth/change-password-dialog/change-password.directive';
import { SwitchRolesDirective } from '@app/auth/switch-roles-dialog/switch-roles.directive';
import { CitadelIconModule } from '@app/citadel-icon/citadel-icon.module';
import { AboutDialogDirective } from '@shared/components/about-dialog/about-dialog.directive';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { UserAvatarComponent } from '@shared/components/user-avatar/user-avatar.component';
import { UserFeedbackDirective } from '@shared/components/user-feedback/user-feedback.directive';
import { AppConfigDirective } from '@shared/directives/app-config.directive';
import { CitadelLogoDirective } from '@shared/directives/citadel-logo.directive';
import { ContactSupportDialogDirective } from '@shared/directives/contact-support-dialog.directive';
import { MatBadgeIconDirective } from '@shared/directives/mat-badge-icon.directive';
import { RolesDirective } from '@shared/directives/roles.directive';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { AsyncJob } from '@shared/models/async-job.model';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { Role } from '@shared/models/role';
import {
  AdminOnlyRoles,
  AdminViewRoles,
  FarViewRoles,
  FVViewRoles,
  GroupViewRoles,
  LocViewRoles,
  MetricsViewRoles,
  MonitoringViewRoles,
  ScreeningRoles,
  SystemAlertsEditRoles,
  SystemUsageViewRoles,
  UserApproverRoles,
} from '@shared/models/role-permissions';
import { User } from '@shared/models/user.model';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import { AsyncBulkUploadObservable } from '@shared/services/async-bulk-upload.observable';
import { AsyncService } from '@shared/services/async-bulk-upload.service';
import { UserService } from '@shared/services/user.service';
import { NgPipesModule } from 'ngx-pipes';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { catchError, delay, map, take, takeUntil } from 'rxjs/operators';
import { AsyncDetailsDialogDirective } from './async-details-dialog/async-details-dialog.directive';
import { NavigationService } from '@app/navigation/service/navigation.service';
import { ResourceFileType } from '@shared/components/resource-file/resource-file.enum';
import { ResourceFileDownloadDirective } from '@shared/components/resource-file/resource-file-download.directive';
import { ResourceFileUploadDirective } from '@shared/components/resource-file/resource-file-upload.directive';

@Component({
  selector: 'app-application-toolbar',
  templateUrl: './application-toolbar.component.html',
  styleUrls: ['./application-toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,

    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeIconDirective,

    RouterLink,
    RouterLinkActive,

    PaginatorComponent,

    CitadelLogoDirective,

    AboutDialogDirective,
    AsyncDetailsDialogDirective,
    CitadelIconModule,
    ChangePasswordDirective,
    RolesDirective,
    AppConfigDirective,
    AsyncDetailsDialogDirective,
    ContactSupportDialogDirective,
    UserAvatarComponent,
    SwitchRolesDirective,
    ChangePasswordDirective,
    MatBadgeIconDirective,
    DefaultPipe,
    NgPipesModule,
    StopPropagationDirective,
    PaginatorComponent,
    UserFeedbackDirective,
    ResourceFileDownloadDirective,
    ResourceFileUploadDirective,
  ],
})
export class ApplicationToolbarComponent implements OnInit, OnDestroy {
  readonly navService: NavigationService = inject(NavigationService);
  private isRouting$: Subscription;
  isRouting = false;

  FVViewRoles = FVViewRoles;
  FarViewRoles = FarViewRoles;
  LocViewRoles = LocViewRoles;
  ScreeningRoles = ScreeningRoles;
  AdminViewRoles = AdminViewRoles;
  MetricsViewRoles = MetricsViewRoles;
  SystemAlertsEditRoles = SystemAlertsEditRoles;
  UserApproverRoles = UserApproverRoles;
  GroupViewRoles = GroupViewRoles;
  AdminOnlyRoles = AdminOnlyRoles;
  homeLinkOptions: IsActiveMatchOptions = {
    fragment: 'exact',
    matrixParams: 'exact',
    paths: 'exact',
    queryParams: 'ignored',
  };

  @Input() systemUser: User;

  classification?: string;
  showBanner: boolean;
  disableUserAdmin?: boolean;
  lowEnvFeatures?: boolean;
  rolesFiltered = false;
  asyncJobs: PageableCollection<AsyncJob>;
  asyncPollActive: boolean = false;
  inProgressCount: number = 0;
  defaultFilterParams = new FilterParams({
    sortBy: 'createdDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 5,
    read: false,
  });
  loading: boolean = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public auth: AuthService,
    private config: AppConfigService,
    public userService: UserService,
    private asyncService: AsyncService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private asyncJobPoll: AsyncBulkUploadObservable,
    private router: Router
  ) {
    this.rolesFiltered = !!this.userService.filteredRoles?.length;
    this.disableUserAdmin = config.get('disableUserAdmin');
    this.classification = config.get('classification');
    this.lowEnvFeatures = this.config.get('lowEnvFeatures', false);
    this.showBanner =
      (this.classification?.length &&
        this.classification.toUpperCase() !== 'UNCLASSIFIED') ||
      false;
  }

  Role = Role;

  ngOnInit(): void {
    this.asyncService
      .find(
        new FilterParams({
          statuses: ['In Progress'],
          read: false,
          pageSize: 1,
        })
      )
      .pipe(take(1))
      .subscribe((data) => {
        this.asyncPollActive = true;
        this.inProgressCount = data.totalElements!;
        this.asyncPoll();
      });

    this.asyncJobPoll.AsyncBulkUploadObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      if (data) {
        this.inProgressCount++;
        if (!this.asyncPollActive) this.asyncPoll();
        else this.refresh();
      }
    });

    this.isRouting$ = this.navService.isLoading$
      .pipe(delay(0))
      .subscribe((isRouting: boolean) => {
        this.isRouting = isRouting;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.isRouting$.unsubscribe();
  }

  asyncPoll() {
    this.loading = true;
    if (this.auth.isAuthenticated()) {
      let sources: Observable<PageableCollection<AsyncJob>>[] = [
        this.asyncService.find(this.defaultFilterParams),
        this.asyncService.find(
          new FilterParams({
            statuses: ['In Progress'],
            read: false,
            pageSize: 1,
          })
        ),
      ];

      forkJoin(sources)
        .pipe(
          take(1),
          map(([data, inProgressData]) => {
            this.asyncJobs = data;
            let self = this;
            window.setTimeout(function () {
              self.loading = false;
            }, 500);
            if (inProgressData.totalElements! < this.inProgressCount) {
              this.alertService.infoAlert(
                'A Bulk Upload Job has been completed. View Job status from notifications menu. Successfully completed job results can be viewed via notifications menu option or refreshing the corresponding job type page.',
                {
                  duration: 10000,
                }
              );
              this.inProgressCount = inProgressData.totalElements!;
            }
            if (inProgressData.totalElements) {
              this.asyncPollActive = true;
              setTimeout(function () {
                self.asyncPoll();
              }, 15000);
            } else this.asyncPollActive = false;
          }),
          catchError((err) => {
            //Continue Polling on Error
            let self = this;
            setTimeout(function () {
              self.asyncPoll();
            }, 15000);
            return '';
          })
        )
        .subscribe();
    }
  }

  navigateToGroup(item: AsyncJob) {
    let filter: any = {};
    let destination = '';
    let queryField = '';
    let queryParam = '';

    if (item.type === 'Foreign Visitor Bulk Upload') {
      if (this.router.url === '/') destination = 'fvs';
      else destination = '../fvs';
      queryField = 'foreignVisitorGroupId';
      queryParam = item.fvGroupId!;
    } else {
      if (this.router.url === '/') destination = 'locations';
      else destination = '../locations';
      queryField = 'asyncJobIds';
      queryParam = item.id!;
    }

    if (queryField) {
      filter[queryField] = [queryParam];
      this.router.navigate([destination], {
        relativeTo: this.route,
        queryParams: filter,
      });
    }
  }

  markAllAsRead() {
    let jobsIds: string[] = [];
    for (let item of this.asyncJobs.content)
      if (item.status !== 'In Progress') jobsIds.push(item.id!);
    this.asyncService
      .markAsRead(jobsIds)
      .pipe(take(1))
      .subscribe((res) => {
        this.defaultFilterParams.pageNum = 0;
        this.refresh();
      });
  }

  refresh(isTrue?: boolean) {
    this.loading = true;
    //Reset to Page 1 if all items cleared
    if (isTrue && this.asyncJobs.numberOfElements === 1) {
      this.defaultFilterParams.pageNum = 0;
    }

    this.asyncService
      .find(this.defaultFilterParams)
      .pipe(take(1))
      .subscribe((data) => {
        this.asyncJobs = data;
        let self = this;
        window.setTimeout(function () {
          self.loading = false;
        }, 500);
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.defaultFilterParams = filterParams;
    this.refresh();
  }

  logout() {
    this.auth.logout();
  }

  protected readonly MonitoringViewRoles = MonitoringViewRoles;
  protected readonly SystemUsageViewRoles = SystemUsageViewRoles;
  protected readonly ResourceFileType = ResourceFileType;
}
