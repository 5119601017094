import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { DebounceClickDirective } from '@shared/directives/debounce-click.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { ScreeningHistory } from '@shared/models/screening-history.model';
import { ScreeningDateFormat } from '@shared/models/screening.model';
import { CrudService } from '@shared/services/crud.service';
import { Repository } from '@shared/services/repository.service';
import { ScreeningHistoryService } from '@shared/services/screening-history.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ScreeningDrawerObservable } from '../screening-drawer-observable.service';

@Component({
  selector: 'app-screening-history',
  templateUrl: 'screening-history.component.html',
  styleUrls: ['screening-history.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: ScreeningHistoryService },
  ],
  standalone: true,
  imports: [
    CommonModule,
    DebounceClickDirective,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    PaginatorComponent,
    MatToolbarModule,
    MatTooltipModule,
  ],
})
export class ScreeningHistoryComponent implements OnInit, OnDestroy {
  ExportedScreeningHistory$: Observable<PageableCollection<ScreeningHistory>> =
    this.repo.collection$;
  private ngUnsubscribe = new Subject<void>();
  filterParams: FilterParams = this.repo.defaultFilterParams;
  @Output() emitHide = new EventEmitter();
  ScreeningDateFormat = ScreeningDateFormat;
  constructor(
    private repo: Repository<ScreeningHistory>,
    private screeningHistoryService: ScreeningHistoryService,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable
  ) {}

  ngOnInit(): void {
    this._ScreeningDrawerObservable.ScreeningDrawerObservable$.pipe(
      take(1)
    ).subscribe((data: string) => {
      if (data === 'refresh') {
        this.repo.onFilterParamChange(this.repo.defaultFilterParams);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  downloadScreeningHistory(item: ScreeningHistory) {
    this.screeningHistoryService
      .reexportScreeningById(item.id)
      .pipe(take(1))
      .subscribe((res: any) => {
        const data = window.URL.createObjectURL(res['body']);
        let link = document.createElement('a');

        link.href = data;
        link.download = item.exportFileName.toUpperCase();
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
        item.downloading = false;
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.repo.onFilterParamChange(filterParams);
  }

  hideHistory() {
    this.emitHide.emit();
  }
}
