import { FAR_COLUMNS } from '@app/user-preference/config/far.config.const';
import { FilterConfigType } from '@shared/enums/filter-config-type.enum';
import { FarPurposeTypes } from '@shared/models/far.model';
import { FilterConfig } from '@shared/models/filter-config/filter-config.type';
import { NetworkDomains } from '@shared/models/network-domain';
import { Role } from '@shared/models/role';

/**
 * @todo move this config
 */
export const FarAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: FAR_COLUMNS.eventTitle.label,
    name: FAR_COLUMNS.eventTitle.sortField,
    maxLength: 256,
  },
  {
    type: FilterConfigType.SELECT,
    label: FAR_COLUMNS.purposeType.label,
    name: FAR_COLUMNS.purposeType.sortField,
    options: FarPurposeTypes,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Location Name',
    name: 'locationName',
    maxLength: 256,
    helpText: 'Free Text Search - Matches Partials',
  },
  {
    type: FilterConfigType.LOCATION_ID,
    label: 'Location',
    name: 'locationIds',
    formHidden: false,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_analyst,
      Role.sv_org_user,
      Role.sv_org_admin,
    ],
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Foreign National Name',
    name: 'foreignVisitorName',
    maxLength: 256,
    helpText: 'Free Text Search  - Matches Partials',
  },
  {
    type: FilterConfigType.FN,
    label: 'Foreign National',
    name: 'fvId',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Sponsor Name',
    name: 'sponsorName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Deletion Date',
    name: 'deletionDate',
    ifConfig: {
      key: 'dataDeletionFiltersEnabled',
      defaultVal: true,
    },
    min: new Date(),
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Only Mine',
    name: 'currentUserCreatedBy',
    tooltip: 'Only FARs created by current user',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Has Attachments',
    name: 'hasAttachments',
    tooltip: 'Show Foreign Access Requests with Attachments',
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Network',
    name: 'networkDomain',
    ifConfig: { key: 'highEnvFeatures', value: true },
    options: NetworkDomains,
  },
  {
    type: FilterConfigType.BUTTON_TOGGLE,
    label: 'Location is Unknown',
    name: 'hasLocationUnknown',
    options: [
      { label: 'All', val: null },
      { label: 'Unknown Locations', val: 'true' },
      { label: 'Known Locations', val: 'false' },
    ],
    optLabel: 'label',
    optVal: 'val',
    col: 12,
  },
  {
    type: FilterConfigType.BUTTON_TOGGLE,
    label: 'Active',
    name: 'isActiveFar',
    options: [
      { label: 'All', val: null },
      { label: 'Active', val: 'true' },
      { label: 'Inactive', val: 'false' },
    ],
    optLabel: 'label',
    optVal: 'val',
    col: 12,
  },
  {
    type: FilterConfigType.BUTTON_TOGGLE,
    label: 'Canceled',
    name: 'isCanceled',
    options: [
      { label: 'All', val: null },
      { label: 'Canceled', val: 'true' },
      { label: 'Non-Canceled', val: 'false' },
    ],
    optLabel: 'label',
    optVal: 'val',
    col: 12,
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
      Role.sv_org_user,
    ],
  },
  {
    type: FilterConfigType.USER,
    label: 'Created By',
    name: 'createdByUserId',
    col: 12,
    ifRole: [Role.sv_admin, Role.sv_help_desk, Role.sv_org_admin],
  },
];
