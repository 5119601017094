import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { ItineraryDialogComponent } from '@shared/components/itinerary-control/itinerary-dialog.component';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { TimelineContentTemplateDirective } from '@shared/components/timeline/timeline.directive';
import { RestrictDeleteDirective } from '@shared/directives/restrict-delete.directive';
import { Address } from '@shared/models/address.model';
import { FAR, FarPurposeType } from '@shared/models/far.model';
import { FarLocation } from '@shared/models/farlocation.model';
import { AddressPipe } from '@shared/pipes/address.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { AlertService } from '@shared/services/alert.service';
import { FarLocationService } from '@shared/services/farlocation.service';
import { NgPipesModule } from 'ngx-pipes';

@Component({
  selector: 'app-far-itinerary',
  templateUrl: './far-itinerary.component.html',
  styleUrls: ['./far-itinerary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TimelineComponent,
    EmptyStateComponent,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatSnackBarModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    DefaultPipe,
    NgPipesModule,
    AddressPipe,
    RestrictDeleteDirective,
    TimelineContentTemplateDirective,
  ],
})
export class FarItineraryComponent {
  @Input() far: FAR;
  FarPurposeType = FarPurposeType;

  constructor(
    private dialog: MatDialog,
    private farLocationService: FarLocationService,
    private alerts: AlertService
  ) {}

  onCreate() {
    this.openDialog({ foreignAccessRequest: { id: this.far.id } });
  }

  onUpdate(farLocation: FarLocation) {
    this.openDialog({ ...farLocation });
  }

  onDelete(id: string) {
    this.alerts.confirmDelete({
      title: 'Delete Itinerary Item',
      message: 'Are you sure you would you like to delete this item?',
      performAction: () => this.farLocationService.delete(id),
    });
  }

  openDialog(location: FarLocation) {
    let locationRequired = this.checkLocationRequirement();
    return this.dialog.open(ItineraryDialogComponent, {
      data: {
        locationRequired: locationRequired,
        location: location,
        farOwningOrgId: this.far.ownerOrganizationId,
        farPurposeType: this.far.purposeType,
      },
      width: '600px',
    });
  }

  generateTooltip(): string {
    if (this.far.purposeType) return 'Add Itinerary Item';
    else return 'Please Select a Purpose Type';
  }

  generateEditTooltip(): string {
    if (this.far.purposeType) return 'Edit Itinerary Item';
    else return 'Please Select a Purpose Type';
  }

  checkLocationRequirement() {
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      return true;
    } else if (
      this.far.purposeType === FarPurposeType.OTHER ||
      this.far.purposeType === FarPurposeType.SYSTEM_ACCESS ||
      this.far.purposeType === FarPurposeType.VIRTUAL_MEETING
    ) {
      return false;
    }
  }

  isAddress(location?: Address) {
    if (!location) return false;
    if (
      !location.line1 &&
      !location.line2 &&
      !location.city &&
      !location.stateProvince &&
      !location.countryCode &&
      !location.postalCode
    )
      return false;
    return true;
  }
}
