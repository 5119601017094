import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddApprovalComponent } from '@app/admin/approvals/dialogs/add-approval.component';
import { Approval } from '@shared/models/approval';
import { User } from '@shared/models/user.model';

@Directive({
  selector: '[appAddApproval]',
  standalone: true,
})
export class AddApprovalDirective {
  @Input() user: User;
  @Input() orgId: string;
  @Input() approval: Approval;
  @Input() isGroupExtension: boolean = false;

  @Output() approvalSubmit = new EventEmitter<Approval>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  onClick() {
    const dialogRef = AddApprovalComponent.openDialog(this.dialog, {
      user: this.user,
      orgId: this.orgId,
      approval: this.approval,
      extendApproval: !!this.user,
      isGroupExtension: this.isGroupExtension,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.approvalSubmit.emit(result);
      }
    });
  }
}
