import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent } from './about-dialog.component';

@Directive({
  selector: '[appAboutDialog]',
  standalone: true,
})
export class AboutDialogDirective {
  @Output() appAboutClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AboutDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe(() => {
      return this.appAboutClosed.emit();
    });
  }
}
