import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import { ClassificationService } from '@shared/cmt/classification.service';
import { ClassificationModel } from '@shared/models/classification.model';
import { CmtResponse } from './interface/cmt-response';

@Directive({
  selector: '[appClassify]',
  standalone: true,
})
export class ClassifyDirective {
  @Input() classificationModel: ClassificationModel;
  @Output() classificationModelChange = new EventEmitter<ClassificationModel>();
  @Input() confirmClassified: boolean = false;
  @Output() classificationConfirmed = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private classificationService: ClassificationService
  ) {}

  @HostListener('click')
  classify() {
    this.classificationService.launchCMTEmbed(
      this.classificationModel?.classificationXML
    );
    this.classificationService.cmtClassification$.subscribe(
      (cmtResponse: CmtResponse | undefined) => {
        if (!cmtResponse) return;

        this.classificationModel = {
          ...this.classificationModel,
          classification:
            cmtResponse.classificationJson?.Classification.ClassificationHeader
              .ClassificationBanner,
          classificationXML: cmtResponse.classificationXml,
        };

        if (this.classificationModel.classification) {
          this.classificationConfirmed.emit();
          this.classificationModelChange.emit(this.classificationModel);
        }
      }
    );
  }
}
