<div class="my-2">
  <ng-container *ngIf="currentUser">
    <mat-table
      [dataSource]="users"
      matSort
      (matSortChange)="onSortChange($event)"
    >
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.systemUser.sortField"
      >
        <mat-header-cell
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.systemUser === false
          "
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
          >{{ USER_ADMIN_ACCOUNT_COLUMNS.systemUser.label }}</mat-header-cell
        >
        <mat-cell
          class="justify-content-start"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.systemUser === false
          "
        >
          <div matTooltip="ID: {{ user.id }}">
            <app-user-avatar [user]="user"></app-user-avatar>
            {{ user | fullname | default }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.email.sortField">
        <mat-header-cell
          [hidden]="savedPrefs?.userAdministration?.showColumn?.email === false"
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.email.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="savedPrefs?.userAdministration?.showColumn?.email === false"
        >
          <div [matTooltip]="user.username">{{ user.email | default }}</div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.phone.sortField">
        <mat-header-cell
          [hidden]="savedPrefs?.userAdministration?.showColumn?.phone === false"
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.phone.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="savedPrefs?.userAdministration?.showColumn?.phone === false"
        >
          <button
            mat-button
            #phoneTrigger="matMenuTrigger"
            [matMenuTriggerFor]="phoneMenu"
          >
            <div matTooltip="Click for more Information">
              {{ user.phone | default }}
            </div>
          </button>
          <mat-menu #phoneMenu>
            <dl class="mx-3 small">
              <dt>Work Phone</dt>
              <dd><mat-icon>phone</mat-icon>{{ user.phone | default }}</dd>
              <dt>Mobile Phone</dt>
              <dd>
                <mat-icon>smartphone</mat-icon>{{ user.mobilePhone | default }}
              </dd>
            </dl>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.organization.sortField"
      >
        <mat-header-cell
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.organization === false
          "
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.organization.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.organization === false
          "
        >
          <div
            *ngIf="user.organization as org"
            [matTooltip]="
              org.name && org.shortName
                ? (org | getOrgName: 'long' | default)
                : ''
            "
            [ngClass]="{ small: org.shortName && org.shortName?.length > 15 }"
          >
            {{ org | getOrgName | default }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.accountStatus.sortField"
      >
        <mat-header-cell
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.accountStatus === false
          "
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.accountStatus.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.accountStatus === false
          "
        >
          <button
            mat-button
            type="button"
            #statusTrigger="matMenuTrigger"
            [matMenuTriggerFor]="statusMenu"
          >
            <div matTooltip="Click for more information">
              {{ user.accountStatus | ucwords | default }}
            </div>
          </button>
          <mat-menu #statusMenu>
            <dl class="mx-3 small">
              <dt>Account Access</dt>
              <ng-container
                *ngIf="user.accountLocked; then acctLocked; else acctUnlocked"
              >
              </ng-container>
              <ng-template #acctLocked>
                <dd>Account Locked</dd>
              </ng-template>
              <ng-template #acctUnlocked>
                <dd>Account Unlocked</dd>
              </ng-template>
            </dl>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.lastLoginDate.sortField"
      >
        <mat-header-cell
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.lastLoginDate === false
          "
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.lastLoginDate.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.lastLoginDate === false
          "
        >
          <ng-container *ngIf="user.lastLogin; else noLogin">
            <div class="container">
              <div class="small row justify-content-center">
                {{ user.lastLogin | date }}
              </div>
              <div class="small row justify-content-center">
                {{ user.lastLogin | date: "mediumTime" }}
              </div>
            </div>
          </ng-container>
          <ng-template #noLogin>N/A</ng-template>
        </mat-cell>
      </ng-container>
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.expirationDate.sortField"
      >
        <mat-header-cell
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          mat-sort-header
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.expirationDate === false
          "
        >
          {{ USER_ADMIN_ACCOUNT_COLUMNS.expirationDate.label }}
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.expirationDate === false
          "
        >
          <ng-container *ngIf="user.expirationDate; else noExpirationDate">
            <div class="container">
              <div class="small row justify-content-center">
                {{ user.expirationDate | date }}
              </div>
              <div class="small row justify-content-center">
                {{ user.expirationDate | date: "mediumTime" }}
              </div>
            </div>
          </ng-container>
          <ng-template #noExpirationDate>N/A</ng-template>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.roles.sortField">
        <mat-header-cell
          class="justify-content-center white-arrow"
          *matHeaderCellDef
          [hidden]="savedPrefs?.userAdministration?.showColumn?.roles === false"
          mat-sort-header
          >{{ USER_ADMIN_ACCOUNT_COLUMNS.roles.label }}</mat-header-cell
        >
        <mat-cell
          [hidden]="savedPrefs?.userAdministration?.showColumn?.roles === false"
          class="justify-content-center"
          style="text-align: center"
          *matCellDef="let user"
        >
          <ng-container *ngIf="user.roles; then hasRoles; else noRoles">
          </ng-container>
          <ng-template #hasRoles>
            <ng-container
              *ngIf="user.roles.length > 1; then multiRole; else oneRole"
            ></ng-container>
            <ng-template #oneRole>
              <div [matTooltip]="user.roles[0] | roleLabel">
                {{ user.roles[0] | roleLabel }}
              </div>
            </ng-template>
            <ng-template #multiRole>
              <button
                mat-button
                type="button"
                #roleTrigger="matMenuTrigger"
                [matMenuTriggerFor]="roleMenu"
              >
                <div matTooltip="Click For More Information">
                  {{ user.roles.length }} Roles
                </div>
              </button>
              <mat-menu #roleMenu>
                <dl class="mx-3 small">
                  <dt>Roles Assigned</dt>
                  <dd *ngFor="let role of user.roles">
                    {{ role | roleLabel }}
                  </dd>
                </dl>
              </mat-menu>
            </ng-template>
          </ng-template>
          <ng-template #noRoles> No Assigned Roles </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container
        [matColumnDef]="USER_ADMIN_ACCOUNT_COLUMNS.actions.sortField"
      >
        <mat-header-cell
          class="justify-content-center"
          *matHeaderCellDef
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.actions === false
          "
          >Actions</mat-header-cell
        >
        <mat-cell
          class="justify-content-center"
          *matCellDef="let user"
          [hidden]="
            savedPrefs?.userAdministration?.showColumn?.actions === false
          "
        >
          <button
            *ngIf="
              user.id !== currentUser.id &&
              checkPriv(user.roles, currentUser.roles)
            "
            mat-icon-button
            type="button"
            #actionTrigger="matMenuTrigger"
            [matMenuTriggerFor]="actionMenu"
          >
            <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu" data-cy="account-menu">
            <button
              mat-menu-item
              [appUserAccountDialog]="user.id"
              data-cy="acct-view-button"
              id="view-button"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
            </button>
            <ng-container *ngIf="!highEnvFeatures">
              <button
                *ngIf="
                  user.roles.length === 1 && user.roles[0] === Role.sv_org_user
                "
                mat-menu-item
                data-cy="acct-extend-account-button"
                id="extend-account-button"
                (click)="extendOrgUserAccount(user)"
              >
                <mat-icon svgIcon="account-plus-outline"></mat-icon> Extend
                Account
              </button>
            </ng-container>
            <button
              *ngIf="!limitedUserAdmin"
              mat-menu-item
              appAuthEventsDialog
              [authEventUser]="user"
              id="user=acct-activity-button"
              data-cy="acct-activity-button"
              aria-label="User Account Activity"
            >
              <mat-icon>history</mat-icon> Account Activity
            </button>
            <button
              mat-menu-item
              *ngIf="
                !limitedUserAdmin &&
                user.accountStatus === AccountStatus.PreRegistered
              "
              (click)="resendPassword(user)"
              data-cy="reset-passwd-button"
              id="resend-passwd-button"
              data-cy="reset-passwd-button"
            >
              <mat-icon>refresh</mat-icon>Resend Temporary Password
            </button>

            <button
              mat-menu-item
              *ngIf="
                !limitedUserAdmin &&
                user.accountStatus !== AccountStatus.PreRegistered
              "
              (click)="resetPasswordConfirmation(user)"
              data-cy="reset-passwd-button"
              id="reset-passwd-button"
              data-cy="reset-passwd-button"
            >
              <mat-icon>send</mat-icon>Reset Password
            </button>

            <button
              *ngIf="
                !limitedUserAdmin &&
                user.accountStatus !== AccountStatus.Inactive
              "
              mat-menu-item
              [appToggleUserStatus]="user"
              data-cy="user-status-button"
              id="user-status-button"
            >
              <mat-icon svgIcon="alert-octagon"></mat-icon>
              Deactivate User
            </button>
            <button
              *ngIf="
                !limitedUserAdmin &&
                user.accountStatus === AccountStatus.Inactive
              "
              mat-menu-item
              [appToggleUserStatus]="user"
              data-cy="user-status-button"
              id="user-status-button"
            >
              <mat-icon svgIcon="alert-octagon"></mat-icon>
              Activate User
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="dataColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
    </mat-table>
  </ng-container>
</div>
