import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FARDateValidatorDirective } from '@app/foreign-access-requests/validators/far-date-validator.directive';
import { LocationUnknownComponent } from '@app/locations/location-unknown/location-unknown.component';
import {
  CitadelValidationError,
  CitadelValidationErrors,
} from '@app/shared/models/citadel-validation-errors.model';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FarPurposeType } from '@shared/models/far.model';
import { FarLocation } from '@shared/models/farlocation.model';
import { OrgLocation } from '@shared/models/orglocation.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { Subject, takeUntil } from 'rxjs';
import { ItineraryControlObservable } from '../../services/itinerary-control-observable.service';
import { OrgLocationSelectComponent } from '../org-location-select/org-location-select.component';
/**
 * @todo Move to FAR code
 */

@Component({
  selector: 'app-itinerary-control',
  templateUrl: './itinerary-control.component.html',
  styleUrls: ['./itinerary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    OrgLocationSelectComponent,
    FARDateValidatorDirective,
    MatTooltipModule,
    LocationUnknownComponent,
  ],
})
export class ItineraryControlComponent
  extends BaseControlComponent<FarLocation>
  implements Validator, OnInit, OnDestroy
{
  @ViewChild('itineraryForm')
  itineraryForm: NgForm;
  @Input() locationRequired: boolean = true;
  @Input() owningFarId: string = '';
  @Input() farPurposeType?: FarPurposeType;
  @Output() locationUnknownChangedEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  private ngUnsubscribe = new Subject<void>();
  errorMessage: string = '';
  showFarChangeWarning: boolean = false;
  farScreeningEligibility: boolean = false;
  descriptionExisted: boolean = false;
  originalLocationRequired = this.locationRequired;

  constructor(
    private itineraryControl: ItineraryControlObservable,
    private config: AppConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.itineraryControl.ItineraryControlObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      this.locationRequired = event;
    });
    this.descriptionExisted = false;
  }

  showWarning() {
    if (this.value.id && this.farScreeningEligibility)
      this.showFarChangeWarning = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * returns the opposite of isvalid
   */
  get valid(): boolean {
    const errors: CitadelValidationErrors | null = this.itineraryForm?.errors;
    if (!errors) return true;
    return !this.invalid;
  }

  /**
   * Overrides the default behavior to only prevent submissions on blocking errors
   * @see CitadelValidationError
   */
  get invalid(): boolean {
    const errors: CitadelValidationErrors | null = this.itineraryForm?.errors;
    if (!errors) return false;
    const hasBlockingErrors: boolean =
      Object.keys(errors).filter((key: string) => {
        const error: CitadelValidationError = errors[key];
        return error.blocking;
      }).length > 0;
    return hasBlockingErrors;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return this.valid ? null : control.errors;
  }

  locationUnknownChanged(locationUnknown: boolean) {
    this.value.locationUnknown = locationUnknown;
    if (locationUnknown) {
      this.locationRequired = false;
      this.value.organizationLocation = undefined;
      this.locationUnknownChangedEmitter.emit(locationUnknown);
      return;
    }
    this.locationRequired = this.originalLocationRequired;
    this.locationUnknownChangedEmitter.emit(locationUnknown);
  }

  orgLocationChanged(orgLocation: OrgLocation) {
    if (orgLocation.address) {
      this.value.locationUnknown = false;
      this.locationUnknownChanged(this.value.locationUnknown);
    }
    this.value.organizationLocation = orgLocation;
    this.emitChangeEvent();
  }
}
