import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  FloatLabelType,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FieldComponent } from '@shared/components/field/field.component';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { FilterParams } from '@shared/models/filter-params.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { PageableCollection } from '@shared/models/pageable-collection.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { CurrentPassportPipe } from '@shared/pipes/current-passport.pipe';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import {
  ScreeningClassPipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
} from '@shared/pipes/screening-display.pipe';
import { FvService } from '@shared/services/fv.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-select-fv',
  templateUrl: './select-fv.component.html',
  styleUrls: ['./select-fv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FormsModule,
    FieldComponent,
    ScreeningLabelPipe,
    ScreeningIconPipe,
    ScreeningClassPipe,
    CountryPipe,
    CurrentPassportPipe,
    FullnamePipe,
    HighlightDirective,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    MatTooltipModule,
  ],
})
export class SelectFvComponent
  extends BaseMatSelectComponent<ForeignVisitor>
  implements AfterViewInit
{
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() filterLabel: string = 'Search';
  @Input() foreignVisitorGroupId: string = '';
  @Input() multiple: boolean;
  @Input() label: string = 'Select a Foreign National';
  @Input() excludeById: string = '';
  @Input() restrictCurrentOrganization: boolean = true;
  @Input() organizationId: string = '';

  @Output() fvIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set fvId(_orgId: string) {
    this.loadOrgId(_orgId);
  }

  get fvId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    currentOrganization: this.restrictCurrentOrganization,
    sort: {
      active: 'surname',
      direction: 'asc',
    },
  });

  constructor(injector: Injector, private fvService: FvService) {
    super(injector);
  }

  loadOrgId(fvId: string) {
    if ((!fvId && !this.value) || fvId === this.value?.id) return;

    if (!fvId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: fvId };
    this.fvService
      .get(fvId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  ngAfterViewInit(): void {
    this.baseFilterParams['excludeFarId'] = this.excludeById;
    this.baseFilterParams['excludeForeignVisitorGroupId'] = this.excludeById;
    this.baseFilterParams['currentOrganization'] =
      this.restrictCurrentOrganization;

    if (this.organizationId)
      this.baseFilterParams['organizationId'] = this.organizationId;

    //Remove FN from Group
    if (this.foreignVisitorGroupId) {
      this.baseFilterParams['foreignVisitorGroupId'] =
        this.foreignVisitorGroupId;
      delete this.baseFilterParams['excludeForeignVisitorGroupId'];
    }

    super.ngOnInit();
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.fvIdChange.emit(this.fvId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<ForeignVisitor>> {
    return this.fvService.find(params);
  }

  getSelectedText(values: any) {
    if (values?.length) {
      const singleItem = `${values[0].surname}, ${values[0].givenName}`;
      const multipleItems = `${values.length} Options Selected`;
      return values.length > 1 ? multipleItems : singleItem;
    }

    return `${values?.surname}, ${values?.givenName}`;
  }
}
