import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { AgePipe } from './age.pipe';
import { AuditOrgPipe } from './audit-org.pipe';
import { AuditUserPipe } from './audit-user.pipe';
import { CountryPipe } from './country.pipe';
import { CurrentPassportPipe } from './current-passport.pipe';
import { CurrentScreeningPipe } from './current-screening.pipe';
import { DaysAgoPipe } from './days-ago.pipe';
import { DaysUntilPipe } from './days-until.pipe';
import { DefaultPipe } from './default.pipe';
import {
  CommunicationCodeDescriptionPipe,
  DerogDescriptionPipe,
  OffenseCodeDescriptionPipe,
  RelationalCodeDescriptionPipe,
  TimeframeCodeDescriptionPipe,
  VerificationCodeDescriptionPipe,
} from './derog-description.pipe';
import { FarLocationPipe } from './far-location.pipe';
import { FullnamePipe } from './fullname.pipe';
import { GetOrgNamePipe } from './get-org-name.pipe';
import { UserRoleListPipe } from './get-user-role-list.pipe';
import { IsDateBeforePipe } from './is-date-before.pipe';
import { MilitaryDateTimePipe } from './military-date-time.pipe';
import { MilitaryDatePipe } from './military-date.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { RolePipe } from './role.pipe';
import {
  ScreeningClassPipe,
  ScreeningColorPipe,
  ScreeningDatePipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
} from './screening-display.pipe';
import { SecureImagePipe } from './secure-image.pipe';
import { SortEmailPipe } from './sort-emails.pipe';
import { SortPhoneNumbersPipe } from './sort-phone-numbers.pipe';
import { StatusMapperPipe } from './status-mapper.pipe';

const SHARED_PIPES = [
  AddressPipe,
  AgePipe,
  AuditOrgPipe,
  AuditUserPipe,
  CommunicationCodeDescriptionPipe,
  CountryPipe,
  CurrentPassportPipe,
  CurrentScreeningPipe,
  DaysAgoPipe,
  DaysUntilPipe,
  DefaultPipe,
  DerogDescriptionPipe,
  FarLocationPipe,
  FullnamePipe,
  GetOrgNamePipe,
  IsDateBeforePipe,
  MilitaryDatePipe,
  MilitaryDateTimePipe,
  OffenseCodeDescriptionPipe,
  PhoneNumberPipe,
  RelationalCodeDescriptionPipe,
  RolePipe,
  ScreeningClassPipe,
  ScreeningColorPipe,
  ScreeningDatePipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
  SecureImagePipe,
  SortEmailPipe,
  SortPhoneNumbersPipe,
  StatusMapperPipe,
  TimeframeCodeDescriptionPipe,
  UserRoleListPipe,
  VerificationCodeDescriptionPipe,
];

@NgModule({
  imports: [...SHARED_PIPES],
  exports: [...SHARED_PIPES],
  providers: [...SHARED_PIPES],
})
export class PipesModule {}
