import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  DocumentIdComponent,
  DocumentTypeLabel,
} from '@app/foreign-visitors/fv/document-id/document-id.component';
import { DocumentId } from '@shared/models/document-id.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FvService } from '@shared/services/fv.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-document-id-dialog',
  templateUrl: './document-id-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    DocumentIdComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
  ],
})
export class DocumentIdDialogComponent implements OnInit, AfterContentChecked {
  documentId: DocumentId = {
    documentType: 'DIPLOMAT',
  };
  typeLabel = DocumentTypeLabel;
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentId,
    public dialogRef: MatDialogRef<DocumentIdDialogComponent>,
    public fvService: FvService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.documentId = this.data;
  }

  //Supressing "ExpressionChangedAfterItHasBeenCheckedError" occurring due to documentForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  submitDocumentId() {
    this.busy = true;
    !this.documentId.id
      ? this.fvService
          .addDocIdToFv(this.documentId.foreignVisitor?.id!, [this.documentId])
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.onClose(result as DocumentId);
            },
            (err) => (this.busy = false)
          )
      : this.fvService
          .updateDocId(this.documentId.foreignVisitor?.id!, this.documentId!)
          .pipe(take(1))
          .subscribe(
            (result: ForeignVisitor) => {
              this.onClose(result as DocumentId);
            },
            (err) => (this.busy = false)
          );
  }

  onClose(diplomaticId: DocumentId) {
    this.dialogRef.close(diplomaticId);
  }
}
