export enum ResourceFileType {
  HELP_FILE = 'help-file',
}

export const ResourceFileConfig: {
  [key in ResourceFileType]: { mimeType: string[]; name: string };
} = {
  [ResourceFileType.HELP_FILE]: {
    mimeType: ['application/pdf'],
    name: 'Help Documentation',
  },
};
