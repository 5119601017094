import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject } from 'rxjs';

export type CollectionView = 'card' | 'table' | string;
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTooltipModule,
  ],
})
export class ViewComponent {
  public toggleSubject = new Subject<string>();
  @Input() view: CollectionView = 'table';
  @Output() viewChange = new EventEmitter<CollectionView>();

  constructor() {}

  onToggleChange(event: MatButtonToggleChange) {
    const value: CollectionView = event.value;
    this.viewChange.emit(value);
  }
}
