import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentData } from '@shared/models/attachment-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ProfilePicSelectComponent } from './profile-pic-select.component';

@Directive({
  selector: '[appProfilePicSelect]',
  standalone: true,
})
export class ProfilePicSelectDirective {
  @Input() appProfilePicSelect: ForeignVisitor;

  @Output() appProfilePicChanged = new EventEmitter<AttachmentData | null>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appProfilePicSelect) return;
    const dialog = ProfilePicSelectComponent.openDialog(this.dialog, {
      fv: this.appProfilePicSelect,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result?.id) return this.appProfilePicChanged.emit(result);
      if (result) return this.appProfilePicChanged.emit(null);
    });
  }
}
