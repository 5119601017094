<div id="account-expiration-heading" class="mb-3" *ngIf="user.isExpiring">
  {{ expiryMessage }}
  {{ user.expirationDate | date: "dd MMM YYYY" }}
</div>
<div
  data-cy="contacts_found"
  *ngIf="contactGroupName && contacts && contacts.length > 0"
>
  <p *ngIf="user.isExpiring">
    Please contact any of the
    <a
      [attr.href]="
        'mailto:' +
        groupEmails.join(',') +
        '?subject=' +
        emailSubject +
        '&body=' +
        emailBody
      "
    >
      {{ contactGroupName }}
    </a>
    listed below to request that your account be renewed.
  </p>
  <app-account-approvers [emailSubject]="emailSubject" [contacts]="contacts">
  </app-account-approvers>
</div>
<p data-cy="support_message" *ngIf="user.isExpiring">
  {{
    contactGroupName && contacts && contacts.length > 0
      ? "You may always contact "
      : "Please contact "
  }}{{ supportGroupName }} (
  <a
    [attr.href]="
      'mailto:' +
      supportEmail +
      '?subject=' +
      emailSubject +
      '&body=' +
      emailBody
    "
    >{{ supportEmail }}</a
  >) for assistance.
</p>
