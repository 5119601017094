import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FvViewInfoBannerComponent } from '@app/foreign-visitors/fvview/fv-view-info-banner/fv-view-info-banner.component';
import { FarCardComponent } from '@shared/components/far-card/far-card.component';
import { Address } from '@shared/models/address.model';
import { ContactEmail } from '@shared/models/contact-email.model';
import { ContactPhoneNumber } from '@shared/models/contact-phone-number.model';
import { DocumentId } from '@shared/models/document-id.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { Passport } from '@shared/models/passport.model';
import { Visa } from '@shared/models/visa.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
@Component({
  selector: 'app-fv-summary',
  templateUrl: './fv-summary.component.html',
  styleUrls: ['./fv-summary.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,

    FarCardComponent,
    FvViewInfoBannerComponent,
    PhoneNumberPipe,
    CountryPipe,
    MatTooltipModule,
  ],
})
export class FvSummaryComponent {
  @Input() reviewFV?: ForeignVisitor;
  @Input() fvEmail: ContactEmail;
  @Input() fvPhone: ContactPhoneNumber;
  @Input() passport?: Passport = {};
  @Input() visa?: Visa = {};
  @Input() diplomaticId?: DocumentId = {};
  @Input() address?: Address = {};
  @Input() formInvalid = true;
}
