import { NetworkDomain } from '@app/shared/models/network-domain';
import { UserApproverRoles } from '@app/shared/models/role-permissions';
import { ACTION_COLUMN } from '../../shared/constants/standard-column.const';
import {
  BaseSavedDef,
  BaseViewDef,
  CitadelColumnDef,
  ViewType,
} from '../model/config-view-definitions.model';

export const USER_ADMIN_APPROVAL_TABLE_NAME = 'userTable';

export interface UserAdminApprovalColumnDefs {
  systemUser: CitadelColumnDef;
  email: CitadelColumnDef;
  organization: CitadelColumnDef;
  highestRole: CitadelColumnDef;
  approver: CitadelColumnDef;
  approvalDate: CitadelColumnDef;
  expirationDate: CitadelColumnDef;
  actions: CitadelColumnDef;
}

export const USER_ADMIN_APPROVAL_COLUMNS: UserAdminApprovalColumnDefs = {
  systemUser: {
    label: 'System User',
    hidden: false,
    isSortable: true,
    sortField: 'systemUser.surname',
    columnIndex: 0,
  },
  email: {
    label: 'Email',
    hidden: false,
    isSortable: true,
    sortField: 'systemUser.email',
    columnIndex: 1,
  },
  organization: {
    label: 'Organization',
    hidden: false,
    isSortable: true,
    sortField: 'orgId.shortName',
    columnIndex: 2,
  },
  highestRole: {
    label: 'Highest Role',
    hidden: false,
    isSortable: false,
    sortField: 'highestRole',
    columnIndex: 3,
  },
  approver: {
    label: 'Approver',
    columnIndex: 0,
    hidden: false,
    isSortable: true,
    sortField: 'contact.fullName',
  },
  approvalDate: {
    label: 'Approval Date',
    columnIndex: 0,
    hidden: false,
    isSortable: false,
    sortField: 'approvalDate',
  },
  expirationDate: {
    label: 'Approval Expiration',
    columnIndex: 0,
    hidden: false,
    isSortable: false,
    sortField: 'expirationDate',
  },
  actions: ACTION_COLUMN,
};

export type USER_ADMIN_APPROVAL_COLUMN_KEYS =
  keyof typeof USER_ADMIN_APPROVAL_COLUMNS;

export interface UserAdminApprovalPrefSaved extends BaseSavedDef {
  showColumn: { [key in USER_ADMIN_APPROVAL_COLUMN_KEYS]: boolean };
}

export interface UserAdminApprovalTabDef extends BaseViewDef {
  defaultMineOnly: boolean;
  viewType: ViewType;
  columns: { [key in USER_ADMIN_APPROVAL_COLUMN_KEYS]: CitadelColumnDef };
}

export const USER_ADMIN_APPROVAL_TAB: UserAdminApprovalTabDef = {
  defaultMineOnly: false,
  viewType: 'table',
  columns: USER_ADMIN_APPROVAL_COLUMNS,
  viewName: 'Approvals',
  pageLink: '/approvals',
  roles: UserApproverRoles,
  tableName: USER_ADMIN_APPROVAL_TABLE_NAME,
  domains: [NetworkDomain.J, NetworkDomain.U],
  viewDefKey: 'userApproval',
};
