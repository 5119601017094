import { Component, inject, Input, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { environment } from '@environments/environment';
import { AppConfigValue } from '@shared/constants/shared.const';
import { AccountApproverContact } from '@shared/models/account-approver-contact.model';
import { User } from '@shared/models/user.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { AccountExpirationSnackbarComponent } from '../account-expiration-snackbar/account-expiration-snackbar.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AccountApproversComponent } from '../account-approvers/account-approvers.component';

@Component({
  selector: 'app-account-expiration',
  standalone: true,
  imports: [CommonModule, DatePipe, AccountApproversComponent],
  templateUrl: './account-expiration.component.html',
  styleUrl: './account-expiration.component.scss',
})
export class AccountExpirationComponent implements OnInit {
  private readonly configService = inject(AppConfigService);
  @Input() user: User;
  @Input() contacts: AccountApproverContact[];

  @AppConfigValue('supportEmail')
  supportEmail: string | undefined = environment.supportEmail;

  expiryMessage: string = '';
  emailSubject = 'Citadel Account Renewal Request';
  emailBody =
    'I am a citadel user and my account is expiring. \n\n Can you please extend my account?';
  supportGroupName: string = 'Citadel Support';
  contactGroupName: string = this.supportGroupName;
  groupEmails: string[] = [];

  ngOnInit(): void {
    this.createContent();
  }

  private createContent(): void {
    this.expiryMessage = 'Your Citadel Account is expiring soon.';
    if (this.user.expirationDate) {
      this.expiryMessage = `Your Citadel Account is expiring on `;
    }

    if (this.contacts && this.contacts.length > 0) {
      this.contactGroupName = this.contacts[0].approverType + '(s)';
      this.contacts
        .filter((contact: AccountApproverContact) => contact.approverEmail)
        .forEach((contact: AccountApproverContact) => {
          this.groupEmails.push(contact.approverEmail);
        });
    }
  }
}
