import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IVersion } from '@environments/iversion';
import { CitadelLogoDirective } from '@shared/directives/citadel-logo.directive';
import { APIInfo } from '@shared/models/app-info.model';
import { AboutService } from '@shared/services/about.service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CitadelLogoDirective,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
})
export class AboutDialogComponent {
  apiInfo: APIInfo;
  uiInfo: IVersion;

  constructor(about: AboutService) {
    this.uiInfo = about.getUIInfo();
    about.getAPIInfo().subscribe((info) => (this.apiInfo = info));
  }

  static openDialog(dialog: MatDialog): MatDialogRef<AboutDialogComponent> {
    return dialog.open<AboutDialogComponent>(AboutDialogComponent, {
      maxWidth: 620,
    });
  }
}
