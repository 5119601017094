import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CollectionComponent } from '@app/foreign-visitors/dashboard/collection/collection.component';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';

@Component({
  selector: 'app-biographic-form',
  templateUrl: './biographic-form.component.html',
  styleUrls: ['./biographic-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BiographicFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BiographicFormComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatCommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CountrySelectComponent,
    CollectionComponent,
  ],
})
export class BiographicFormComponent extends BaseControlComponent<ForeignVisitor> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @ViewChild('birthCountryCtl') birthCountryCtl: CountrySelectComponent;
  @ViewChild('bioForm') bioForm: NgForm;
  today = new Date();
  gender: any = ['male', 'female'];
  minDate = new Date(1900, 1, 1);
  constructor() {
    super();
  }

  writeValue(value: ForeignVisitor): void {
    this.value = value || {};
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.bioForm?.valid ? null : { bioError: true };
  }
}
