import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { DashboardComponent } from '@app/metrics-dashboard/dashboard/dashboard.component';
import { MetricsViewRoles } from '@shared/models/role-permissions';

export const METRICS_ROUTES: Routes = [
  {
    path: 'metrics',
    title: 'Metrics',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: MetricsViewRoles },
  },
];
