import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentData } from '@shared/models/attachment-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { ResourceFileUploadComponent } from './resource-file-upload.component';
import { ResourceFileType } from '@shared/components/resource-file/resource-file.enum';

@Directive({
  selector: '[appResourceFileUpload]',
  standalone: true,
})
export class ResourceFileUploadDirective {
  @Input() appResourceFileUpload: ResourceFileType;

  @Output() appResourceFileUploaded = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appResourceFileUpload) return;
    const dialog = ResourceFileUploadComponent.openDialog(this.dialog, {
      type: this.appResourceFileUpload,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appResourceFileUploaded.emit();
    });
  }
}
