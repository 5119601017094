import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  Injector,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { TitleStrategy, provideRouter } from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { provideNgIdle } from '@ng-idle/core';
import { AppTitleStrategy } from '@shared/helpers/app-title.strategy';
import { ErrorInterceptor } from '@shared/helpers/error.interceptor';
import { JwtInterceptor } from '@shared/helpers/jwt.interceptor';
import { SuccessMessageInterceptor } from '@shared/helpers/success-message.interceptor';
import {
  AuditService,
  auditAppInitializerFactory,
} from '@shared/logging/audit.service';
import { GlobalErrorHandler } from '@shared/logging/global-error-handler';
import { LogSenderService } from '@shared/logging/log-sender.service';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  AuthConfig,
  OAuthStorage,
  provideOAuthClient,
} from 'angular-oauth2-oidc';
import {
  LoggerModule,
  NgxLoggerLevel,
  TOKEN_LOGGER_CONFIG,
  TOKEN_LOGGER_SERVER_SERVICE,
} from 'ngx-logger';
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage,
} from 'ngx-webstorage';
import { APP_ROUTES } from './app.routes';
import { AuthService } from './auth/auth.service';
import { CognitoAuthService } from './auth/cognito-auth/cognito-auth.service';
import { storageFactory } from './auth/oauth/citadel-oauth.module';
import { CitadelOauthService } from './auth/oauth/citadel-oauth.service';

const MAT_FORM_FIELD_APP_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

export function authServiceFactory(
  appConfigService: AppConfigService,
  injector: Injector
) {
  if (appConfigService.get('oauth')) return injector.get(CitadelOauthService);
  return injector.get(CognitoAuthService);
}

export function authServiceInitFactory(authService: AuthService) {
  return (): Promise<void> => {
    return authService.init();
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES),
    provideNgIdle(),
    provideOAuthClient(),
    {
      provide: AuthService,
      useExisting: environment.oauth ? CitadelOauthService : CognitoAuthService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authServiceInitFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: auditAppInitializerFactory,
      deps: [AuditService],
      multi: true,
    },
    {
      provide: AuthConfig,
      useFactory: (config: AppConfigService) => config.get('oauthConfig'),
      deps: [AppConfigService],
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, autoFocus: true, delayFocusTrap: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_APP_OPTIONS,
    },
    { provide: HTTP_INTERCEPTORS, useExisting: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SuccessMessageInterceptor,
      multi: true,
    },

    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({
        prefix: 'ngx-webstorage',
        separator: '|',
        caseSensitive: true,
      }),
      withLocalStorage(),
      withSessionStorage()
    ),
    provideMomentDateAdapter(),
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    importProvidersFrom(
      LoggerModule.forRoot(null, {
        configProvider: {
          provide: TOKEN_LOGGER_CONFIG,
          useFactory: (config: AppConfigService) => ({
            level: config.get('consoleLogLevel', NgxLoggerLevel.ERROR),
            serverLoggingUrl: 'logs',
            serverLogLevel: config.get('serverLogLevel', NgxLoggerLevel.INFO),
            enableSourceMaps: true,
          }),
          deps: [AppConfigService, AuthService],
        },
        serverProvider: {
          provide: TOKEN_LOGGER_SERVER_SERVICE,
          useClass: LogSenderService,
        },
      })
    ),
  ],
};
