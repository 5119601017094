import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { SystemUsageViewRoles } from '@shared/models/role-permissions';

export const SYSTEM_USUAGE_ROUTES: Routes = [
  {
    path: 'usage',
    title: 'System Usage',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
    canActivate: [AuthGuard],
    data: { roles: SystemUsageViewRoles },
  },
];
