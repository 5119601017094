import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { ItineraryControlComponent } from '@shared/components/itinerary-control/itinerary-control.component';
import { AddressComponent } from '@shared/controls/address/address.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { IDialogData } from '@shared/models/dialog-data.model';
import {
  EmployerType,
  EmploymentRecord,
  FvWork,
  OrganizationalOwnership,
} from '@shared/models/fv-work.model';
import { EmploymentService } from '@shared/services/employment.service';
import { DialogCloseMessage } from '@shared/shared.types';
import { PhoneValidator } from '@shared/validators/phone-number.validator';
import { isEmpty } from 'lodash';
import { NgPipesModule } from 'ngx-pipes';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-employment-form',
  templateUrl: './employment-form.component.html',
  styleUrls: ['./employment-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatDialogModule,
    FormsModule,
    MatDividerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    AddressComponent,
  ],
})
export class EmploymentFormComponent implements OnInit {
  mode: 'create' | 'update' | 'delete' = 'create';
  appearance: MatFormFieldAppearance = 'outline';
  employmentRecord!: EmploymentRecord;
  busy: boolean = false;
  // Using Enums for the mat-select components
  orgOwnership = OrganizationalOwnership;
  employerType = EmployerType;
  PhoneValidation = PhoneValidator.getPattern();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData<EmploymentRecord>,
    private employmentService: EmploymentService,
    private dialogRef: MatDialogRef<EmploymentFormComponent>
  ) {}

  ngOnInit(): void {
    this.mode = this.dialogData.mode;
    this.employmentRecord = this.dialogData.model;
  }

  isValid() {
    return !isEmpty(this.employmentRecord?.employerName);
  }

  onSubmit() {
    this.busy = true;
    this.employmentService
      .save(this.employmentRecord)
      .pipe(take(1))
      .subscribe(
        (employment: FvWork) => {
          this.close({
            [this.mode]: employment,
            message: `Employment Record for ${this.employmentRecord.employerName} has been ${this.mode}d!`,
          });
        },
        (err) => (this.busy = false)
      );
  }

  // Close should only be called on a successful call to the api
  close(message: DialogCloseMessage<FvWork>) {
    this.dialogRef.close(message);
  }
}
